import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { TemplateContext } from 'context/Context';
import { templateReducer } from 'reducers/templateReducer';

const TemplateProvider = ({ children }) => {
  const initData = {
    templates: {}
  };
  const [templateState, dispatch] = useReducer(templateReducer, initData);

  const saveAllTemplates = data => {
    dispatch({
      type: 'SAVE_ALL_TEMPLATE_DATA',
      payload: data
    });
  };
  const addTemplate = data => {
    dispatch({
      type: 'ADD_TEMPLATE',
      payload: data
    });
  };
  const removeTemplate = templateId => {
    dispatch({
      type: 'REMOVE_TEMPLATE',
      payload: templateId
    });
  };
  const resetTemplate = () => {
    dispatch({
      type: 'SAVE_ALL_TEMPLATE_DATA',
      payload: []
    });
  };
  return (
    <TemplateContext.Provider
      value={{
        templateState,
        saveAllTemplates,
        addTemplate,
        removeTemplate,
        resetTemplate
      }}
    >
      {children}
    </TemplateContext.Provider>
  );
};

TemplateProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default TemplateProvider;
