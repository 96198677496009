import React, { useContext, useState } from 'react';
import axios from 'services/axiosInstance';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from 'context/Context';
import { useEffect } from 'react';
import useClearContextState from 'hooks/useClearContextState';
import { Form } from 'react-bootstrap';

const Login = () => {
	const { clearContextState } = useClearContextState();
	const { isLogedIn, onLoginSuccess } = useContext(AuthContext);
	let navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setErrors] = useState('');
	const [isFetching, setFetching] = useState(false);

	const [searchParams] = useSearchParams();
	const authfailed = searchParams.get('authfailed');

	useEffect(() => {
		if (authfailed) {
			clearContextState();
			return;
		}
		const savedAuthStateString = localStorage.getItem('authState');
		const savedAuthState = JSON.parse(savedAuthStateString);
		if (savedAuthState?.isLogedIn && savedAuthState.isLogedIn == true) {
			onLoginSuccess();
			if (authfailed) {
				navigate(authfailed);
			} else {
				navigate('/dashboard');
			}
		}
	}, []);

	const handleLogin = e => {
		e.preventDefault();
		setFetching(true);
		axios
			.post(
				`/auth/login`,
				{
					email,
					password
				},
				{
					withCredentials: true,
					headers: { 'Content-Type': 'application/json' }
				}
			)
			.then(res => {
				onLoginSuccess(res.data.role);
				setFetching(false);
				if (authfailed) {
					navigate(authfailed);
				} else {
					navigate('/dashboard');
				}
			})
			.catch(err => {
				setErrors(
					err?.response?.data?.message || 'Something went wrong'
				);
				setFetching(false);
			});
	};

	return (
		<div>
			<Form onSubmit={handleLogin}>
				{error && error.length > 0 && (
					<div className="alert alert-danger">{error}</div>
				)}
				<label>Email</label>
				<input
					type="email"
					className="form-control"
					value={email}
					onChange={e => setEmail(e.target.value)}
				/>

				{/* <hr /> */}
				<label className="mt-3">Password</label>
				<input
					type="password"
					className="form-control"
					value={password}
					onChange={e => setPassword(e.target.value)}
				/>
				<button
					type="submit"
					className="btn btn-primary mt-3"
					disabled={isFetching}
				>
					{!isFetching ? 'Login' : 'Logging in...'}
				</button>
			</Form>
		</div>
	);
};

export default Login;
