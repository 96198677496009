import { StepContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import PreviewTechniqueConfig from '../techniques/PreviewTechniqueConfig';
import { Button, Col, Form, Row } from 'react-bootstrap';
import JSONEditor from 'components/common/JsonEditor';
import { toast } from 'react-toastify';

export default function ShowSelectedStep() {
	const [showPreview, setShowPreview] = useState(false);

	const [step, setStep] = useState(null);
	const {
		stepState: { nodes, selectedStep, showStepData },
		updateNode
	} = useContext(StepContext);

	const handleShowPreview = () => {
		let formSchema = nodes[step]?.data?.extraConfig.formSchema;
		if (formSchema) {
			setShowPreview(true);
		} else {
			toast(
				<span className="text-danger">
					Please add form schema to see preview.
				</span>
			);
		}
	};
	const handlePreviewClose = () => setShowPreview(false);

	useEffect(() => {
		let stepIndex = nodes.findIndex(n => n.id === selectedStep);
		setStep(stepIndex);
	}, [selectedStep]);

	const handleFieldChange = e => {
		const { id, value } = e.target;
		updateNode({ id, value });
	};
	const handleOnJsonChange = (id, value) => {
		handleFieldChange({ target: { id, value } });
	};
	console.log(step, showStepData, nodes[step]);
	if (!showStepData || step == null) {
		return null;
	}

	return (
		<React.Fragment>
			<div className="bg-300 rounded p-2 my-3">
				<h5 className="mb-2">Step {nodes[step]?.data.number}</h5>
				<Row>
					<Col>
						<Form.Label className="text-black">Name*</Form.Label>
						<Form.Control
							id="name"
							type="text"
							className="text-700"
							placeholder="e.g. Linear"
							value={nodes[step]?.data?.name}
							onChange={handleFieldChange}
						/>
					</Col>
					<Col>
						<Form.Label className="text-black">
							Description
						</Form.Label>
						<Form.Control
							id="description"
							as="textarea"
							rows={2}
							className="text-700"
							placeholder="e.g. Linear"
							value={nodes[step]?.data?.description}
							onChange={handleFieldChange}
						/>
					</Col>
				</Row>
				<Row className="my-3">
					<Col>
						<Form.Label className="text-black">
							Master Config
						</Form.Label>
						<JSONEditor
							key={nodes[step]?.data?.masterConfig}
							json={nodes[step]?.data?.masterConfig}
							handleChange={value =>
								handleOnJsonChange('masterConfig', value)
							}
						/>
					</Col>
					<Col>
						<Form.Label className="text-black">
							FormSchema
						</Form.Label>
						<JSONEditor
							key={nodes[step]?.data?.extraConfig?.formSchema}
							json={
								nodes[step]?.data?.extraConfig?.formSchema || {}
							}
							handleChange={value =>
								handleOnJsonChange('extraConfig', {
									...nodes[step]?.data?.extraConfig,
									formSchema: value
								})
							}
						/>
						<Button
							variant="primary"
							size="sm"
							className="mt-2 py-2 px-4"
							onClick={handleShowPreview}
						>
							Preview
						</Button>
					</Col>
				</Row>
			</div>
			{showPreview && (
				<PreviewTechniqueConfig
					show={showPreview}
					handleClose={handlePreviewClose}
					formSchema={nodes[step]?.data?.extraConfig?.formSchema}
				/>
			)}
		</React.Fragment>
	);
}
