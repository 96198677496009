import { handleError } from 'helpers/utils';
import axios from './axiosInstance';

export const attachTagsService = async data => {
	try {
		const resp = await axios.post(`/tags/attach`, data);
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};
