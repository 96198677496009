import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Col, OverlayTrigger, Row, Tab, Tabs, Tooltip } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
	MdAlternateEmail,
	MdLocationCity,
	MdOutlineMyLocation
} from 'react-icons/md';
import { FiPhone } from 'react-icons/fi';
import Switch from 'react-switch';

import Flex from 'components/common/Flex';

import { getOrgById, updateOrgService } from 'services/orgService';
import user from 'assets/img/icons/user-icon.png';

import Users from './Users';
import Projects from './Projects';
import Assets from './Assets';
import Assessments from './Assessments';
import useError from 'hooks/useError';

export default function OrgDetail() {
	const { getResponse } = useError();
	const { org_id } = useParams();
	const [org, setOrg] = useState(null);

	const getOrgDetails = useCallback(async orgId => {
		const res = await getOrgById(orgId);
		getResponse(res)
			.then(res => {
				setOrg(res);
			})
			.catch(err => {});
	}, []);

	useLayoutEffect(() => {
		getOrgDetails(org_id);
	}, []);

	const handleSwitch = async (checked, event, id) => {
		const res = await updateOrgService(org_id, {
			[id]: checked
		});
		getResponse(res)
			.then(res => {
				setOrg({ ...org, [id]: checked });
			})
			.catch(err => console.error(err));
	};

	return (
		<React.Fragment>
			<div className="font-sans-serif">
				<Row>
					<Col md={8}>
						<div className="bg-white ">
							<Flex className={'p-3'}>
								<div className="">
									<div className="shadow me-3">
										<div
											className=""
											style={{
												height: 100,
												width: 100,
												flexBasis: 100,
												flexGrow: 0,
												flexShrink: 0
											}}
										>
											<img
												src={user}
												className="w-100 h-100"
											/>
										</div>
									</div>
								</div>
								<div className="w-100">
									<div className="d-flex ">
										<div className="flex-grow-1">
											<h5 className="mb-3">
												{org?.name}
											</h5>
										</div>
										<div>
											<OverlayTrigger
												overlay={
													<Tooltip
														style={{
															position: 'fixed'
														}}
														id="overlay-trigger-example"
													>
														Inactive/Active
													</Tooltip>
												}
											>
												<div>
													<Switch
														className="react-switch mx-4 ms-auto"
														checked={
															org?.disabled
																? true
																: false
														}
														onChange={handleSwitch}
														uncheckedIcon={false}
														checkedIcon={false}
														onColor="#A4FFD6"
														onHandleColor="#2EB578"
														offHandleColor="#E52121AB"
														offColor="#FFA9A9"
														height={17}
														width={34}
														id="disabled"
													/>
												</div>
											</OverlayTrigger>
										</div>
									</div>
									<div className="fs--1">
										{org?.description}
									</div>
								</div>
							</Flex>
						</div>
					</Col>
					<Col md={4}>
						<div className="bg-white p-3 fs--1">
							<Flex className={'mb-2'} alignItems="center">
								<Flex
									alignItems={'center'}
									justifyContent="center"
									className="me-2 bg-200 rounded-circle"
									style={{
										height: 24,
										width: 24,
										flexBasis: 24,
										flexGrow: 0,
										flexShrink: 0
									}}
								>
									<MdAlternateEmail
										className="text-primary fw-medium"
										size={18}
									/>
								</Flex>
								<span className="fw-medium">
									{org?.contact?.email}
								</span>
							</Flex>
							<Flex className={'mb-2'} alignItems="center">
								<Flex
									alignItems={'center'}
									justifyContent="center"
									className="me-2 bg-200 rounded-circle"
									style={{
										height: 24,
										width: 24,
										flexBasis: 24,
										flexGrow: 0,
										flexShrink: 0
									}}
								>
									<FiPhone
										className="text-primary fw-medium"
										size={18}
									/>
								</Flex>
								<span className="fw-medium">
									{org?.contact?.phone}
								</span>
							</Flex>
							<Flex className={'mb-2'} alignItems="center">
								<Flex
									alignItems={'center'}
									justifyContent="center"
									className="me-2 bg-200 rounded-circle"
									style={{
										height: 24,
										width: 24,
										flexBasis: 24,
										flexGrow: 0,
										flexShrink: 0
									}}
								>
									<MdOutlineMyLocation
										className="text-primary fw-medium"
										size={18}
									/>
								</Flex>
								<span className="fw-medium">
									{org?.contact?.address?.address_1}{' '}
									{org?.contact?.address?.address_2}
								</span>
							</Flex>
							<Flex className={''} alignItems="center">
								<Flex
									alignItems={'center'}
									justifyContent="center"
									className="me-2 bg-200 rounded-circle "
									style={{
										height: 24,
										width: 24,
										flexBasis: 24,
										flexGrow: 0,
										flexShrink: 0
									}}
								>
									<MdLocationCity
										className="text-primary fw-medium"
										size={18}
									/>
								</Flex>
								<span className="fw-medium">
									{org?.contact?.address?.city},{' '}
									{org?.contact?.address?.state},{' '}
									{org?.contact?.address?.country} -{' '}
									{org?.contact?.address?.zipCode}
								</span>
							</Flex>
						</div>
					</Col>
				</Row>
				<Row className="mt-3">
					<Col>
						<div className="bg-white p-3">
							<Tabs
								defaultActiveKey="users"
								id="uncontrolled-tab-example"
							>
								<Tab
									eventKey="users"
									title="Users"
									className="border-bottom border-x p-3"
								>
									<Users org_id={org_id} />
								</Tab>
								<Tab
									eventKey="projects"
									title="Projects"
									className="border-bottom border-x p-3"
								>
									<Projects orgId={org_id} />
								</Tab>
								<Tab
									eventKey="assets"
									title="Assets"
									className="border-bottom border-x p-3"
								>
									<Assets orgId={org_id} />
								</Tab>
								<Tab
									eventKey="assessments"
									title="Assessments"
									className="border-bottom border-x p-3"
								>
									<Assessments orgId={org_id} />
								</Tab>
							</Tabs>
						</div>
					</Col>
				</Row>
			</div>
		</React.Fragment>
	);
}
