import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { OrgContext } from 'context/Context';
import { orgReducer } from 'reducers/orgReducer';

const OrgProvider = ({ children }) => {
	const initData = {
		Orgs: {}
	};
	const [orgState, dispatch] = useReducer(orgReducer, initData);

	const saveAllOrgs = data => {
		dispatch({
			type: 'SAVE_ALL_ORG_DATA',
			payload: data
		});
	};
	const resetOrg = () => {
		dispatch({
			type: 'SAVE_ALL_ORG_DATA',
			payload: []
		});
	};
	return (
		<OrgContext.Provider
			value={{
				orgState,
				saveAllOrgs,
				resetOrg
			}}
		>
			{children}
		</OrgContext.Provider>
	);
};

OrgProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export default OrgProvider;
