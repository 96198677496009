const tools = [
  {
    id: 'tool_a1',
    name: 'Nmap',
    quota_left: 21
  },
  {
    id: 'tool_a2',
    name: 'Tool with logest name',
    quota_left: 21
  },
  {
    id: 'tool_a3',
    name: 'Nmap',
    quota_left: 21
  },
  {
    id: 'tool_a4',
    name: 'Nmap',
    quota_left: 21
  },
  {
    id: 'tool_a5',
    name: 'Tool with logest name Tool with logest name',
    quota_left: 21
  },
  {
    id: 'tool_a6',
    name: 'Nmap',
    quota_left: 21
  },
  {
    id: 'tool_a7',
    name: 'Nmap',
    quota_left: 21
  },
  {
    id: 'tool_a8',
    name: 'Nmap',
    quota_left: 21
  },
  {
    id: 'tool_a9',
    name: 'Nmap',
    quota_left: 21
  },
  {
    id: 'tool_a10',
    name: 'Nmap',
    quota_left: 21
  },
  {
    id: 'tool_a11',
    name: 'Nmap',
    quota_left: 21
  },
  {
    id: 'tool_a12',
    name: 'Nmap',
    quota_left: 21
  }
];

export default tools;
