import OverviewCard from 'components/common/OverviewCard';
import React from 'react';

import toolsIcon from 'assets/img/icons/tools.png';
import vulnerabilitiesBlackIcon from 'assets/img/icons/vulnerabilities_black.png';
import domainScanIcon from 'assets/img/icons/domain_scan.png';
import activeIssueIcon from 'assets/img/icons/active_issue.png';
import reportGenerateIcon from 'assets/img/icons/apple_logo_black.png';

import graph1 from 'assets/img/icons/graph_1.png';
import graph2 from 'assets/img/icons/graph_2.png';
import { Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import ProjectsSnapshot from './ProjectsSnapshot';
import TaskSummarySingleLine from './TaskSummarySingleLine';
import CategoryProjectsPie from './CategoryProjectsPie';
import CountOverview from './CountOverview';
import VulnerabilitiesSnapshot from './VulnerabilitiesSnapshot';
import ToolsScan from './ToolsScan';

export default function DefaultDashboard() {
  return (
    <React.Fragment>
      <Row className="mb-4">
        <Col>
          <Flex className="bg-white rounded-3 p-2">
            <OverviewCard
              title={'Tools'}
              titleImgUrl={toolsIcon}
              label={'Current Value'}
              value={203}
              labelValueGraphUrl={graph1}
              bgColor={'#E6D6FF'}
            />
            <OverviewCard
              title={'Vulnerabilities'}
              titleImgUrl={vulnerabilitiesBlackIcon}
              label={'Current Value'}
              value={203}
              labelValueGraphUrl={graph1}
              bgColor={'#FFEEDD'}
            />
            <OverviewCard
              title={'Domains scanned'}
              titleImgUrl={domainScanIcon}
              label={'Current Value'}
              value={203}
              labelValueGraphUrl={graph2}
              bgColor={'#D0EEFF'}
            />
            <OverviewCard
              title={'Active Issues'}
              titleImgUrl={activeIssueIcon}
              label={'Current Value'}
              value={203}
              labelValueGraphUrl={graph2}
              bgColor={'#D9FFC2'}
            />
            <OverviewCard
              title={'Reports Generated'}
              titleImgUrl={reportGenerateIcon}
              label={'Current Value'}
              value={203}
              labelValueGraphUrl={graph2}
              bgColor={'#FFD9DB'}
            />
          </Flex>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <CountOverview />
          <div className="my-4">
            <VulnerabilitiesSnapshot />
          </div>
          <ToolsScan />
        </Col>
        <Col md={8}>
          <ProjectsSnapshot />
          <div className="my-4">
            <TaskSummarySingleLine />
          </div>
          <CategoryProjectsPie />
        </Col>
      </Row>
    </React.Fragment>
  );
}
