export const addStepReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'TOGGLE_ADD_STEP_MODAL':
      return {
        ...state,
        showModal: !state.showModal
      };
    case 'UPDATE_SCENARIOS':
      return {
        ...state,
        selectedScenarios: payload
      };
    case 'RESET':
      return payload;
    default:
      return state;
  }
};
