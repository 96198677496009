import Flex from 'components/common/Flex';
import Tag from 'components/common/Tag';
import React from 'react';
import { Card, Row, Col, Form } from 'react-bootstrap';
import { BiSliderAlt } from 'react-icons/bi';

import { tags } from 'data/dashboard';
import tools from 'data/tools';

import toolImage from 'assets/img/tools/besecure.png';

export default function Tools() {
  return (
    <Card>
      <Card.Header
        className="border-bottom"
        style={{ borderBottomWidth: '5px!important' }}
      >
        <Flex className="justify-content-end">
          {/* <div></div> */}
          <div>
            <Flex className={'align-items-center fw-semi-bold text-black'}>
              <BiSliderAlt size={18} />
              <div className="ms-1">Filters</div>
            </Flex>
          </div>
        </Flex>
        <Row className="my-4">
          <Col md={3}>
            <Form.Select
              id="type"
              aria-label="Default select example"
              className="text-700"
            >
              <option className="text-700">Tools</option>
              <option className="text-700" value="text-file">
                Text File
              </option>
            </Form.Select>
          </Col>
          <Col md={3}>
            <Form.Select
              id="type"
              aria-label="Default select example"
              className="text-700"
            >
              <option className="text-700">Report Format</option>
              <option className="text-700" value="text-file">
                Text File
              </option>
            </Form.Select>
          </Col>
          <Col md={3}>
            <Form.Select
              id="type"
              aria-label="Default select example"
              className="text-700"
            >
              <option className="text-700">Input Type</option>
              <option className="text-700" value="text-file">
                Text File
              </option>
            </Form.Select>
          </Col>
        </Row>
        <Flex className="">
          {tags.map(tag => (
            <Tag title={tag.name} />
          ))}
        </Flex>
      </Card.Header>
      <Card.Body>
        <Flex className="flex-wrap">
          {tools.map(tool => (
            <Card
              className="mt-1 mb-4 me-3  align-self-start"
              style={{ width: '10rem' }}
              key={tool.id}
            >
              <Card.Img src={toolImage} variant="top" />
              <Card.Body className="p-2">
                <h6 className="mt-1 mb-0">{tool.name}</h6>
                <Flex className="justify-content-between">
                  <small>Quota left</small>
                  <small>{tool.quota_left}</small>
                </Flex>
              </Card.Body>
            </Card>
          ))}
        </Flex>
      </Card.Body>
    </Card>
  );
}
