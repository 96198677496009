import React, { useState } from 'react';
import { Col, Button, Row } from 'react-bootstrap';
import CreateProjectModal from './CreateUpdateProjectModal';

const ProjectHeader = ({ handleShow }) => {
	return (
		<React.Fragment>
			<Row className="flex-between-center">
				<Col md>
					<h5 className="mb-2 mb-md-0">Projects</h5>
				</Col>
				<Col xs="auto">
					<Button
						variant="primary"
						className="me-2 mb-1"
						onClick={handleShow}
					>
						Create Project
					</Button>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default ProjectHeader;
