import React from 'react';
import ReactDom from 'react-dom';
import App from './App';
import 'helpers/initFA';
import './assets/scss/index.scss';
const root = document.getElementById('main');

ReactDom.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	root
);
