import classNames from 'classnames';
import { StepContext } from 'context/Context';
import { lowerize } from 'helpers/utils';
import React, { useContext } from 'react';
import {
	BaseEdge,
	EdgeLabelRenderer,
	EdgeProps,
	getSmoothStepPath
} from 'reactflow';
import { MdOutlineCancel } from 'react-icons/md';
import 'assets/scss/custom_edge.scss';

export default function CustomEdge({
	id,
	sourceX,
	sourceY,
	targetX,
	targetY,
	sourcePosition,
	targetPosition,
	style = {},
	markerEnd,
	label
	//   className
}) {
	const [edgePath, labelX, labelY] = getSmoothStepPath({
		sourceX,
		sourceY,
		sourcePosition,
		targetX,
		targetY,
		targetPosition
	});
	const { toggleEdgeLabel, removeEdge } = useContext(StepContext);
	const onEdgeClick = (evt, id) => {
		evt.stopPropagation();
		toggleEdgeLabel(id);
	};
	const onEdgeRemove = e => {
		e.stopPropagation();
		removeEdge(id);
	};
	return (
		<>
			<BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
			<EdgeLabelRenderer>
				<div
					style={{
						position: 'absolute',
						transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
						fontSize: 12,
						// everything inside EdgeLabelRenderer has no pointer events by default
						// if you have an interactive element, set pointer-events: all
						pointerEvents: 'all'
					}}
					className="custom_edge nodrag nopan"
				>
					<button
						className={classNames(
							'edgebutton text-white border-none border-0 rounded hover-shadow',
							lowerize(label)
						)}
						onClick={event => onEdgeClick(event, id)}
					>
						{label}
					</button>
					<MdOutlineCancel
						size={24}
						className="remove_edge"
						onClick={onEdgeRemove}
					/>
				</div>
			</EdgeLabelRenderer>
		</>
	);
}
