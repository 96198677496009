import React, { useState } from 'react';

import { Row, Col, Form } from 'react-bootstrap';

import Flex from 'components/common/Flex';
import PenetrationTestingDashboard from './dashboard/PenetrationTestingDashboard';
import IncidentResponseDashboard from './dashboard/IncidentResponseDashboard';
import DefaultDashboard from './dashboard/DefaultDashboard';

export default function Dashboard() {
  const [dashboardLayout, setDashboardLayout] = useState('default');

  const handleChange = e => {
    setDashboardLayout(e.target.value);
  };

  return (
    <div>
      <Flex className="justify-content-end mb-4">
        {/* <div></div> */}
        <div>
          <Flex className={'align-items-center fw-semi-bold text-black'}>
            <div className="me-2 text-nowrap">Switch your dashboard here</div>
            <Form.Select
              id="type"
              aria-label="Default select example"
              className="text-700"
              onChange={handleChange}
              defaultValue={dashboardLayout}
            >
              <option className="text-700" value={'default'}>
                Default
              </option>
              <option className="text-700" value={'penetration_testing'}>
                Penetration Testing
              </option>
              <option className="text-700" value="incident_response">
                Incident Response
              </option>
            </Form.Select>
          </Flex>
        </div>
      </Flex>
      <RenderDashboard layout={dashboardLayout} />
    </div>
  );
}

const RenderDashboard = ({ layout }) => {
  const dashes = {
    penetration_testing: PenetrationTestingDashboard,
    incident_response: IncidentResponseDashboard,
    default: DefaultDashboard
  };
  const DashLayout = dashes[layout];
  return <DashLayout />;
};
