import { getColor } from 'helpers/utils';
import React from 'react';
import { MdClear } from 'react-icons/md';

export default function Tag({ title }) {
  return (
    <span className="border border-2 border-primary px-2 rounded me-2 mb-2">
      <span className="text-primary me-2 fw-semi-bold fs--1">{title}</span>
      <MdClear
        className="pe-auto text-primary hover-pointer"
        onClick={() => {}}
      />
    </span>
  );
}
