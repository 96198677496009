import ReactEChartsCore from 'echarts-for-react/lib/core';
import { PieChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

echarts.use([GridComponent, PieChart, CanvasRenderer]);

const getOptions = data => ({
  color: [
    getColor('info'),
    getColor('primary'),
    getColor('danger'),
    getColor('warning')
  ],
  legend: { show: false },
  series: [
    {
      name: 'Total',
      type: 'pie',
      radius: ['100%', '57%'],
      avoidLabelOverlap: false,
      itemStyle: {
        // borderWidth: 2,
        // borderColor: getColor('gray-100')
      },
      label: {
        show: true,
        formatter: '{c}%',
        position: 'inside'
      },
      emphasis: {
        scale: false
      },
      labelLine: { show: false },
      data: data
    }
  ]
});

const CategoryTasksChart = ({ data }) => {
  const chartRef = useRef(null);

  return (
    <div className="position-relative">
      <ReactEChartsCore
        ref={chartRef}
        echarts={echarts}
        option={getOptions(data)}
        style={{ height: '14.5rem' }}
      />
      <div className="position-absolute top-50 start-50 translate-middle text-center">
        <p className="fs--1 mb-0 text-400 font-sans-serif fw-medium">
          Total Tasks
        </p>
        <p className="fs-3 mb-0 font-sans-serif fw-medium mt-n2">325000</p>
      </div>
    </div>
  );
};

export default CategoryTasksChart;
