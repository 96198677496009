import React, { useContext } from 'react';
import SimpleBarReact from 'simplebar-react';
import { Table, Form } from 'react-bootstrap';
import classNames from 'classnames';
import ActionButton from 'components/common/ActionButton';
// import { SelectContext } from 'context/Context';
import Switch from 'react-switch';

import { accountStatus } from 'data/users';
import { capitalize } from 'helpers/utils';

const UserListTableRow = ({ item, isLast, isRowActionable }) => {
	return (
		<tr>
			<td
				className={classNames(
					'align-middle font-sans-serif fw-medium text-nowrap',
					{
						'border-bottom-0': isLast
					}
				)}
			>
				<div className="d-flex">
					{/* <Form.Check type="checkbox" id="defaultCheckbox" /> */}
					<span className="ms-1">{item.id}</span>
				</div>
			</td>
			<td
				className={classNames('align-middle', {
					'border-bottom-0': isLast
				})}
			>
				{item.firstName} {item.lastName}
			</td>
			<td
				className={classNames('align-middle text-center', {
					'border-bottom-0': isLast
				})}
			>
				{item.email}
			</td>
			{/* <td
				className={classNames('align-middle fw-medium', {
					'border-bottom-0': isLast,
					'text-success': item.account_status == accountStatus.active,
					'text-danger':
						item.account_status == accountStatus.deactivated
				})}
			>
				<div className=" d-flex align-items-center ">
					<Switch
						className="react-switch"
						checked={item.account_status == accountStatus.active}
						onChange={() => {}}
						uncheckedIcon={false}
						checkedIcon={false}
						onColor="#A4FFD6"
						onHandleColor="#2EB578"
						offHandleColor="#E52121AB"
						offColor="#FFA9A9"
						height={17}
						width={34}
					/>
					<span className="ms-2">
						{capitalize(item.account_status)}
					</span>
				</div>
			</td> */}
			<td
				className={classNames('align-middle text-center text-nowrap', {
					'border-bottom-0': isLast
				})}
			>
				{item.createdAt}
			</td>
			{isRowActionable ? (
				<td
					className={classNames('align-middle text-end', {
						'border-bottom-0': isLast
					})}
				>
					<ActionButton
						icon="pencil-alt"
						title="Edit"
						variant="action"
						className="p-0 me-2"
					/>
					<ActionButton
						icon="trash-alt"
						title="Delete"
						variant="action"
						className="p-0 me-2 text-danger"
					/>
				</td>
			) : null}
		</tr>
	);
};
const UserListTable = ({
	data,
	tableHeight = '100%',
	isActionable = false
}) => {
	return (
		<SimpleBarReact style={{ height: tableHeight }}>
			<Table className="fs--1 mb-0 w-100">
				<thead className="project_table_header text-800 py-3">
					<tr>
						<th className="text-nowrap pt-4 pb-3">ID</th>
						<th className="text-nowrap pt-4 pb-3">Name</th>
						<th className="text-nowrap pt-4 pb-3 text-center">
							Email
						</th>
						{/* <th className="text-nowrap pt-4 pb-3">
							Account Management
						</th> */}
						<th className="text-nowrap pt-4 pb-3 text-center">
							Created On
						</th>
						{isActionable ? (
							<th className="text-nowrap pt-4 pb-3 text-end"></th>
						) : null}
					</tr>
				</thead>
				<tbody>
					{data.map((item, index) => (
						<UserListTableRow
							key={item.id}
							item={item}
							isLast={data.length - 1 === index}
							isRowActionable={isActionable}
						/>
					))}
				</tbody>
			</Table>
		</SimpleBarReact>
	);
};

export default UserListTable;
