import React, { useState } from 'react';
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import CloseButton from 'components/common/CloseButton';

function CreateUpdateProjectModal({
	show,
	handleClose,
	loading,
	title,
	handleChange,
	btnText,
	btnLoadingText,
	onSubmit,
	formData
}) {
	return (
		<>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				size="lg"
			>
				<Modal.Header>
					<Modal.Title>{title}</Modal.Title>
					<CloseButton onClick={handleClose} disabled={loading} />
				</Modal.Header>
				<Modal.Body>
					<Form.Group
						as={Row}
						className="mb-3"
						controlId="formHorizontalEmail"
					>
						<Form.Label column sm={4}>
							Project Name*
						</Form.Label>
						<Col sm={8}>
							<Form.Control
								type="text"
								placeholder="e.g. Linear"
								name="name"
								value={formData.name}
								onChange={handleChange}
							/>
						</Col>
					</Form.Group>

					<Form.Group
						as={Row}
						className="mb-3"
						controlId="formHorizontalPassword"
					>
						<Form.Label column sm={4}>
							Project Description*
						</Form.Label>
						<Col sm={8}>
							<Form.Control
								as="textarea"
								rows={3}
								name="description"
								value={formData.description}
								onChange={handleChange}
								placeholder="Write a few sentences about the project..."
							/>
						</Col>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="falcon-default"
						className="py-2 px-4"
						onClick={handleClose}
						disabled={loading}
					>
						Cancel
					</Button>
					<Button
						onClick={onSubmit}
						variant="primary"
						className="py-2 px-4"
						disabled={loading}
					>
						{loading ? btnLoadingText : btnText}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default CreateUpdateProjectModal;
