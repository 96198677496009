import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import AppContext from 'context/Context';
import classNames from 'classnames';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import ProtectedLayout from './ProtectedLayout';

const MainLayout = () => {
  const { hash, pathname } = useLocation();

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <ProtectedLayout>
      <div className={isFluid ? 'container-fluid' : 'container'}>
        {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
          <NavbarVertical />
        )}
        <div
          className={classNames('content', 'content-body-container', 'pb-0')}
        >
          <NavbarTop />
          {/*------ Main Routes ------*/}
          <div className="mx-n3 px-3 py-4 bg-soft-secondary min-vh-100">
            <Outlet />
          </div>
        </div>
      </div>
    </ProtectedLayout>
  );
};

export default MainLayout;
