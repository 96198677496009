import { generateQueryString, handleError } from 'helpers/utils';
import axios from './axiosInstance';

export const getAllOrgsService = async (page = 1) => {
	try {
		const resp = await axios.get('/organization?page=' + page);
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};

export const createOrgService = async data => {
	try {
		const resp = await axios.post('/organization', data);
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};

export const updateOrgService = async (id, data) => {
	try {
		const resp = await axios.put('/organization/' + id, data);
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};

export const getOrgById = async id => {
	try {
		const resp = await axios.get('/organization/' + id);
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};

export const getUsersByOrgIdService = async (organizationId, page) => {
	let params = { organizationId, page };
	const queryString = generateQueryString(params);
	let query = '';
	if (queryString) {
		query += `?${queryString}`;
	}
	try {
		const resp = await axios.get('/user' + query);
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};

export const createUserService = async data => {
	try {
		const resp = await axios.post('/user', data);
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};
