import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function useError() {
	const navigate = useNavigate();
	const location = useLocation();
	const getResponse = res => {
		return new Promise((resolve, reject) => {
			if (!res.err) {
				return resolve(res);
			}
			if (res.err) {
				if (res.err.statusCode === 401) {
					navigate('/?authfailed=' + location.pathname);
				} else if (res.err.statusCode === 403) {
					toast(
						<span className="text-danger">
							You are not Authorised.
						</span>
					);
					reject(403);
				} else {
					// show err message
					toast(
						<span className="text-danger">
							{res?.err?.responseMessage?.message ??
								'Something went wrong! try again.'}
						</span>
					);
					reject(500);
				}
			}
		});
	};
	return { getResponse };
}

export default useError;
