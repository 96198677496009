import { generateQueryString, handleError } from 'helpers/utils';
import axios from './axiosInstance';

export const getIntegrationService = async () => {
	try {
		const resp = await axios.get('/integrations');
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};
