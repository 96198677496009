import React, { useEffect, useState } from 'react';
import '@yaireo/tagify/dist/tagify.css';
import 'assets/scss/tag_search.scss';
import Tagify from '@yaireo/tagify';
import { FormControl } from 'react-bootstrap';

export default function TagBox({
	label,
	labelClass = '',
	placeholder = '',
	id = '',
	tags = [],
	updateTags = () => {}
}) {
	const [tagsList, setTagsList] = useState(tags || []);
	useEffect(() => {
		const searchElement = document.getElementById(id);
		const tagify = new Tagify(searchElement, { editTags: false });

		tagify.addTags(tags);
		tagify.on('add', onTagAdded);
		tagify.on('remove', onTagRemoved);

		return () => {
			tagify.destroy(); // Cleanup when component unmounts
		};
	}, []);

	useEffect(() => {
		updateTags(tagsList);
	}, [tagsList]);

	function onTagAdded(e) {
		let tags_list = e.detail.tagify.value.map(tag => tag.value);
		setTagsList(tags_list);
	}

	function onTagRemoved(e) {
		let tags_list = e.detail.tagify.value.map(tag => tag.value);
		setTagsList(tags_list);
	}
	return (
		<div className="w-100 h-100">
			<FormControl
				id={id}
				className="w-100"
				placeholder={placeholder}
				autoFocus={true}
			/>
			{/* <label className={labelClass}>{label}</label> */}
		</div>
	);
}
