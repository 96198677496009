import React, { useContext, useState } from 'react';
import AddEditStep from '../killchains/AddEditStep';
import { AddStepContext, TestContext } from 'context/Context';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import GroupModal from './GroupModal';

export default function AddTestStep() {
	const [showGroupModal, setShowGroupModal] = useState(false);
	const [isCalling, setCalling] = useState(false);
	const {
		addStepState: { showModal, selectedScenarios },
		toggleModal,
		resetAddStep
	} = useContext(AddStepContext);
	const {
		testState: { tests },
		addTest,
		updateTestDataProps
	} = useContext(TestContext);

	console.log(tests);
	const handleAddSteps = async group_name => {
		setCalling(true);
		let newSteps = [];
		for (const scn of selectedScenarios) {
			let newObject = {
				id: uuidv4(),
				name: scn.name,
				description: scn.description,
				index: tests.length + newSteps.length + 1,
				group: group_name,
				successTechniqueId: null,
				failureTechniqueId: null,
				techniqueId: scn.id
			};
			if (newObject.index !== 1) {
				// 0 and 2 new
				if (newSteps.length !== 0) {
					newSteps[newSteps.length - 1] = {
						...newSteps[newSteps.length - 1],
						successTechniqueId: newObject.techniqueId,
						failureTechniqueId: newObject.techniqueId
					};
				} else {
					// 1 already and 1 new
					// tests 0 -> no action
					// tests 1 or more
					if (tests.length !== 0) {
						let lastTest = tests[tests.length - 1];
						updateTestDataProps(lastTest.id, {
							successTechniqueId: newObject.techniqueId,
							failureTechniqueId: newObject.techniqueId
						});
					}
				}
			}
			newSteps.push(newObject);
		}
		addTest(newSteps);
		resetAddStep();
		setCalling(false);
	};

	return (
		<React.Fragment>
			<AddEditStep
				title="Add Steps"
				show={showModal}
				handleClose={toggleModal}
				onSave={() => setShowGroupModal(true)}
				isSaveLoading={isCalling}
			/>
			{showGroupModal && (
				<GroupModal
					title="Attach Group"
					defaultName=""
					open={showGroupModal}
					handleClose={() => setShowGroupModal(false)}
					onSubmit={handleAddSteps}
				/>
			)}
		</React.Fragment>
	);
}
