import axios from 'axios';
axios.defaults.withCredentials = true;

const instance = axios.create({
	baseURL: '/api',
	// baseURL: 'http://127.0.0.1:4001/api',
	withCredentials: true,
	headers: {
		'Content-type': 'application/json'
	}
});

export default instance;
