import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { IntegrationContext } from 'context/Context';
import { integrationReducer } from 'reducers/integrationReducer';

const IntegrationProvider = ({ children }) => {
	const initData = {
		all: []
	};
	const [integrationState, dispatch] = useReducer(
		integrationReducer,
		initData
	);

	const saveAllIntegrations = data => {
		dispatch({
			type: 'SAVE_ALL',
			payload: data
		});
	};
	return (
		<IntegrationContext.Provider
			value={{
				integrationState,
				saveAllIntegrations
			}}
		>
			{children}
		</IntegrationContext.Provider>
	);
};

IntegrationProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export default IntegrationProvider;
