import React from 'react';
import CardHeader from 'components/common/CardHeader';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';

export default function ToolQuata() {
  const data = [
    { name: 'Nmap', total: 58, used: 30 },
    { name: 'OPenVas', total: 39, used: 18 },
    { name: 'Nessus', total: 28, used: 19 },
    { name: 'Qualys', total: 38, used: 27 },
    { name: 'Nexpose', total: 110, used: 80 },
    { name: 'Burp Suite', total: 20, used: 15 },
    { name: 'Tenable', total: 8, used: 1 },
    { name: 'Nikto', total: 81, used: 66 },
    { name: 'Zabbix', total: 70, used: 44 }
  ];
  const maxValue = 110;
  const getParentWidth = v => `${(v / maxValue) * 100}%`;
  const getChildWidth = (childValue, parentValue) =>
    `${(childValue / parentValue) * 100}%`;

  return (
    <Card className="h-100">
      <CardHeader
        title="Tools Quota"
        titleTag="h5"
        className="border-200 border-bottom py-3"
      />
      <Card.Body as={Flex} direction="column">
        {data.map(d => (
          <Row className="my-1">
            <Col md={8}>
              <div
                className="bg-soft-primary rounded"
                style={{ width: getParentWidth(d.total), height: 24 }}
              >
                <Flex
                  className="text-primary bg-400 text-nowrap rounded fs--2 fw-semi-bold h-100 align-items-center ps-1"
                  style={{ width: getChildWidth(d.used, d.total) }}
                >
                  {d.used + '/' + d.total}
                </Flex>
              </div>
            </Col>
            <Col md={4}>
              <div className="text-primary fs--1 fw-medium">{d.name}</div>
            </Col>
          </Row>
        ))}
      </Card.Body>
    </Card>
  );
}
