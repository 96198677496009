import classNames from 'classnames';
import { lowerize } from 'helpers/utils';
import React, { useContext } from 'react';
import { BaseEdge, EdgeLabelRenderer, getSmoothStepPath } from 'reactflow';

// import './buttonedge.css';

export default function CustomEdgeWithViewMode({
	id,
	sourceX,
	sourceY,
	targetX,
	targetY,
	sourcePosition,
	targetPosition,
	style = {},
	markerEnd,
	label
	//   className
}) {
	const [edgePath, labelX, labelY] = getSmoothStepPath({
		sourceX,
		sourceY,
		sourcePosition,
		targetX,
		targetY,
		targetPosition
	});
	return (
		<>
			<BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
			<EdgeLabelRenderer>
				<div
					style={{
						position: 'absolute',
						transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
						fontSize: 12,
						// everything inside EdgeLabelRenderer has no pointer events by default
						// if you have an interactive element, set pointer-events: all
						pointerEvents: 'all'
					}}
					className="nodrag nopan"
				>
					<button
						className={classNames(
							'edgebutton text-white border-none border-0 rounded hover-shadow',
							lowerize(label)
						)}
					>
						{label}
					</button>
				</div>
			</EdgeLabelRenderer>
		</>
	);
}
