import React from 'react';
import { Card } from 'react-bootstrap';
import CardHeader from 'components/common/CardHeader';

import nmap from 'assets/img/tools/nmap.png';
import nessus from 'assets/img/tools/nessus.png';
import nexpose from 'assets/img/tools/nexpose.png';
import aquatrivy from 'assets/img/tools/aquatrivy.png';
import besecure from 'assets/img/tools/besecure.png';
import Flex from 'components/common/Flex';

export default function ToolsScan() {
  return (
    <Card>
      <CardHeader
        title="Tools Scan watch-list (Top 5)"
        titleTag="h5"
        className="py-3 border-bottom"
        titleClass="text-black"
      />
      <Card.Body className="py-0">
        <Flex
          className={
            'justify-content-between align-items-center border-bottom pb-2 pt-3'
          }
        >
          <Flex>
            <img src={nmap} height={24} />
            <p className="mb-1">Nmap</p>
          </Flex>
          <div className="text-end">
            <h6>1024</h6>
            <p className="mb-0 fs--2">Scans performed</p>
          </div>
        </Flex>
        <Flex
          className={
            'justify-content-between align-items-center border-bottom pb-2 pt-3'
          }
        >
          <Flex>
            <img src={nexpose} height={24} />
            <p className="mb-1">Nexpose</p>
          </Flex>
          <div className="text-end">
            <h6>6049</h6>
            <p className="mb-0 fs--2">Scans performed</p>
          </div>
        </Flex>
        <Flex
          className={
            'justify-content-between align-items-center border-bottom pb-2 pt-3'
          }
        >
          <Flex>
            <img src={nessus} height={24} />
            <p className="mb-1">Nessus</p>
          </Flex>
          <div className="text-end">
            <h6>9294</h6>
            <p className="mb-0 fs--2">Scans performed</p>
          </div>
        </Flex>
        <Flex
          className={
            'justify-content-between align-items-center border-bottom pb-2 pt-3'
          }
        >
          <Flex>
            <img src={besecure} height={24} />
            <p className="mb-1">Besecure</p>
          </Flex>
          <div className="text-end">
            <h6 className="mb-0">24816</h6>
            <p className="mb-0 fs--2">Scans performed</p>
          </div>
        </Flex>
        <Flex
          className={'justify-content-between align-items-center pb-2 pt-3'}
        >
          <Flex>
            <img src={aquatrivy} height={24} />
            <p className="mb-1">Aqua Trivy</p>
          </Flex>
          <div className="text-end">
            <h6 className="mb-0">18204</h6>
            <p className="mb-0 fs--2">Scans performed</p>
          </div>
        </Flex>
      </Card.Body>
    </Card>
  );
}
