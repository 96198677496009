import { generateQueryString, handleError } from 'helpers/utils';
import axios from './axiosInstance';
import axiosG from 'axios';

export const getAllAgentService = async (
	organizationId = null,
	page = 1,
	search = null,
	environment = null,
	tags = null
) => {
	let params = { search, page, environment, tags, organizationId };
	const queryString = generateQueryString(params);
	let query = '';
	if (queryString) {
		query += `?${queryString}`;
	}
	try {
		const resp = await axios.get('/agent' + query);
		// const resp = await axiosG.get('http://localhost:1999/agents');
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};

export const createAgentService = async data => {
	try {
		const resp = await axios.post('/agent', data);
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};

export const getAgentByIdService = async id => {
	try {
		const resp = await axios.get('/agent/' + id);
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};

export const updateAgentService = async (id, data) => {
	try {
		const resp = await axios.put('/agent/' + id, data);
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};
