import React, { useState, useEffect } from 'react';
import ReactFlow, {
	useEdgesState,
	MiniMap,
	Controls,
	Background
} from 'reactflow';
import 'reactflow/dist/style.css';
import '../killchains/overview.css';

import CustomNodeWithViewMode from './CustomNodeWithViewMode';
import CustomEdgeWithViewMode from './CustomEdgeWithViewMode';

const connectionLineStyle = { stroke: '#000', type: 'smoothstep' };
const snapGrid = [20, 20];
const nodeTypes = {
	custom: CustomNodeWithViewMode
};
const edgeTypes = {
	custom: CustomEdgeWithViewMode
};
const minimapStyle = {
	height: 80
};
const defaultViewport = { x: 0, y: 0, zoom: 1 };

const ViewModeKillchainFlow = ({ flowNodes, flowEdges }) => {
	const [nodes, setNodes] = useState(flowNodes);
	const [edges, setEdges] = useEdgesState(flowEdges);

	useEffect(() => {
		setNodes(flowNodes);
	}, [flowNodes]);

	useEffect(() => {
		setEdges(flowEdges);
	}, [flowEdges]);

	return (
		<React.Fragment>
			<ReactFlow
				nodes={nodes}
				edges={edges}
				nodeTypes={nodeTypes}
				edgeTypes={edgeTypes}
				connectionLineStyle={connectionLineStyle}
				connectionLineType="smoothstep"
				snapToGrid={true}
				snapGrid={snapGrid}
				defaultViewport={defaultViewport}
				// fitView
				attributionPosition="bottom-left"
				style={{ backgroundColor: '#fff' }}
			>
				<MiniMap
					style={minimapStyle}
					zoomable
					pannable
					position="top-right"
				/>
				<Controls showInteractive={false} />
				<Background color="#aaa" gap={16} />
			</ReactFlow>
		</React.Fragment>
	);
};

export default ViewModeKillchainFlow;
