import Flex from 'components/common/Flex';
import React, { useLayoutEffect } from 'react';
import { Button, Col, Offcanvas, Row } from 'react-bootstrap';

export default function TemplateFullDetailViewModal({
	data,
	show,
	handleClose
}) {
	useLayoutEffect(() => {
		var styles = '.offcanvas{width: 40%!important;}';
		var styleSheet = document.createElement('style');
		styleSheet.innerText = styles;
		document.head.appendChild(styleSheet);
	}, []);

	return (
		<React.Fragment>
			<Offcanvas
				show={show}
				onHide={handleClose}
				placement="end"
				backdrop="static"
			>
				<Offcanvas.Header closeButton className="border-bottom">
					<Offcanvas.Title>{data?.name}</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Flex className="flex-column h-100">
						<div className="flex-grow-1">
							<div className="bg-white shadow">
								<div className="p-3 bg-primary text-white fw-medium">
									Description
								</div>
								<div className="p-3 ">
									<div className="fs--1 text-black fw-medium">
										{data?.description}
									</div>
									{data?.tests && (
										<div className="my-1 fs--1 text-black font-sans-serif">
											Tests:- {data?.tests}
										</div>
									)}
									{data?.Steps && (
										<div className="my-1 fs--1 text-black font-sans-serif">
											Scenarios:- {data?.Steps}
										</div>
									)}
								</div>
							</div>
						</div>
					</Flex>
				</Offcanvas.Body>
			</Offcanvas>
		</React.Fragment>
	);
}
