import React, { useRef } from 'react';
import CardHeader from 'components/common/CardHeader';
import { Card, Col, Form, Row } from 'react-bootstrap';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { PieChart } from 'echarts/charts';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import { cateProjectData } from 'data/dashboard';
import Flex from 'components/common/Flex';
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer
]);

const getOptions = data => ({
  //   color: [
  //     getColor('warning'),
  //     getColor('danger'),
  //     getColor('primary'),
  //     getColor('info')
  //   ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: function (params) {
      console.log(params, 'ff');
      return `<div><div>Project Type: ${params.name}</div><div>Total:- ${params.value}</div></div>`;
    }
  },
  legend: { show: false },
  series: [
    {
      name: 'Total',
      type: 'pie',
      radius: ['100%', '57%'],
      avoidLabelOverlap: false,
      itemStyle: {
        // borderWidth: 2,
        // borderColor: getColor('gray-100')
      },
      label: {
        show: false
      },
      emphasis: {
        scale: false
      },
      labelLine: { show: false },
      data: data
    }
  ]
});

export default function CategoryProjectsPie() {
  const chartRef = useRef(null);

  return (
    <Card className="">
      <CardHeader
        title="Task Summary"
        titleTag="h5"
        className="py-3 border-bottom"
        titleClass="text-black"
        endEl={
          <Form.Select className="text-black fw-medium" size="sm">
            <option>Last 7 days</option>
            <option>Last Month</option>
            <option>Last Year</option>
          </Form.Select>
        }
      />
      <Card.Body>
        <Row>
          <Col md={5}>
            <div className="d-flex justify-content-center position-relative">
              <ReactEChartsCore
                ref={chartRef}
                echarts={echarts}
                option={getOptions(cateProjectData)}
                style={{ height: '16.5rem', width: '16.5rem' }}
              />
              <div className="position-absolute top-50 start-50 translate-middle text-center">
                <p className="fs-4 mb-0 text-black font-sans-serif fw-bold">
                  393
                </p>
                <p className="fs--1 mb-0 text-black font-sans-serif fw-medium">
                  Total Projects
                </p>
              </div>
            </div>
          </Col>
          <Col md={7} className="d-flex align-items-center">
            <Row>
              {cateProjectData.map(cate => (
                <Col md={6}>
                  <Flex
                    className={'justify-content-between align-items-center'}
                  >
                    <Flex className={'align-items-center'}>
                      <div
                        style={{ width: 12, height: 12 }}
                        className={`rounded-circle bg-${cate.color} me-2`}
                      ></div>
                      <h6 className="text-700 lh-base mb-0 fs--1">
                        {cate.name}
                      </h6>
                    </Flex>
                    <h6 className="text-black mb-0 fs--1 fw-semi-bold">
                      {cate.value}
                    </h6>
                  </Flex>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
