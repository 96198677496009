import React from 'react';
import { Card } from 'react-bootstrap';
import { HiArrowNarrowUp } from 'react-icons/hi';
import projects from 'assets/img/icons/tools.png';
import Flex from 'components/common/Flex';

const CountItem = ({ title, growth, count, imgUrl, bgVarient }) => {
  return (
    <div className="p-1 my-2">
      <p className="text-900 fw-semi-bold fs--1 mb-1">{title}</p>
      <Flex>
        <div className={`flex-fill rounded-3 px-3 py-2 bg-${bgVarient} me-2`}>
          <Flex alignItems={'center'} className={'h-100'}>
            <div className="me-2">
              <img src={imgUrl} height={16} className="mb-1" />
            </div>
            <h5 className="text-white fw-normal mb-0">{count}</h5>
          </Flex>
        </div>
        <div
          className="rounded-3"
          style={{ width: 98, height: 56, backgroundColor: '#E1E1FB' }}
        >
          <Flex className={'p-2 align-items-center'}>
            <HiArrowNarrowUp size={24} color="black" />
            <div>
              <p className="fs--1 text-900 mb-1 fw-semi-bold text-center">
                {growth}%
              </p>
              <p className="fs--2 text-900 mb-0">Last Week</p>
            </div>
          </Flex>
        </div>
      </Flex>
    </div>
  );
};

export default function CountOverview() {
  return (
    <Card className="p-2">
      <CountItem
        title={'Users Count'}
        imgUrl={projects}
        growth={5.63}
        count={14302}
        bgVarient="primary"
      />
      <CountItem
        title={'Projects count'}
        imgUrl={projects}
        growth={5.63}
        count={2731}
        bgVarient="warning"
      />
      <CountItem
        title={'Tasks count'}
        imgUrl={projects}
        growth={5.63}
        count={34980}
        bgVarient="success"
      />
    </Card>
  );
}
