import CardHeader from 'components/common/CardHeader';
import React from 'react';
import { Card, Form } from 'react-bootstrap';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { useRef } from 'react';
import useDimention from 'hooks/useDimention';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer
]);

const getOptions = data => ({
  xAxis: {
    type: 'category',
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      data: [150, 230, 224, 218, 135, 147, 260],
      type: 'line'
    }
  ],
  grid: { left: 0, bottom: 0, right: 0, top: 10, containLabel: true }
});

export default function TaskSummarySingleLine() {
  const chartRef = useRef(null);
  const { ref, width } = useDimention();

  return (
    <Card className="">
      <CardHeader
        title="Task Summary"
        titleTag="h5"
        className="py-3 border-bottom"
        titleClass="text-black"
        endEl={
          <Form.Select className="text-black fw-medium" size="sm">
            <option>Last 7 days</option>
            <option>Last Month</option>
            <option>Last Year</option>
          </Form.Select>
        }
      />
      <Card.Body>
        <div className="position-relative" ref={ref}>
          {width > 0 && (
            <ReactEChartsCore
              ref={chartRef}
              echarts={echarts}
              option={getOptions([])}
              style={{ height: '20rem', width: width }}
            />
          )}
        </div>
      </Card.Body>
    </Card>
  );
}
