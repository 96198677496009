import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getKillChainByIdService } from 'services/killchainService';
import { ReactFlowProvider } from 'reactflow';
import ViewModeKillchainFlow from './orgs/ViewModeKillchainFlow';
import KillchainLoader from 'components/common/KillchainLoader';
import { StepContext } from 'context/Context';
import Flex from 'components/common/Flex';
import useError from 'hooks/useError';

export default function AssessmentFlowView() {
	const { getResponse } = useError();
	const [isFetching, setFetching] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const { id } = useParams();
	const [shortDetails, setDetails] = useState({ name: '', description: '' });

	const {
		stepState,
		addNodesToStep,
		addEdgesToStep,
		updateKillchain,
		resetStep
	} = useContext(StepContext);

	useEffect(() => {
		getKillChain();
		// updateKillchain(id);
		return () => {
			resetStep();
		};
	}, []);

	const getKillChain = async () => {
		setFetching(true);
		const res = await getKillChainByIdService(id);
		getResponse(res)
			.then(res => {
				setDetails({ name: res.name, description: res.description });
				handleSteps(res.Steps);
				setFetching(false);
			})
			.catch(err => {
				setFetching(false);
			});
	};

	const handleSteps = steps => {
		let nodeArr = [];
		let edgeArr = [];
		steps.forEach(step => {
			nodeArr.push({
				...step?.node,
				type: 'custom',
				data: {
					name: step?.name || step?.techniqueName,
					description:
						step?.description || step?.techniqueDescription,
					isPaused: step?.isPaused,
					scheduledAt: step?.scheduledAt,
					stepId: step?.id,
					status: step?.status,
					detailStatus: 'completed',
					number: stepState.nodes.length + nodeArr.length + 1
				}
			});
			step?.edges.forEach(edge => {
				edgeArr.push(edge);
			});
		});
		addNodesToStep(nodeArr);
		addEdgesToStep(edgeArr);
	};

	return (
		<React.Fragment>
			<div className="mb-4">
				<Flex>
					<h4>{shortDetails.name}</h4>
					{/* <IconButton
                className="ms-auto"
                variant="falcon-default"
                icon="ellipsis-h"
                iconAlign="right"
                transform="shrink-3"
              >
                More Options{' '}
              </IconButton> */}
				</Flex>
				<div className="mt-3">
					<div className="fs--1 fw-medium">
						{shortDetails.description}
					</div>
				</div>
			</div>
			{isFetching ? (
				<KillchainLoader />
			) : (
				<div className="my-4">
					<div className="position-relative" style={{ height: 400 }}>
						<ReactFlowProvider>
							<ViewModeKillchainFlow
								flowNodes={stepState?.nodes}
								flowEdges={stepState?.edges}
							/>
						</ReactFlowProvider>
					</div>
				</div>
			)}
		</React.Fragment>
	);
}
