import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import ActionButton from './ActionButton';
import { v4 as uuidv4 } from 'uuid';
import { convertTechniqueFormSchemaIntoFormat } from 'helpers/utils';

const DynamicForms = ({ formsData }) => {
	const [forms, setForms] = useState([
		// {
		//   id: 'fdshfjdhlkfdlgdfgjjhlkkftd',
		//   label: 'Form 1',
		//   description: 'This is Form 1',
		//   forms: [
		//     {
		//       id: 'fdfsdfsuiuoyiytsdg',
		//       fields: [
		//         {
		//           id: 'ffname_fdfsdfsuiuoyiytsdg',
		//           type: 'text',
		//           label: 'Name',
		//           name: 'name'
		//         },
		//         {
		//           id: 'ffgender_fdfsdfsuiuoyiytsdg',
		//           type: 'select',
		//           label: 'Gender',
		//           name: 'gender',
		//           options: ['Male', 'Female', 'Other']
		//         }
		//       ]
		//     }
		//   ],
		//   fieldStructure: [
		//     { type: 'text', label: 'Name', name: 'name' },
		//     {
		//       type: 'select',
		//       label: 'Gender',
		//       name: 'gender',
		//       options: ['Male', 'Female', 'Other']
		//     }
		//   ],
		//   multiple: false
		// },
		// {
		//   id: 'fdshfjdhlkfdlmnninubbuyvyt',
		//   label: 'Form 2',
		//   description: 'This is Form 2',
		//   forms: [
		//     {
		//       id: 'fsdg16573538684gsgfdsuvbj',
		//       fields: [
		//         {
		//           id: 'ffaddress_fsdg16573538684gsgfdsuvbj',
		//           type: 'textarea',
		//           label: 'Address',
		//           name: 'address'
		//         },
		//         {
		//           id: 'ffterms_fsdg16573538684gsgfdsuvbj',
		//           type: 'checkbox',
		//           label: 'Agree to Terms',
		//           name: 'terms'
		//         }
		//       ]
		//     }
		//   ],
		//   fieldStructure: [
		//     { type: 'textarea', label: 'Address', name: 'address' },
		//     { type: 'checkbox', label: 'Agree to Terms', name: 'terms' }
		//   ],
		//   multiple: true,
		//   max: 3
		// },
		// {
		//   id: 'fsdflspkofmsfsdfghgedqn937ctyw4b',
		//   label: 'Form 3',
		//   description: 'This is Form 2',
		//   forms: [
		//     {
		//       id: 'fsdfghgedqn937ctyw4b',
		//       fields: [
		//         {
		//           id: 'ffauthorimage_fsdfghgedqn937ctyw4b',
		//           type: 'file',
		//           label: 'Author Image',
		//           name: 'authorimage'
		//         },
		//         {
		//           id: 'ffgender_fsdfghgedqn937ctyw4b',
		//           type: 'radio',
		//           label: 'Gender',
		//           name: 'gender',
		//           options: ['Male', 'Female', 'Other']
		//         }
		//       ]
		//     }
		//   ],
		//   fieldStructure: [
		//     { type: 'file', label: 'Author Image', name: 'authorimage' },
		//     {
		//       type: 'radio',
		//       label: 'Gender',
		//       name: 'gender',
		//       options: ['Male', 'Female', 'Other']
		//     }
		//   ],
		//   multiple: true,
		//   max: 2
		// }
	]);

	useEffect(() => {
		let copy = convertTechniqueFormSchemaIntoFormat(formsData);
		console.log('cp', copy);
		setForms(copy);
	}, []);

	const handleInputChange = (formId, fieldIndex, event, childId) => {
		const { name, value } = event.target;
		let formCopy = forms.slice();
		let formIndex = formCopy.findIndex(f => f.id === formId);
		let cForms = formCopy[formIndex].forms;
		let cfIndex = cForms.findIndex(cf => cf.id === childId);
		cForms[cfIndex].fields[fieldIndex].value = value;
		formCopy[formIndex].forms = cForms;
		setForms(formCopy);
	};

	const handleAddForm = (formId, max) => {
		let formCopy = forms.slice();
		let formIndex = formCopy.findIndex(f => f.id === formId);
		if (formCopy[formIndex].forms.length === max) {
			return;
		}
		let fields = formCopy[formIndex].fieldStructure.map(f => ({
			id: uuidv4(),
			...f
		}));
		formCopy[formIndex].forms.push({
			id: uuidv4(),
			fields: fields
		});
		setForms(formCopy);
	};

	const handleRemoveForm = (formId, childId) => {
		let formCopy = forms.slice();
		let formIndex = formCopy.findIndex(f => f.id === formId);
		formCopy[formIndex].forms = formCopy[formIndex].forms.filter(
			cf => cf.id !== childId
		);
		setForms(formCopy);
	};

	const renderField = (formId, field, fieldIndex, childFormId) => {
		const { type, label, name, options } = field;
		let field_type = type === 'number' || type === 'text' ? 'input' : type;
		switch (field_type) {
			case 'input':
				return (
					<Form.Control
						type={type}
						name={name}
						className="mb-2"
						defaultValue={field.default}
						value={field.value}
						onChange={event =>
							handleInputChange(
								formId,
								fieldIndex,
								event,
								childFormId
							)
						}
					/>
				);
			case 'textarea':
				return (
					<Form.Control
						as="textarea"
						name={name}
						className="mb-2"
						value={field.value || ''}
						onChange={event =>
							handleInputChange(
								formId,
								fieldIndex,
								event,
								childFormId
							)
						}
					/>
				);
			case 'select':
				return (
					<Form.Control
						as="select"
						name={name}
						className="mb-2"
						value={field.value || ''}
						onChange={event =>
							handleInputChange(
								formId,
								fieldIndex,
								event,
								childFormId
							)
						}
					>
						{options.map((option, index) => (
							<option key={index} value={option.value}>
								{option.label}
							</option>
						))}
					</Form.Control>
				);
			case 'checkbox':
				if (field.multiple) {
					{
						options.map((option, index) => (
							<Form.Check
								key={option.value}
								type="checkbox"
								label={option.label}
								name={name}
								className="mb-2"
								checked={field.value == option.value}
								onChange={event =>
									handleInputChange(
										formId,
										fieldIndex,
										event,
										childFormId
									)
								}
							/>
						));
					}
				} else {
					return (
						<Form.Check
							type="checkbox"
							label={label}
							name={name}
							className="mb-2"
							checked={field.value || false}
							onChange={event =>
								handleInputChange(
									formId,
									fieldIndex,
									event,
									childFormId
								)
							}
						/>
					);
				}
			case 'radio':
				return (
					<Form.Group as={Row} className="mb-2">
						{options.map((option, index) => (
							<Col>
								<Form.Check
									key={index}
									type="radio"
									label={option.label}
									className="mb-0"
									name={name}
									value={option.value}
									checked={field.value === option.value}
									onChange={event =>
										handleInputChange(
											formId,
											fieldIndex,
											event,
											childFormId
										)
									}
								/>
							</Col>
						))}
					</Form.Group>
				);
			case 'file':
				return (
					<Form.Control
						type="file"
						name={name}
						className="mb-2"
						onChange={event =>
							handleInputChange(
								formId,
								fieldIndex,
								event,
								childFormId
							)
						}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<div>
			{forms.map((form, formIndex) => (
				<div key={form.id}>
					<h5>{form.label}</h5>
					<div className="fw-medium fs--1 mb-2">
						{form.description}
					</div>
					<Form>
						{form.forms.map(
							(childForm, arrayIndex) => (
								<Row className="" key={childForm.id}>
									<Col>
										<Form
											className="mb-2"
											key={childForm.id}
										>
											{childForm.fields.map(
												(field, fieldIndex) => (
													<div key={field}>
														<Form.Label className="mb-0">
															{field.label}
														</Form.Label>
														{renderField(
															form.id,
															field,
															fieldIndex,
															childForm.id
														)}
													</div>
												)
											)}
										</Form>
									</Col>

									{arrayIndex !== 0 ? (
										<Col sm={2}>
											<ActionButton
												className="mt-4"
												title={'Remove'}
												icon={'trash-alt'}
												onClick={() =>
													handleRemoveForm(
														form.id,
														childForm.id
													)
												}
											/>
										</Col>
									) : null}
								</Row>
							)
							// ) : (
							//   <Form>
							//     {form.forms[0].fields.map((field, fieldIndex) => (
							//       <div key={fieldIndex}>
							//         <Form.Label className="mb-0">{field.label}</Form.Label>

							//         {renderField(formIndex, field, fieldIndex)}
							//       </div>
							//     ))}
							//   </Form>
						)}
						{form.multiple && form.forms.length < form.max && (
							<Button
								variant="primary"
								onClick={() => handleAddForm(form.id, form.max)}
							>
								Add More
							</Button>
						)}
					</Form>
					<hr />
				</div>
			))}
		</div>
	);
};

export default DynamicForms;

DynamicForms.defaultProps = {
	formsData: [
		{
			type: 'heading',
			value: 'Heading'
		},
		{
			type: 'description',
			value: 'Description'
		},
		{
			type: 'form_group',
			output_key: 'group1',
			is_multiple: true,
			required: true,
			max_count: 3,
			inputs: [
				{
					type: 'text',
					label: 'Text',
					placeholder: 'bar',
					default: 'foo',
					output_key: 'key1',
					output_type: 'string'
				},
				{
					type: 'number',
					label: 'Number',
					placeholder: 'bar',
					default: 1,
					output_key: 'key2',
					output_type: 'number'
				},
				{
					type: 'textarea',
					label: 'Textarea',
					placeholder: 'bar',
					default: 'foo',
					output_key: 'textarea',
					output_type: 'string'
				}
			]
		},
		{
			type: 'file',
			label: 'File',
			placeholder: 'bar',
			output_key: 'file',
			output_file_name: 'foo.txt',
			allowed_extensions: ['txt'],
			output_type: 'file'
		},
		{
			type: 'select',
			label: 'Select',
			options: [
				{
					label: 'Foo',
					value: 'foo'
				},
				{
					label: 'Bar',
					value: 'bar'
				}
			],
			output_key: 'select',
			output_type: 'string'
		},
		{
			type: 'checkbox',
			label: 'Checkbox',
			max_check_count: 2,
			options: [
				{
					label: 'Foo',
					value: 'foo'
				},
				{
					label: 'Bar',
					value: 'bar'
				}
			],
			output_key: 'checkbox',
			output_type: 'string'
		},
		{
			type: 'radio',
			label: 'Radio',
			options: [
				{
					label: 'Foo',
					value: 'foo'
				},
				{
					label: 'Bar',
					value: 'bar'
				}
			],
			output_key: 'radio',
			output_type: 'string'
		},
		{
			type: 'textarea',
			label: 'Textarea',
			placeholder: 'bar',
			default: 'foo',
			output_key: 'textarea',
			output_type: 'string'
		}
	]
};
