import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { TestContext } from 'context/Context';
import { testReducer } from 'reducers/testReducer';

const TestProvider = ({ children }) => {
	const initData = {
		tests: [],
		selectedTest: null
	};
	const [testState, dispatch] = useReducer(testReducer, initData);

	const addTest = tests => {
		dispatch({
			type: 'ADD_TESTS',
			payload: tests
		});
	};

	const updateTestDataProps = (id, data) => {
		dispatch({
			type: 'UPDATE_TESTS',
			payload: { id, data }
		});
	};

	const updateSelectedTest = id => {
		dispatch({ type: 'SELECT_TEST', payload: id });
	};

	const resetTest = () =>
		dispatch({ type: 'RESET_TESTS', payload: initData });

	return (
		<TestContext.Provider
			value={{
				testState,
				addTest,
				updateTestDataProps,
				resetTest,
				updateSelectedTest
			}}
		>
			{children}
		</TestContext.Provider>
	);
};

TestProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export default TestProvider;
