import React from 'react';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import { Card, Col, Row } from 'react-bootstrap';
import CategoryTasksChart from './CategoryTasksChart';
import { cateTAskData } from 'data/dashboard';
import classNames from 'classnames';
import CardHeader from 'components/common/CardHeader';

const CategoryTasks = () => {
  return (
    <Card className="h-100">
      <CardHeader
        title="Tasks by category"
        titleTag="h5"
        className="border-200 border-bottom py-3"
      />
      <Card.Body as={Flex} direction="column">
        <div className="mb-auto">
          <CategoryTasksChart data={cateTAskData} />
        </div>
        <Row>
          {cateTAskData.map((item, index) => (
            <Col xs={6} className="mb-2">
              <Flex className={'align-items-center'}>
                <div
                  className={`border border-2 border-${item.color} rounded-circle mx-2`}
                  style={{ width: 12, height: 12 }}
                ></div>
                <h6 className="text-500 mb-0 fw-normal">{item.name}</h6>
              </Flex>
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CategoryTasks;
