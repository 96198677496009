import React from 'react';
import ReactPaginate from 'react-paginate';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { useRef } from 'react';

export default function usePagination(handleFetch) {
  const currentPage = useRef(1);

  const handlePageChange = selectedObject => {
    currentPage.current = selectedObject.selected + 1;
    handleFetch(selectedObject.selected + 1);
  };

  const Pagination = React.useMemo(() => {
    const PaginationComponent = ({ totalPages }) => {
      return (
        <ReactPaginate
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          containerClassName={'react-paginate-container'}
          previousLinkClassName={'page'}
          breakClassName={'page'}
          nextLinkClassName={'page'}
          pageClassName={'page'}
          disabledClassNae={'disabled'}
          activeClassName={'active'}
          renderOnZeroPageCount={null}
          previousLabel={<MdChevronLeft size={20} />}
          nextLabel={<MdChevronRight size={20} />}
          breakLabel={'...'}
        />
      );
    };
    return PaginationComponent;
  }, []);

  return {
    Pagination,
    currentPage: currentPage.current
  };
}
