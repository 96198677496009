import React from 'react';
import TechniqueCreateEditComponent from './techniques/TechniqueCreateEditComponent';
import { useLayoutEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
	getMasterTemplateByName,
	getTechniqueById,
	updateTechniqueService
} from 'services/techniqueService';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { attachTagsService } from 'services/tagService';
import { getRefactoredTechniqueResponse } from 'helpers/utils';
import useError from 'hooks/useError';

export default function TechniqueEdit() {
	const { getResponse } = useError();
	const { id } = useParams();
	const [formData, setFormData] = useState({
		name: '',
		description: '',
		masterConfig: '',
		extraConfig: '',
		author: null,
		mitigation: null,
		status: 'draft',
		type: null,
		integrationInstruction: null
	});
	const [tags, setTags] = useState([]);
	const [isFetching, setFecthing] = useState(false);

	const handleFieldChange = e => {
		const { id, value } = e.target;
		setFormData({ ...formData, [id]: value });
	};

	useLayoutEffect(() => {
		getTechniqueData(id);
	}, []);

	const getTechniqueData = async id => {
		setFecthing(true);
		const res = await getTechniqueById(id);
		getResponse(res)
			.then(async res => {
				const masterConfigRes = await getMasterTemplateByName(
					res?.technique?.name
				);
				getResponse(masterConfigRes)
					.then(async masterRes => {
						const {
							createdAt,
							deletedAt,
							updatedAt,
							...masterResponse
						} = getRefactoredTechniqueResponse(masterRes);
						setFormData({
							...formData,
							name: res?.technique?.name,
							description: res?.technique?.description,
							author: res?.technique?.author,
							status: res?.technique?.status,
							extraConfig: {
								query: res?.technique?.query,
								formSchema: res?.formSchema?.formSchema || {}
							},
							masterConfig: masterResponse,
							mitigation:
								res?.technique?.mitigationRecommendations,
							type: res?.technique?.type,
							integrationInstruction: res?.integrationInstruction
						});
						let techTags = res?.technique?.Taggable?.map(
							each => each?.tag
						).map(tag => tag?.name);
						setTags(techTags);
						setFecthing(false);
					})
					.catch(err => {
						setFecthing(false);
					});
			})
			.catch(err => {
				setFecthing(false);
			});
	};

	/**
	 * getting instruction of integration
	 */
	const getInstructionOfIntegration = value => {
		try {
			return formData?.integrationInstruction?.instruction?.[value];
		} catch (err) {
			return {};
		}
	};

	const handleSubmit = async () => {
		const { id: masterId, ...refactorObj } = {
			name: formData.name,
			description: formData.description,
			...formData.masterConfig,
			...formData.extraConfig,
			mitigationRecommendations: formData.mitigation,
			status: formData.status,
			author: formData.author,
			type: formData.type
		};
		const { formSchema, ...technique } = refactorObj;
		const res = await updateTechniqueService(id, {
			technique,
			formSchema,
			integrationInstruction: {
				...(formData?.integrationInstruction?.instruction || {})
			}
		});
		getResponse(res)
			.then(async res => {
				const tagRes = await attachTagsService({
					names: tags,
					taggableName: 'Technique',
					taggableId: id
				});
				getResponse(tagRes)
					.then(async res => {
						toast.success('Technique edited successfully!');
					})
					.catch(err => console.error(err));
			})
			.catch(err => console.error(err));
	};

	return (
		<React.Fragment>
			<TechniqueCreateEditComponent
				title={'Edit Technique'}
				submitButtonText={'Save Technique'}
				onSubmit={handleSubmit}
				data={formData}
				handleFieldChange={handleFieldChange}
				isFetching={isFetching}
				tags={tags}
				handleTags={setTags}
				getInstructionOfIntegration={getInstructionOfIntegration}
			/>
		</React.Fragment>
	);
}
