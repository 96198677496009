import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { settings } from './config';
import { getItemFromStore } from 'helpers/utils';
import { configReducer } from './reducers/configReducer';
import useToggleStyle from './hooks/useToggleStyle';
import TemplateProvider from 'providers/TemplateProvider';
import TechniqueProvider from 'providers/TechniqueProvider';
import AddStepProvider from 'providers/AddStepProvider';
import EditStepProvider from 'providers/EditStepProvider';
import StepProvider from 'providers/StepProvider';
import AuthProvider from 'providers/AuthProvider';
import OrgProvider from 'providers/OrgProvider';
import AgentProvider from 'providers/AgentProvider';
import TestProvider from 'providers/TestProvider';
import IntegrationProvider from 'providers/IntegrationProvider';

// import { Chart as ChartJS, registerables } from 'chart.js';
// ChartJS.register(...registerables);

const Main = props => {
	const configState = {
		isFluid: getItemFromStore('isFluid', settings.isFluid),
		isRTL: getItemFromStore('isRTL', settings.isRTL),
		isDark: getItemFromStore('isDark', settings.isDark),
		navbarPosition: getItemFromStore(
			'navbarPosition',
			settings.navbarPosition
		),
		disabledNavbarPosition: [],
		isNavbarVerticalCollapsed: getItemFromStore(
			'isNavbarVerticalCollapsed',
			settings.isNavbarVerticalCollapsed
		),
		navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
		currency: settings.currency,
		showBurgerMenu: settings.showBurgerMenu,
		showSettingPanel: false,
		navbarCollapsed: false
	};

	const [config, configDispatch] = useReducer(configReducer, configState);

	useToggleStyle(config.isRTL, config.isDark, configDispatch);

	const setConfig = (key, value) => {
		configDispatch({
			type: 'SET_CONFIG',
			payload: {
				key,
				value,
				setInStore: [
					'isFluid',
					'isRTL',
					'isDark',
					'navbarPosition',
					'isNavbarVerticalCollapsed',
					'navbarStyle'
				].includes(key)
			}
		});
	};

	return (
		<AppContext.Provider value={{ config, setConfig }}>
			<AuthProvider>
				<OrgProvider>
					<AgentProvider>
						<TemplateProvider>
							<TestProvider>
								<StepProvider>
									<AddStepProvider>
										<EditStepProvider>
											<TechniqueProvider>
												<IntegrationProvider>
													{props.children}
												</IntegrationProvider>
											</TechniqueProvider>
										</EditStepProvider>
									</AddStepProvider>
								</StepProvider>
							</TestProvider>
						</TemplateProvider>
					</AgentProvider>
				</OrgProvider>
			</AuthProvider>
		</AppContext.Provider>
	);
};

Main.propTypes = { children: PropTypes.node };

export default Main;
