import React, { useCallback, useContext, useEffect, useState } from 'react';
import AssessmentView from './templates/AssessmentView';
import { Button, Col, Row } from 'react-bootstrap';
import TemplateFullDetailViewModal from './templates/TemplateFullDetailViewModal';
import {
	createTemplateService,
	getAllTemplatesService
} from 'services/templateService';
import { TemplateContext } from 'context/Context';
import { Link } from 'react-router-dom';
import usePagination from 'hooks/usePagination';
import { useDebounce } from 'use-debounce';
import useError from 'hooks/useError';

export default function Templates() {
	const { getResponse } = useError();
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState('');
	const [debouncedText] = useDebounce(search, 1000);
	const [selectedAssessmentType, setAssessmentType] = useState({
		value: null,
		label: 'Select'
	});
	const [isTemplateFetching, setTemplateFetching] = useState(false);

	const getAllTemplates = useCallback(
		async (page, debouncedText = null) => {
			setTemplateFetching(true);
			const res = await getAllTemplatesService(
				page,
				debouncedText,
				selectedAssessmentType.value
			);
			getResponse(res)
				.then(res => {
					saveAllTemplates(res);
					setTemplateFetching(false);
				})
				.catch(err => {
					setTemplateFetching(false);
				});
		},
		[debouncedText, selectedAssessmentType]
	);

	const { Pagination } = usePagination(setPage);
	const { templateState, saveAllTemplates, addTemplate } =
		useContext(TemplateContext);

	const [selectedTemplate, setTemplate] = useState(null);
	const [showCreateUpdateModal, setCreateUpdateModal] = useState(false);
	const [showTemplateViewModal, setTemplateViewModal] = useState(false);

	const handleCreateUpdateModalOpen = () => setCreateUpdateModal(true);
	const handleCreateUpdateModalClose = () => {
		setCreateUpdateModal(false);
		if (selectedTemplate != null) {
			setTemplate(null);
		}
	};
	const handleTemplateViewModalClose = () => {
		setTemplate(null);
		setTemplateViewModal(false);
	};
	const handleTemplateView = template => {
		setTemplate(template);
		setTemplateViewModal(true);
	};
	const handleTemplateUpdateOpen = template => {
		setTemplate(template);
		handleCreateUpdateModalOpen();
	};

	useEffect(() => {
		getAllTemplates(page, debouncedText, selectedAssessmentType);
	}, [page, debouncedText, selectedAssessmentType]);

	return (
		<React.Fragment>
			<Row className="flex-between-center mb-3">
				<Col md>
					<h5 className="mb-2 mb-md-0">Templates</h5>
				</Col>
				<Col xs="auto">
					<Button
						as={Link}
						variant="primary"
						className="me-2 mb-1"
						to="/template/create"
					>
						Create New Template
					</Button>
				</Col>
			</Row>
			<AssessmentView
				data={templateState?.templates}
				onAssessmentView={handleTemplateView}
				isLoading={isTemplateFetching}
				search={search}
				setSearch={setSearch}
				selectedAssessmentType={selectedAssessmentType}
				setAssessmentType={setAssessmentType}
			/>
			{templateState.templates?.total_pages ? (
				<Pagination totalPages={templateState.templates.total_pages} />
			) : null}
			<TemplateFullDetailViewModal
				data={selectedTemplate}
				show={showTemplateViewModal}
				handleClose={handleTemplateViewModalClose}
			/>
		</React.Fragment>
	);
}
