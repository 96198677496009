import React from 'react';
import TechniqueCreateEditComponent from './techniques/TechniqueCreateEditComponent';
import { createTechniqueService } from 'services/techniqueService';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { attachTagsService } from 'services/tagService';
import useError from 'hooks/useError';

export default function TechniqueCreate() {
	const navigate = useNavigate();
	const { getResponse } = useError();
	const [formData, setFormData] = useState({
		name: '',
		description: '',
		author: null,
		mitigation: null,
		masterConfig: {},
		extraConfig: {},
		status: 'draft',
		type: 'killchain'
	});
	const [tags, setTags] = useState([]);
	const handleFieldChange = e => {
		const { id, value } = e.target;
		setFormData({ ...formData, [id]: value });
	};
	const handleSubmit = async () => {
		const refactorObj = {
			name: formData.name,
			description: formData.description,
			author: formData.author,
			...formData.masterConfig,
			...formData.extraConfig,
			mitigationRecommendations: formData.mitigation,
			status: formData.status,
			type: formData.type
		};
		const { formSchema, ...body } = refactorObj;
		const res = await createTechniqueService({
			body,
			formSchema,
			integrationInstruction: {}
		});
		getResponse(res)
			.then(async res => {
				const tagRes = await attachTagsService({
					names: tags,
					taggableName: 'Technique',
					taggableId: res.id
				});
				getResponse(tagRes)
					.then(async res => {
						toast(
							<span className="text-success">
								Technique created successfully!
							</span>
						);
						navigate('/techniques');
					})
					.catch(err => console.error(err));
			})
			.catch(err => console.error(err));
	};
	return (
		<React.Fragment>
			<TechniqueCreateEditComponent
				title={'Create Technique'}
				submitButtonText={'Create Technique'}
				onSubmit={handleSubmit}
				data={formData}
				handleFieldChange={handleFieldChange}
				tags={tags}
				handleTags={setTags}
			/>
		</React.Fragment>
	);
}
