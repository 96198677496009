import { handleError } from 'helpers/utils';
import axios from './axiosInstance';

export const logoutService = async () => {
	try {
		const resp = await axios.get('/auth/logout');
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};

export const getNotificationsService = async page => {
	let query = '';
	if (page) {
		query = `?page=${page}`;
	}
	try {
		const resp = await axios.get('/notification' + query);
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};
