import React from 'react';
import { Button, ButtonGroup, Col, Row } from 'react-bootstrap';
import { MdOutlineList } from 'react-icons/md';
import { RiGridFill } from 'react-icons/ri';
import SelectBox from 'components/common/SelectBox';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';
import SearchBox from 'components/navbar/top/SearchBox';
import PropTypes from 'prop-types';

export default function AssessmentHeader({
	selectedType,
	viewTypes,
	onViewChange,
	search,
	setSearch,
	selectedAssessmentType,
	setAssessmentType
}) {
	return (
		<Row className="align-items-end">
			<Col>
				<Flex>
					<SearchBox
						inputClass="bg-transparent border-0 border-bottom rounded-0"
						searchContainerClasses="w-100"
						searchInputValue={search}
						setSearchInputValue={setSearch}
					/>
				</Flex>
			</Col>
			<Col md={2}>
				<SelectBox
					title={'Type'}
					options={[
						{ value: '', label: 'Select' },
						{ value: 'killchain', label: 'APTs' },
						{ value: 'test', label: 'Tests' }
					]}
					value={selectedAssessmentType}
					onChange={setAssessmentType}
					style={{ minWidth: 'unset' }}
				/>
			</Col>
			<Col md="auto">
				{/* <IconButton
					className="me-2"
					variant="falcon-default"
					icon="filter"
					iconAlign="right"
					transform="shrink-3"
				>
					FILTER
				</IconButton> */}
				<ButtonGroup>
					{viewTypes.map(({ type, IconComponent }) => (
						<Button
							key={type}
							onClick={() => onViewChange(type)}
							variant={selectedType == type ? 'primary' : 'white'}
						>
							<IconComponent />
						</Button>
					))}
				</ButtonGroup>
			</Col>
		</Row>
	);
}

AssessmentHeader.propTypes = {
	viewTypes: PropTypes.array.isRequired
};

AssessmentHeader.defaultProps = {
	viewTypes: [
		{
			type: 'grid',
			IconComponent: RiGridFill
		},
		{
			type: 'table',
			IconComponent: MdOutlineList
		}
	]
};
