import Flex from 'components/common/Flex';
import JSONEditor from 'components/common/JsonEditor';
import TagBox from 'components/common/TagBox';
import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Switch from 'react-switch';
import PreviewTechniqueConfig from './PreviewTechniqueConfig';
import { toast } from 'react-toastify';
import TypeFormSelect from '../templates/TemplateTypeFormSelect';
import { IntegrationContext } from 'context/Context';
import { getIntegrationService } from 'services/integrationService';
import useError from 'hooks/useError';
export default function TechniqueCreateEditComponent({
	title,
	submitButtonText,
	onSubmit,
	data,
	handleFieldChange,
	isFetching = false,
	tags,
	handleTags,
	getInstructionOfIntegration = () => {
		return {};
	}
}) {
	const { getResponse } = useError();
	const { integrationState, saveAllIntegrations } =
		useContext(IntegrationContext);
	const [showPreview, setShowPreview] = useState(false);
	const [integrationOptions, setIntegrationOptions] = useState(
		integrationState?.all?.map(int => ({
			value: int.slug,
			label: int.name
		}))
	);
	const handleOnJsonChange = (id, value) => {
		handleFieldChange({ target: { id, value } });
	};

	const updateQueryFieldValue = value =>
		handleOnJsonChange('extraConfig', {
			...data.extraConfig,
			query: value
		});

	/**
	 * updating the query value based on selected integration
	 */
	const handleIntegrationSelection = event => {
		const value = event.target.value;
		const instructionOfValue = getInstructionOfIntegration(value);
		updateQueryFieldValue(instructionOfValue);
	};

	const handleShowPreview = () => {
		let formSchema = data?.extraConfig.formSchema;
		if (formSchema) {
			setShowPreview(true);
		} else {
			toast(
				<span className="text-danger">
					Please add form schema to see preview.
				</span>
			);
		}
	};
	const handlePreviewClose = () => setShowPreview(false);

	const getIntegrations = async () => {
		const res = await getIntegrationService();
		getResponse(res)
			.then(res => {
				saveAllIntegrations(res?.data ?? []);
				setIntegrationOptions(
					(res?.data ?? [])?.map(int => ({
						value: int.slug,
						label: int.name
					}))
				);
			})
			.catch(err => console.error(err));
	};

	useEffect(() => {
		if (integrationState?.all?.length === 0) {
			getIntegrations();
		}
	}, []);

	return (
		<React.Fragment>
			<div className="position-relative">
				<h5 className="mb-3">{title}</h5>
				<div>
					<div className="mb-3">
						<Form.Label>Technique Name*</Form.Label>
						<Form.Control
							id="name"
							type="text"
							className="text-700"
							placeholder="e.g. Linear"
							value={data?.name}
							onChange={handleFieldChange}
						/>
					</div>
					<div className="mb-3">
						<Form.Label>Technique Description*</Form.Label>
						<Form.Control
							id="description"
							as="textarea"
							rows={3}
							className="text-700"
							placeholder="e.g. Linear"
							value={data?.description}
							onChange={handleFieldChange}
						/>
					</div>
					{/* author*/}
					<div className="mb-3">
						<Form.Label>Author (optional)</Form.Label>
						<Form.Control
							id="author"
							type="text"
							className="text-700"
							placeholder="e.g. Linear"
							value={data?.author}
							onChange={handleFieldChange}
						/>
					</div>

					{/* <TypeFormSelect
						title={'Technique Type*'}
						selectedType={data?.type}
						onViewChange={handleFieldChange}
					/> */}

					<div className="mb-3">
						<Flex className={'align-items-center'}>
							<Form.Label className="me-3">Publish</Form.Label>
							<div>
								<Switch
									checked={data?.status != 'draft'}
									onChange={() =>
										handleFieldChange({
											target: {
												id: 'status',
												value:
													data?.status != 'draft'
														? 'draft'
														: 'active'
											}
										})
									}
									onColor="#86d3ff"
									onHandleColor="#2693e6"
									handleDiameter={24}
									uncheckedIcon={false}
									checkedIcon={false}
									boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
									activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
									height={16}
									width={40}
									className="react-switch"
									id="material-switch"
								/>
							</div>
						</Flex>
					</div>
					<div className="mb-3">
						<Form.Label>
							Mitigation Recommendations (optional)
						</Form.Label>
						<Form.Control
							id="mitigation"
							as="textarea"
							rows={3}
							className="text-700"
							placeholder="e.g. Linear"
							value={data?.mitigation}
							onChange={handleFieldChange}
						/>
					</div>

					<Row className="my-3">
						<Col>
							<Form.Label className="text-black">
								Master Config
							</Form.Label>
							<JSONEditor
								key={data.masterConfig}
								json={data.masterConfig}
								handleChange={value =>
									handleOnJsonChange('masterConfig', value)
								}
							/>
						</Col>
						<Col>
							<Form.Label className="text-black">
								FormSchema
							</Form.Label>
							<JSONEditor
								key={data.extraConfig}
								json={data.extraConfig?.formSchema || {}}
								handleChange={value =>
									handleOnJsonChange('extraConfig', {
										...data.extraConfig,
										formSchema: value
									})
								}
							/>
							<Button
								variant="primary"
								size="sm"
								className="mt-2 py-2 px-4"
								onClick={handleShowPreview}
							>
								Preview
							</Button>
						</Col>
						<Col sm={12}>
							<Form.Label className="text-black">
								Query
							</Form.Label>
							<p>
								Currently available integrations:
								elastic-security, splunk
							</p>
							<Flex alignItems={'center'} className="mb-2">
								<div className="me-2">
									<Form.Label>Select Integration</Form.Label>
								</div>
								<div>
									<Form.Select
										id="name"
										// value={data.name}
										onChange={handleIntegrationSelection}
										className="text-700"
									>
										<option className="text-700">
											Select...
										</option>
										{integrationOptions?.map(op => (
											<option
												className="text-700"
												value={op.value}
											>
												{op.label}
											</option>
										))}
									</Form.Select>
								</div>
							</Flex>
							<JSONEditor
								key={data.extraConfig}
								json={data.extraConfig?.query || {}}
								handleChange={updateQueryFieldValue}
							/>
						</Col>
					</Row>
				</div>
				<div className="my-3">
					<Form.Label>Tags (Optional)</Form.Label>
					<TagBox
						labelClass="text-900 fw-bold"
						label={'Tags'}
						id="tech_tag_box"
						tags={tags}
						updateTags={handleTags}
						key={tags}
					/>
				</div>
				<div>
					<Button
						variant="primary"
						className="py-2 px-4"
						onClick={onSubmit}
					>
						{submitButtonText}
					</Button>
				</div>
				{!isFetching ? null : (
					<div className="position-absolute top-50 start-50 translate-middle shadow-lg">
						<div className="bg-white p-4 rounded">Loading...</div>
					</div>
				)}
			</div>
			{showPreview && (
				<PreviewTechniqueConfig
					show={showPreview}
					handleClose={handlePreviewClose}
					formSchema={data?.extraConfig.formSchema}
				/>
			)}
		</React.Fragment>
	);
}
