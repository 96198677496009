import React from 'react';
import AssessmentSetupKillChain from '../killchains/KillChain';
import TemplateTest from '../test/Test';
import ShowSelectedStep from './ShowSelectedStep';
import { Form } from 'react-bootstrap';

function TemplateTypeBasedOperation({ type }) {
	if (type === 'test') {
		return (
			<React.Fragment>
				<div>
					<div className="mb-3">
						<Form.Label>Steps</Form.Label>
						<TemplateTest />
					</div>
				</div>
			</React.Fragment>
		);
	}
	return (
		<React.Fragment>
			<div>
				<div className="mb-3">
					<Form.Label>Steps</Form.Label>
					<AssessmentSetupKillChain />
					<ShowSelectedStep />
				</div>
			</div>
		</React.Fragment>
	);
}

export default TemplateTypeBasedOperation;
