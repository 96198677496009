import React from 'react';
import { Card } from 'react-bootstrap';

export default function VerticalAnalysisCard({ title, data, imgUrl, ...rest }) {
  return (
    <Card className="p-3  justify-content-between" {...rest}>
      <h6>{title}</h6>
      <div className="fw-bold text-black fs-2">{data}</div>
      <div style={{ height: 36 }}>
        <img
          src={imgUrl}
          style={{ width: 'auto%', maxHeight: '100%', float: 'right' }}
        />
      </div>
    </Card>
  );
}
