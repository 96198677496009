import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { StepContext } from 'context/Context';
import { stepReducer } from 'reducers/stepReducer';

const LABEL_DATA = {
	success: { label: 'SUCCESS', className: 'success' },
	failure: { label: 'FAILURE', className: 'danger' }
};

const TOGGLE_LABEL_DATA = {
	failure: {
		label: 'SUCCESS',
		className: 'success',
		key: 'success'
	},
	success: {
		label: 'FAILURE',
		className: 'danger',
		key: 'failure'
	}
};

const StepProvider = ({ children }) => {
	const initData = {
		nodes: [],
		edges: [],
		techniques: [],
		scenarioType: null,
		selectedStep: null,
		showStepData: false,
		selectedDeleteStep: null
	};
	const [stepState, dispatch] = useReducer(stepReducer, initData);

	useEffect(() => {
		console.log(stepState, 'dsdfds');
	}, [stepState]);

	const updateScenarioType = type => {
		dispatch({
			type: 'UPDATE_SCENARIO_TYPE',
			payload: type
		});
	};

	const addNodesToStep = newNodes => {
		dispatch({ type: 'ADD_NODES_TO_STEP', payload: newNodes });
	};

	const addEdgesToStep = newEdges => {
		dispatch({ type: 'ADD_EDGES_TO_STEP', payload: newEdges });
	};

	const addHandleToStartNode = handleData => {
		dispatch({ type: 'ADD_HANDLE_TO_OUTPUT', payload: handleData });
	};

	const addHandleToEndNode = handleData => {
		dispatch({ type: 'ADD_HANDLE_TO_INPUT', payload: handleData });
	};

	const updateEdge = params => {
		console.log(params.target, stepState.nodes, 'test', params);
		let handleLabel = { 0: 'a', 1: 'b', 2: 'c' };
		let targetNode = stepState.nodes.find(n => n.id === params.target);
		let targetNodeOutLen = targetNode.data.output.length;
		let sourceNode = stepState.nodes.find(n => n.id === params.source);
		let sourceNodeInLen = sourceNode.data.inputs.length;

		let edgeParams = {
			source: params.target,
			target: params.source,
			sourceHandle: params.target + 'o' + handleLabel[targetNodeOutLen],
			targetHandle: params.source + 'i' + handleLabel[sourceNodeInLen]
		};

		let DOT_POS = {
			0: {
				left: { top: 20, bottom: 'auto' },
				right: { top: 20, bottom: 'auto' }
			},
			1: {
				left: { bottom: 20, top: 'auto' },
				right: { bottom: 20, top: 'auto' }
			},
			2: {
				left: { bottom: 40, top: 'auto' }
			}
		};

		const startHandleData = {
			nodeId: params.target,
			handleId: edgeParams.sourceHandle,
			position: 'right',
			dotPositon: DOT_POS[targetNodeOutLen]['right']
		};

		const endHandleData = {
			nodeId: params.source,
			handleId: edgeParams.targetHandle,
			position: 'left',
			dotPositon: DOT_POS[sourceNodeInLen]['left']
		};

		addHandleToStartNode(startHandleData);
		addHandleToEndNode(endHandleData);

		let labelKey;
		switch (targetNode.data.output.length) {
			case 0:
				labelKey = 'success';
				break;
			case 1:
				labelKey = 'failure';
				break;
			default:
				break;
		}
		// let edgeId = //reactflow__edge-2sourceConnectLeftLine-1targetConnectRightLine
		//   'reactflow__edge-' +
		//   edgeParams.source +
		//   edgeParams.sourceHandle +
		//   '-' +
		//   edgeParams.target +
		//   edgeParams.targetHandle;
		dispatch({
			type: 'UPDATE_EDGE',
			payload: {
				// id: edgeId,
				// ...edgeParams,
				...params,
				animated: true,
				type: 'smoothstep',
				label: LABEL_DATA[labelKey].label,
				labelKey: labelKey,
				className: LABEL_DATA[labelKey].className
			}
		});
	};

	const addEdgeToStore = edge => {
		dispatch({ type: 'ADD_EDGE', payload: edge });
	};

	const showStepData = id => {
		dispatch({ type: 'SHOW_STEP', payload: id });
	};

	const updateNode = keyValue => {
		dispatch({ type: 'UPDATE_NODE', payload: keyValue });
	};

	const toggleEdgeLabel = id => {
		let edges = stepState.edges.slice();
		let edgeIndex = edges.findIndex(e => e.id === id);
		let currentEdge = edges[edgeIndex];
		// check how much edge from target
		let currentEdgeTargetEdges = edges.filter(
			e => e.source === currentEdge.source
		);
		// 1 then change
		// 2 then toggle
		switch (currentEdgeTargetEdges.length) {
			case 1:
				edges[edgeIndex] = {
					...currentEdge,
					labelKey: TOGGLE_LABEL_DATA[currentEdge.labelKey].key,
					label: TOGGLE_LABEL_DATA[currentEdge.labelKey].label,
					className: TOGGLE_LABEL_DATA[currentEdge.labelKey].className
				};
				break;
			case 2:
				currentEdgeTargetEdges.forEach(ed => {
					let edgeIndex = edges.findIndex(e => e.id === ed.id);
					edges[edgeIndex] = {
						...ed,
						labelKey: TOGGLE_LABEL_DATA[ed.labelKey].key,
						label: TOGGLE_LABEL_DATA[ed.labelKey].label,
						className: TOGGLE_LABEL_DATA[ed.labelKey].className
					};
				});
				break;
			default:
				break;
		}
		dispatch({ type: 'TOGGLE_LABEL', payload: edges });
	};

	const updateNodePosition = data => {
		dispatch({ type: 'UPDATE_NODE_POSITION', payload: data });
	};

	const resetStep = () => dispatch({ type: 'RESET_STEP', payload: initData });
	const updateDeleteStep = id => {
		dispatch({ type: 'UPDATE_SELECTED_DELETE_STEP', payload: id });
	};
	const handleDeleteNode = id => {
		let remainingNodes = stepState.nodes.filter(n => n.id != id);
		let remainingEdges = stepState.edges.filter(
			e => e.source != id || e.target != id
		);
		dispatch({
			type: 'DELETE_NODE',
			payload: { nodes: remainingNodes, edges: remainingEdges }
		});
	};
	const updateConnectionOfEdge = (oldEdge, newConnection) => {
		dispatch({
			type: 'UPDATE_CONNECTION_OF_EDGE',
			payload: { ...oldEdge, ...newConnection }
		});
	};
	const removeEdge = id => {
		dispatch({ type: 'REMOVE_EDGE', payload: id });
	};
	return (
		<StepContext.Provider
			value={{
				stepState,
				updateScenarioType,
				addNodesToStep,
				addHandleToStartNode,
				addHandleToEndNode,
				updateEdge,
				addEdgeToStore,
				showStepData,
				updateNode,
				toggleEdgeLabel,
				updateNodePosition,
				resetStep,
				addEdgesToStep,
				updateDeleteStep,
				handleDeleteNode,
				updateConnectionOfEdge,
				removeEdge
			}}
		>
			{children}
		</StepContext.Provider>
	);
};

StepProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export default StepProvider;
