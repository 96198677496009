import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { TechniqueContext } from 'context/Context';
import { techniqueReducer } from 'reducers/techniqueReducer';

const TechniqueProvider = ({ children }) => {
	const initData = {
		techniques: null,
		filters: {
			search: '',
			selectedEnv: '',
			tags: []
		}
	};
	const [techniqueState, dispatch] = useReducer(techniqueReducer, initData);

	const saveAllTechniques = data => {
		dispatch({
			type: 'SAVE_ALL_TECH_DATA',
			payload: data
		});
	};

	const resetTechnique = () => {
		dispatch({
			type: 'SAVE_ALL_TECH_DATA',
			payload: null
		});
	};

	const updateTechniqueFilters = (text, os, tags) => {
		dispatch({
			type: 'SAVE_TECH_FILTERS',
			payload: { search: text, selectedEnv: os, tags: tags }
		});
	};

	return (
		<TechniqueContext.Provider
			value={{
				techniqueState,
				saveAllTechniques,
				resetTechnique,
				updateTechniqueFilters
			}}
		>
			{children}
		</TechniqueContext.Provider>
	);
};

TechniqueProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export default TechniqueProvider;
