import React, { useContext, useState } from 'react';
import AddEditStep from './AddEditStep';
import { AddStepContext, StepContext } from 'context/Context';
import {
	getNodePosition,
	getRefactoredTechniqueResponseForStep
} from 'helpers/utils';
import { v4 as uuidv4 } from 'uuid';
import { getMasterTemplateByName } from 'services/techniqueService';
import useError from 'hooks/useError';

export default function AddStep() {
	const [isCalling, setCalling] = useState(false);
	const { getResponse } = useError();
	const {
		addStepState: { showModal, selectedScenarios },
		toggleModal,
		resetAddStep
	} = useContext(AddStepContext);
	const {
		stepState: { nodes },
		addNodesToStep
	} = useContext(StepContext);

	const handleAddSteps = async () => {
		setCalling(true);
		let newNodes = [];
		for (const scn of selectedScenarios) {
			const masterConfigRes = await getMasterTemplateByName(scn.name);

			await getResponse(masterConfigRes)
				.then(res => {
					const {
						createdAt,
						deletedAt,
						updatedAt,
						...masterResponse
					} = getRefactoredTechniqueResponseForStep(masterConfigRes);
					let newObject = {
						id: uuidv4(),
						type: 'custom',
						data: {
							number: nodes.length + newNodes.length + 1,
							inputs: [],
							output: [],
							name: scn.name ?? scn?.techniqueName,
							description:
								scn.description ?? scn?.techniqueDescription,
							action: 'play',
							detailStatus: 'noaction',
							status: 'noaction',
							techniqueId: scn.id,
							tags:
								scn?.Taggable?.map(each => each?.tag).map(
									tag => tag?.name
								) || [],
							extraConfig: {
								formSchema: scn?.formSchema
							},
							masterConfig: masterResponse
						},
						position: getNodePosition([...nodes, ...newNodes])
					};
					if (nodes.length == 0) {
						newObject.sourcePosition = 'right';
					}
					newNodes.push(newObject);
				})
				.catch(err => {
					console.log(err, 'error while adding step');
					setCalling(false);
				});
		}
		addNodesToStep(newNodes);
		resetAddStep();
		setCalling(false);
	};

	return (
		<React.Fragment>
			<AddEditStep
				title="Add Steps"
				show={showModal}
				handleClose={toggleModal}
				onSave={handleAddSteps}
				isSaveLoading={isCalling}
			/>
		</React.Fragment>
	);
}
