import React, { useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { AddStepContext, StepContext } from 'context/Context';
import { addStepReducer } from 'reducers/addStepReducer';

const AddStepProvider = ({ children }) => {
	const { updateScenarioType } = useContext(StepContext);
	const initData = {
		selectedScenarios: [],
		stepData: null,
		showModal: false
	};
	const [addStepState, dispatch] = useReducer(addStepReducer, initData);

	const toggleModal = () => {
		if (!addStepState.showModal) {
			updateScenarioType('add');
		} else {
			updateScenarioType(null);
		}
		dispatch({
			type: 'TOGGLE_ADD_STEP_MODAL'
		});
	};
	const updateScenarios = tech => {
		let newScenarios = addStepState.selectedScenarios.slice();
		const newScenariosIds = newScenarios.map(sc => sc?.id);
		const techId = tech?.id;
		if (newScenariosIds.includes(techId)) {
			newScenarios = newScenarios.filter(sc => sc?.id !== techId);
		} else {
			newScenarios.push(tech);
		}
		dispatch({
			type: 'UPDATE_SCENARIOS',
			payload: newScenarios
		});
	};
	const resetAddStep = () => {
		dispatch({ type: 'RESET', payload: initData });
	};
	return (
		<AddStepContext.Provider
			value={{
				addStepState,
				updateScenarios,
				toggleModal,
				resetAddStep
			}}
		>
			{children}
		</AddStepContext.Provider>
	);
};

AddStepProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export default AddStepProvider;
