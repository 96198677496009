import React, { useContext, useEffect, useState } from 'react';
import AddEditStep from './AddEditStep';
import { EditStepContext, StepContext } from 'context/Context';

export default function EditStep() {
	const {
		editStepState: { showModal },
		toggleModal
	} = useContext(EditStepContext);

	const handleEditStep = async () => {
		//     setCalling(true);
		// let node = nodes.find(n => n.id === selectedStep);
		// const data = { techniqueId: selectedScenario.id };
		// const res = await updateStepService(node.data.stepId, data);
		// if (res.err) {
		//   if (res.err.statusCode === 401) {
		//     toast.info('You login session ended.');
		//     navigate('/');
		//     return;
		//   }
		//   // show err message
		//   toast(
		//     <span className="text-danger">
		//       Something went wrong! Refresh & try again.
		//     </span>
		//   );
		//   setCalling(false);
		//   return;
		// }
		// let dataProps = {
		//   techniqueId: res.techniqueId,
		//   config: res.formSchema
		// };
		// updateNodeDataProps(selectedStep, dataProps);
		// resetEditStep();
		// setCalling(false);
	};

	return (
		<React.Fragment>
			<AddEditStep
				title="Change Scenario"
				show={showModal}
				handleClose={toggleModal}
				onSave={handleEditStep}
			/>
		</React.Fragment>
	);
}
