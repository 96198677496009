export const techniqueReducer = (state, action) => {
	const { type, payload } = action;
	switch (type) {
		case 'SAVE_ALL_TECH_DATA':
			return {
				...state,
				techniques: payload
			};
		case 'SAVE_TECH_FILTERS':
			return {
				...state,
				filters: payload
			};
		default:
			return state;
	}
};
