import { generateQueryString, handleError } from 'helpers/utils';
import axios from './axiosInstance';

export const getAllKillChainService = async (organizationId, page = 1) => {
	let params = { organizationId, page };
	const queryString = generateQueryString(params);
	let query = '';
	if (queryString) {
		query += `?${queryString}`;
	}
	try {
		const resp = await axios.get('/killchain' + query);
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};

export const getKillChainByIdService = async id => {
	try {
		const resp = await axios.get('/killchain/' + id);
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};
