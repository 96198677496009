import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import usePagination from 'hooks/usePagination';
import { getAllKillChainService } from 'services/killchainService';
import AssessmentTable from './orgs/AssessmentTable';
import useError from 'hooks/useError';

export default function Assessments({ orgId }) {
	const { getResponse } = useError();
	const [isFetching, setFetching] = useState(false); // initial fetching state = false
	const [assessmentsRes, setAssessmentsRes] = useState(null);

	useEffect(() => {
		getAllAssessments(orgId, 1);
	}, []);

	const getAllAssessments = useCallback(async (orgId, page) => {
		setFetching(true);
		const res = await getAllKillChainService(orgId, page);
		getResponse(res)
			.then(res => {
				setAssessmentsRes(res);
				setFetching(false);
			})
			.catch(err => {
				setFetching(false);
			});
	}, []);

	const getAssessmentsByOrgIdWithPage = page => {
		getAllAssessments(orgId, page);
	};
	const { Pagination } = usePagination(getAssessmentsByOrgIdWithPage);

	const handleViewClick = assessment => {
		const newWindow = window.open(
			`/assessment/${assessment.id}`,
			'_blank',
			'noopener,noreferrer'
		);
		if (newWindow) newWindow.opener = null;
	};

	return (
		<React.Fragment>
			<Row className="flex-between-center">
				<Col md>
					<h5 className="mb-2 mb-md-0">Assessments</h5>
				</Col>
			</Row>
			<Card className="overflow-hidden my-4">
				<Card.Body className="p-0">
					<AssessmentTable
						data={assessmentsRes?.data || []}
						isFetching={isFetching}
						onViewClick={handleViewClick}
					/>
				</Card.Body>
			</Card>

			{assessmentsRes?.total_pages ? (
				<Pagination totalPages={assessmentsRes.total_pages} />
			) : null}
		</React.Fragment>
	);
}
