import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from 'context/Context';
import { authReducer } from 'reducers/authReducer';
import { useNavigate } from 'react-router-dom';
import { logoutService } from 'services/authService';

const AuthProvider = ({ children }) => {
	let navigate = useNavigate();
	const initData = {
		isLogedIn: false
	};
	const [authState, dispatch] = useReducer(authReducer, initData);

	useEffect(() => {
		const authStateString = JSON.stringify(authState);
		localStorage.setItem('authState', authStateString);
	}, [authState]);

	const onLoginSuccess = () => {
		dispatch({
			type: 'LOGIN_SUCCESS'
		});
	};

	const onAuthError = () => {
		dispatch({
			type: 'RESET_DATA'
		});
	};

	const logout = async () => {
		const res = await logoutService();
		onAuthError();
		localStorage.removeItem('authState');
		navigate('/');
	};

	return (
		<AuthContext.Provider
			value={{
				...authState,
				logout,
				onAuthError,
				onLoginSuccess
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

AuthProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export default AuthProvider;
