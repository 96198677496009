import React from 'react';
import { Col, Row, Form, Card } from 'react-bootstrap';
import VerticalAnalysisCard from 'components/common/VerticalAnalysisCard';

import activeUsersIcon from 'assets/img/icons/active_users.png';
import failRateIcon from 'assets/img/icons/fail_rate.png';
import vulnerabilityIcon from 'assets/img/icons/vulnerabilitiesFound.png';
import projectIcon from 'assets/img/icons/projects.png';
import successRateIcon from 'assets/img/icons/scanPerformed.png';
import scanTimeIcon from 'assets/img/icons/scan_time.png';
import CategoryTasks from './CategoryTasks';
import ToolQuata from './ToolQuata';
import TaskSummaryLine from './TaskSummaryLine';
import FileDownload from './FileDownload';
import Tools from './Tools';

const IncidentResponseDashboard = () => {
  return (
    <>
      <Row className="mb-4">
        <Col md={5}>
          <Row>
            <Col>
              <div>Time Frame:</div>
            </Col>
            <Col>
              <Form.Select
                id="type"
                aria-label="Default select example"
                className="text-700"
                // onChange={handleChange}
                // defaultValue={dashboardLayout}
              >
                <option className="text-700" value={'penetration_testing'}>
                  All Time
                </option>
                <option className="text-700" value="incident_response">
                  Text File
                </option>
              </Form.Select>
            </Col>
          </Row>
          <Row className=" mt-2 mb-3">
            <Col md={4} className="pe-1 mt-2">
              <VerticalAnalysisCard
                style={{ height: 152 }}
                title={'Active Users'}
                data={27}
                imgUrl={activeUsersIcon}
              />
            </Col>
            <Col md={4} className="px-1 mt-2">
              <VerticalAnalysisCard
                title={'Vulnerabilities found'}
                data={3298}
                imgUrl={vulnerabilityIcon}
                style={{ height: 152 }}
              />
            </Col>
            <Col md={4} className="ps-1 mt-2">
              <VerticalAnalysisCard
                title={'Av. Scan Time / Task'}
                data={'2m3s'}
                style={{ height: 152 }}
                imgUrl={scanTimeIcon}
              />
            </Col>
            <Col md={4} className="pe-1 mt-2">
              <VerticalAnalysisCard
                style={{ height: 152 }}
                title={'Success Rate'}
                data={'64%'}
                imgUrl={successRateIcon}
              />
            </Col>
            <Col md={4} className="px-1 mt-2">
              <VerticalAnalysisCard
                style={{ height: 152 }}
                title={'Fail Rate'}
                data={'36%'}
                imgUrl={failRateIcon}
              />
            </Col>
            <Col md={4} className="ps-1 mt-2">
              <VerticalAnalysisCard
                style={{ height: 152 }}
                title={'Projects'}
                data={34}
                imgUrl={projectIcon}
              />
            </Col>
          </Row>
        </Col>
        <Col md={3} className="px-0">
          <CategoryTasks />
        </Col>
        <Col md={4}>
          <ToolQuata />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={8}>
          <TaskSummaryLine />
        </Col>
        <Col md={4}>
          <FileDownload />
        </Col>
      </Row>
      <Tools />
    </>
  );
};

export default IncidentResponseDashboard;
