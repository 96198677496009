import React from 'react';
import ContentLoader from 'react-content-loader';

export default function KillchainLoader() {
	return (
		<ContentLoader
			width={'100%'}
			height={400}
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb"
			className="bg-white"
		>
			<rect x="30%" y="50" rx="8" ry="8" width="20%" height="130" />
			<rect x="55%" y="200" rx="8" ry="8" width="20%" height="130" />
		</ContentLoader>
	);
}
