import React from 'react';
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

function TypeFormSelect({ title, selectedType, viewTypes, onViewChange }) {
	return (
		<React.Fragment>
			<div className="mb-3">
				<div>
					<Form.Label>{title}</Form.Label>
				</div>
				<ButtonGroup className="bg-white">
					{viewTypes.map(({ type, label }) => (
						<Button
							key={type}
							onClick={() =>
								onViewChange({
									target: { id: 'type', value: type }
								})
							}
							variant={selectedType == type ? 'primary' : 'white'}
						>
							{label}
						</Button>
					))}
				</ButtonGroup>
			</div>
		</React.Fragment>
	);
}

export default TypeFormSelect;

TypeFormSelect.propTypes = {
	viewTypes: PropTypes.array.isRequired
};

TypeFormSelect.defaultProps = {
	viewTypes: [
		{
			type: 'killchain',
			label: 'Killchain'
		},
		{
			type: 'test',
			label: 'Test'
		}
	]
};
