import React from 'react';
import TaskSummary from './TaskSummary';
import RunningTaskStatusCart from './RunningTaskStatus';
import totalScanIcon from 'assets/img/icons/totalScan.png';
import scanPerformedIcon from 'assets/img/icons/scanPerformed.png';
import projectIcon from 'assets/img/icons/projects.png';
import QuickAnalysisCard from 'components/common/QuickAnalysisCard';
import { Row, Col, Form } from 'react-bootstrap';
import vulnerabilitiesFoundIcon from 'assets/img/icons/vulnerabilitiesFound.png';
import Tools from './Tools';

export const topProducts = {
  week: {
    cates: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    data: [5100, 7500, 18000, 6000, 41000, 21000, 3500]
  }
};
export default function PenetrationTestingDashboard() {
  return (
    <>
      <Row>
        <Col lg={3}>
          <QuickAnalysisCard
            imgUrl={totalScanIcon}
            title={'Total Scans'}
            count={250}
          />
        </Col>
        <Col lg={3}>
          <QuickAnalysisCard
            imgUrl={scanPerformedIcon}
            title={'Scans performed'}
            count={104}
          />
        </Col>
        <Col lg={3}>
          <QuickAnalysisCard
            imgUrl={projectIcon}
            title={'Projects'}
            count={24}
          />
        </Col>
        <Col lg={3}>
          <QuickAnalysisCard
            imgUrl={vulnerabilitiesFoundIcon}
            title={'Vulnerabilities found'}
            count={12174}
          />
        </Col>
      </Row>
      <Row className="my-4">
        <Col md={6}>
          <RunningTaskStatusCart />
        </Col>
        <Col md={6}>
          <TaskSummary data={topProducts} />
        </Col>
      </Row>
      <Tools />
    </>
  );
}
