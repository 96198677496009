import { formatDate } from 'helpers/utils';
import useError from 'hooks/useError';
import usePagination from 'hooks/usePagination';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getNotificationsService } from 'services/authService';

export default function Notifications() {
	const [isFetching, setFetching] = useState(false);
	const { getResponse } = useError();
	const [notifications, setNotifications] = useState(null);

	const getNotifications = useCallback(async page => {
		setFetching(true);
		const res = await getNotificationsService(page);
		getResponse(res)
			.then(res => {
				setNotifications(res);
				setFetching(false);
			})
			.catch(err => {
				setFetching(false);
			});
	}, []);

	const { Pagination } = usePagination(getNotifications);

	useEffect(() => {
		getNotifications(1);
	}, []);

	if (isFetching) {
		// TODO: later add loader/
		return null;
	}

	return (
		<React.Fragment>
			<Row className="flex-between-center">
				<Col md>
					<h5 className="mb-2 mb-md-0">Notifications</h5>
				</Col>
			</Row>
			<div className="p-3">
				<Row className="bg-white py-3 pb-4">
					{notifications?.data?.map(each => (
						<Col sm={12}>
							<Row className="border rounded p-2 mt-3 mx-0 bg-200">
								<Col sm={8} className="fw-medium fs--1">
									{each.message}
								</Col>
								<Col sm={1}></Col>
								<Col
									sm={3}
									className="text-end fs--1 fw-medium"
								>
									{formatDate(each.createdAt)}
								</Col>
							</Row>
						</Col>
					))}
					{notifications?.data?.length == 0 ? (
						<Col>
							<div>No Notifications yet.</div>
						</Col>
					) : null}
				</Row>
			</div>
			{notifications?.total_pages ? (
				<Pagination totalPages={notifications?.total_pages} />
			) : null}
		</React.Fragment>
	);
}
