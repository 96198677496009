export const agentReducer = (state, action) => {
	const { type, payload } = action;
	switch (type) {
		case 'SAVE_ALL_AGENT_DATA':
			return {
				...state,
				agents: payload
			};
		case 'SAVE_AGENT_DATA':
			return {
				...state,
				agents: [...state.agents, payload]
			};
		case 'ADD_SELECT_AGENTS':
			return {
				...state,
				selectedAgents: payload
			};
		case 'RESET_SELECTED_AGENTS':
			return {
				...state,
				selectedAgents: payload
			};
		default:
			return state;
	}
};
