import React from 'react';
import CardHeader from 'components/common/CardHeader';
import { runningTaskStatus } from 'data/dashboard';
import { Card, Col, ProgressBar, Row } from 'react-bootstrap';
import classNames from 'classnames';

const RunningTaskStatusCartItem = ({ item, index }) => {
  const { id, title, completion_percentile, project_id, createdAt } = item;
  return (
    <Row className={classNames('g-0 align-items-center pb-3')}>
      <Col className="pt-3">
        <Row className="mb-1">
          <Col md={8}>
            <h6 className="fs--1 text-900">{title}</h6>
          </Col>
          <Col md={4} className="text-end">
            <span className="fs--1">Project: </span>
            <span className="fs--1 text-primary">{project_id}</span>
          </Col>
        </Row>

        <ProgressBar className="mt-xxl-auto" style={{ height: 6 }}>
          <ProgressBar
            variant={'primary'}
            now={completion_percentile}
            key={id}
            className="rounded-3"
          />
        </ProgressBar>
        <Row>
          <Col md={8}>
            <span className="fs--1">Run On: </span>
            <span className="fs--1 text-black">{createdAt}</span>
          </Col>
          <Col md={4} className="text-end">
            <span className="fs--1">Completed: </span>
            <span className="fs--1 text-black">{completion_percentile}%</span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const RunningTaskStatusCart = () => {
  return (
    <Card className="h-100">
      <CardHeader
        title="Running Tasks Status"
        titleTag="h5"
        className="py-3 border-bottom"
        titleClass="text-black"
      />
      <Card.Body
        className="py-0 d-flex flex-column scrollbar"
        style={{ height: '24rem' }}
      >
        {runningTaskStatus.map((item, index) => (
          <RunningTaskStatusCartItem item={item} key={item.id} index={index} />
        ))}
      </Card.Body>
    </Card>
  );
};

export default RunningTaskStatusCart;
