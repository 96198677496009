import CardHeader from 'components/common/CardHeader';
import React from 'react';
import { Card, Form } from 'react-bootstrap';
import TaskSummaryLineChart from './TaskSummaryLineChart';

export default function TaskSummaryLine() {
  return (
    <Card className="">
      <CardHeader
        title="Task Summary"
        titleTag="h5"
        className="py-3 border-bottom"
        titleClass="text-black"
        endEl={
          <Form.Select className="text-black fw-medium" size="sm">
            <option>Last 7 days</option>
            <option>Last Month</option>
            <option>Last Year</option>
          </Form.Select>
        }
      />
      <Card.Body>
        <TaskSummaryLineChart data={[]} />
      </Card.Body>
    </Card>
  );
}
