import CardHeader from 'components/common/CardHeader';
import Flex from 'components/common/Flex';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  DatasetComponent,
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Form } from 'react-bootstrap';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  DatasetComponent
]);

const getOption = data => ({
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: function (params) {
      return `<div class="text-center"><div class="fs--1 text-600">
      <strong>${params.value}</strong></div>
      <small class="text-500">Tasks</small>
      </div>`;
    }
  },
  xAxis: {
    type: 'category',
    data: data.week.cates,
    axisLabel: { color: getColor('gray-400') },
    axisLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    axisTick: false,
    boundaryGap: true
  },
  yAxis: {
    axisPointer: { type: 'none' },
    axisTick: 'none',
    splitLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    axisLine: { show: false },
    axisLabel: { color: getColor('gray-400') },
    min: 0
  },
  series: [
    {
      type: 'bar',
      data: data.week.data,
      barWidth: '10px',
      barGap: '30%',
      label: { show: false },
      z: 10,
      itemStyle: {
        borderRadius: [10, 10, 0, 0],
        color: getColor('primary')
      }
    }
  ],
  grid: { right: '0', left: '60px', bottom: '10%', top: '12%' }
});

const TaskSummary = ({ data }) => {
  return (
    <Card className="">
      <CardHeader
        title="Task Summary"
        titleTag="h5"
        className="py-3 border-bottom"
        titleClass="text-black"
        endEl={
          <Form.Select className="text-black fw-medium" size="sm">
            <option>Last 7 days</option>
            <option>Last Month</option>
            <option>Last Year</option>
          </Form.Select>
        }
      />
      <Flex className={'justify-content-between mt-3 mx-4'}>
        <div className="text-start">
          <div className="text-black fw-semi-bold fs-3">61500</div>
          <div className="text-700 fs--1">Tasks</div>
        </div>
        <div className="text-end">
          <div className="text-black fw-semi-bold fs-3">1050</div>
          <div className="text-700 fs--1">Projects</div>
        </div>
      </Flex>
      <Card.Body className=" pt-0">
        <ReactEChartsCore
          echarts={echarts}
          option={getOption(data)}
          style={{ height: '100%', minHeight: '17.75rem', width: 'inherit' }}
        />
      </Card.Body>
    </Card>
  );
};

TaskSummary.propTypes = {
  data: PropTypes.array.isRequired
};

export default TaskSummary;
