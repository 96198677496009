import React from 'react';
import ContentLoader from 'react-content-loader';

export default function GridLoader() {
	return (
		<ContentLoader
			width={'100%'}
			height={200}
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb"
			// className="bg-white"
		>
			<rect x="1%" y="20" rx="3" ry="3" width="24%" height="160" />
			<rect x="26%" y="20" rx="3" ry="3" width="24%" height="160" />
			<rect x="51%" y="20" rx="3" ry="3" width="24%" height="160" />
			<rect x="76%" y="20" rx="3" ry="3" width="24%" height="160" />
		</ContentLoader>
	);
}
