import { OrgContext } from 'context/Context';
import usePagination from 'hooks/usePagination';
import React, { useCallback, useContext, useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getAllOrgsService } from 'services/orgService';
import OrgListTable from './orgs/OrgListTable';
import useError from 'hooks/useError';

export default function Orgs() {
	const { getResponse } = useError();
	const getAllOrgs = useCallback(async page => {
		const res = await getAllOrgsService(page);
		getResponse(res)
			.then(res => {
				saveAllOrgs(res);
			})
			.catch(err => console.error(err));
	}, []);
	const navigate = useNavigate();
	const { orgState, saveAllOrgs } = useContext(OrgContext);
	const { Pagination } = usePagination(getAllOrgs);
	useEffect(() => {
		getAllOrgs(1);
	}, []);
	return (
		<React.Fragment>
			<Row className="gx-3">
				<Col>
					<Row className="flex-between-center">
						<Col md>
							<h5 className="mb-2 mb-md-0">Organisation List</h5>
						</Col>
						<Col xs="auto">
							<Button
								variant="primary"
								className="me-2 mb-1"
								onClick={() => navigate('/orgs/new')}
							>
								Add New Organisation
							</Button>
						</Col>
					</Row>

					<Card className="overflow-hidden my-4">
						<Card.Body className="p-0">
							<OrgListTable data={orgState.orgs?.data || []} />
						</Card.Body>
					</Card>
				</Col>
			</Row>
			{orgState.orgs?.total_pages ? (
				<Pagination totalPages={orgState.orgs.total_pages} />
			) : null}
		</React.Fragment>
	);
}
