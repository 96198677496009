import { TechniqueContext } from 'context/Context';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getAllTechniquesService } from 'services/techniqueService';
import useError from './useError';

export default function useTechnique() {
	const [isFetching, setFetching] = useState(false); // initial fetching state = false
	const { techniqueState, saveAllTechniques } = useContext(TechniqueContext);
	const { getResponse } = useError();

	// unmoutning handler
	useEffect(() => {
		return () => {
			setFetching(false);
		};
	}, []);

	useEffect(() => {
		getAllTechniques(1);
	}, [techniqueState.filters]);

	const getAllTechniques = useCallback(
		async page => {
			setFetching(true);
			const search = techniqueState?.filters?.search;
			const os = techniqueState?.filters?.selectedEnv;
			const tags = techniqueState?.filters?.tags;
			const res = await getAllTechniquesService(page, search, os, tags);

			getResponse(res)
				.then(res => {
					saveAllTechniques(res);
					setFetching(false);
				})
				.catch(err => {
					setFetching(false);
				});
		},
		[techniqueState.filters]
	);

	return {
		techniques: techniqueState.techniques,
		isFetching,
		getAllTechniques
	};
}
