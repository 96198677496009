import { useCallback, useEffect, useContext, Fragment, useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { TechniqueContext } from 'context/Context';
import { getDateInFormatOfDMY } from 'helpers/utils';
import { getAllTechniquesService } from 'services/techniqueService';
import useError from 'hooks/useError';
import usePagination from 'hooks/usePagination';
import TechniqueFilters from './techniques/TechniqueFilters';

export default function Techniques({ data = [] }) {
	// states
	const [page, setPage] = useState(1);
	const [isFetching, setFetching] = useState(false);
	const [search, setSearch] = useState('');
	const [debouncedText] = useDebounce(search, 1000);

	const { techniqueState, saveAllTechniques } = useContext(TechniqueContext);
	const { getResponse } = useError();

	const getAllTechniques = async (page, debouncedText) => {
		setFetching(true);
		const res = await getAllTechniquesService(page, debouncedText);
		getResponse(res)
			.then(res => {
				saveAllTechniques(res);
			})
			.catch(err => console.error(err))
			.finally(() => {
				setFetching(false);
			});
	};

	const { Pagination } = usePagination(setPage);

	useEffect(() => {
		getAllTechniques(page, debouncedText);
	}, [page, debouncedText]);

	return (
		<Fragment>
			<Row className="flex-between-center mb-3">
				<Col md>
					<h5 className="mb-2 mb-md-0">Techniques</h5>
				</Col>
				<Col xs="auto">
					<Button
						as={Link}
						variant="primary"
						className="me-2 mb-1"
						to="/technique/create"
					>
						Create New Technique
					</Button>
				</Col>
			</Row>
			<TechniqueFilters search={search} setSearch={setSearch} />
			<Table className="fs--1 mb-0 font-sans-serif" borderless>
				<thead className="bg-300 text-800">
					<tr className="">
						<th className="text-nowrap">Technique ID</th>
						<th className="text-nowrap ">Name</th>
						<th className="text-nowrap  ">Created On</th>
						<th className="text-nowrap ">Updated On</th>
						<th className="text-nowrap text-end "></th>
					</tr>
				</thead>
				<tbody className="bg-white">
					{techniqueState?.techniques?.data?.map(tech => (
						<tr>
							<td className="text-nowrap ">{tech.techniqueID}</td>
							<td className="text-nowrap ">{tech.name}</td>
							<td className="text-nowrap ">
								{getDateInFormatOfDMY(tech.createdAt)}
							</td>
							<td className="text-nowrap ">
								{getDateInFormatOfDMY(tech.updatedAt)}
							</td>
							<td className="text-nowrap ">
								<Button
									as={Link}
									to={`/technique/edit/${tech.id}`}
									variant="outline-primary"
								>
									Edit
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
			{techniqueState?.techniques?.total_pages ? (
				<Pagination
					totalPages={techniqueState?.techniques?.total_pages}
				/>
			) : null}
		</Fragment>
	);
}
