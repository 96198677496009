import classNames from 'classnames';
import AppContext from 'context/Context';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { CloseButton as Button } from 'react-bootstrap';

const CloseButton = ({
	size,
	onClick,
	noOutline,
	variant,
	className,
	...rest
}) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	return (
		<Button
			variant={variant ? variant : isDark ? 'white' : undefined}
			className={classNames('btn', {
				[`btn-${size}`]: size,
				'outline-none': noOutline,
				[className]: className
			})}
			onClick={onClick && onClick}
			{...rest}
		/>
	);
};

CloseButton.propTypes = {
	size: PropTypes.oneOf(['sm', 'lg']),
	noOutline: PropTypes.bool,
	variant: PropTypes.string, // use 'white' for white variant
	onClick: PropTypes.func,
	className: PropTypes.string
};

export default CloseButton;
