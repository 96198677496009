import { generateQueryString, handleError } from 'helpers/utils';
import axios from './axiosInstance';

export const getAllTemplatesService = async (
	page = 1,
	search = null,
	type = null
) => {
	let params = { page, search, type };
	const queryString = generateQueryString(params);
	let query = '';
	if (queryString) {
		query += `?${queryString}`;
	}
	try {
		const resp = await axios.get('/template' + query);
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};

export const createTemplateService = async data => {
	try {
		const resp = await axios.post('/template', data);
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};

export const getTempateById = async id => {
	try {
		const resp = await axios.get('/template/' + id);
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};

export const updateTemplateService = async (id, data) => {
	try {
		const resp = await axios.put('/template/' + id, data);
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};

export const deleteStepService = async id => {
	try {
		const resp = await axios.delete('/template/step/' + id);
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};
