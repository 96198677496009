import React, { useContext, useEffect } from 'react';

import TemplateCreateUpdateComponent from './templates/TemplateCreateUpdateComponent';
import { createTemplateService } from 'services/templateService';
import { updateTechniqueService } from 'services/techniqueService';
import { useState } from 'react';
import { StepContext, TestContext } from 'context/Context';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { findIndexOfSteps } from 'helpers/utils';
import { attachTagsService } from 'services/tagService';
import useError from 'hooks/useError';

export default function TemplateCreate() {
	const { getResponse } = useError();

	const navigate = useNavigate();
	const location = useLocation();
	const {
		stepState: { edges, nodes },
		resetStep
	} = useContext(StepContext);
	const { testState, resetTest } = useContext(TestContext);
	const [formData, setFormData] = useState({
		name: '',
		description: '',
		steps: [],
		status: 'draft',
		type: 'killchain'
	});
	const [tags, setTags] = useState([]);

	useEffect(() => {
		return () => {
			resetStep();
			resetTest();
		};
	}, []);

	const handleFieldChange = e => {
		const { id, value } = e.target;
		setFormData({ ...formData, [id]: value });
	};

	const handleSubmit = () => {
		if (formData.type === 'killchain') {
			createKillChain();
		} else {
			let testsWithNoId = testState.tests.map(test => {
				const { id, ...rest } = test;
				return { ...rest };
			});
			createTemplate(testsWithNoId);
		}
	};

	const createKillChain = async () => {
		const { steps, techniques } = refactorNodesEdgesIntoStepsAndTechniques(
			nodes,
			edges
		);
		for (const tech of techniques) {
			const techRes = await updateTechniqueService(tech.id, {
				formSchema: tech?.formSchema
			});
			await getResponse(techRes).catch(err => console.error(err));
		}
		createTemplate(steps);
	};

	const createTemplate = async steps => {
		const templateData = {
			...formData,
			steps: steps
		};
		const res = await createTemplateService(templateData);
		await getResponse(res)
			.then(async res => {
				const tagRes = await attachTagsService({
					names: tags,
					taggableName: 'Template',
					taggableId: res.id
				});
				await getResponse(tagRes)
					.then(async res => {
						toast(
							<span className="text-success">
								Template created successfully!
							</span>
						);
						resetStep();
						resetTest();
						navigate('/templates');
					})
					.catch(err => console.error(err));
			})
			.catch(err => console.error(err));
	};

	return (
		<React.Fragment>
			<TemplateCreateUpdateComponent
				title={'Create Template'}
				submitButtonText={'Create Template'}
				onSubmit={handleSubmit}
				data={formData}
				handleFieldChange={handleFieldChange}
				tags={tags}
				handleTags={setTags}
				mode="create"
			/>
		</React.Fragment>
	);
}

const refactorNodesEdgesIntoStepsAndTechniques = (nodesData, edgesData) => {
	const newNodes = findIndexOfSteps(nodesData, edgesData);
	let techniques = [];
	let steps = [];
	newNodes.forEach(node => {
		const {
			name,
			description,
			action,
			detailStatus,
			status,
			techniqueId,
			masterConfig,
			extraConfig,
			index
		} = node.data;
		const edges = edgesData.filter(e => e.source === node.id);
		let successTechniqueId = null;
		let failureTechniqueId = null;
		edges.forEach(e => {
			let sourceNode = nodesData.find(n => n.id === e.target);
			if (e.className === 'success') {
				successTechniqueId = sourceNode.data.techniqueId;
			} else {
				failureTechniqueId = sourceNode.data.techniqueId;
			}
		});
		steps.push({
			name,
			description,
			techniqueId,
			successTechniqueId,
			failureTechniqueId,
			node: {
				id: node.id,
				position: node.position
			},
			edges: edges,
			index: index
		});
		techniques.push({
			id: techniqueId,
			...masterConfig,
			...extraConfig
		});
	});
	return { steps, techniques };
};

const getRefactoredTechniqueResponse = res => {
	let {
		id,
		Taggable,
		createdAt,
		updatedAt,
		deletedAt,
		tacticId,
		tacticID,
		...remainingProperties
	} = res;
	return remainingProperties;
};
