import React, { useState, useEffect, useCallback, useContext } from 'react';
import ReactFlow, {
	useEdgesState,
	addEdge,
	MiniMap,
	Controls,
	Background,
	applyEdgeChanges,
	applyNodeChanges,
	useReactFlow,
	getConnectedEdges,
	updateEdge
} from 'reactflow';
import 'reactflow/dist/style.css';
import './overview.css';

import { getColor } from 'helpers/utils';

import ColorSelectorNode from './CustomNode';
import { StepContext } from 'context/Context';
import CustomEdge from './CustomEdge';

const LABEL_DATA = {
	success: { label: 'SUCCESS', className: 'success' },
	failure: { label: 'FAILURE', className: 'danger' }
};

const TOGGLE_LABEL_DATA = {
	failure: {
		label: 'SUCCESS',
		className: 'success',
		key: 'success'
	},
	success: {
		label: 'FAILURE',
		className: 'danger',
		key: 'failure'
	}
};

const EDGE_LABEL_COLORS = {
	success: 'success',
	failure: 'danger'
};

const connectionLineStyle = { stroke: '#000', type: 'smoothstep' };
const snapGrid = [20, 20];
const nodeTypes = {
	custom: ColorSelectorNode
};
const edgeTypes = {
	custom: CustomEdge
};
const minimapStyle = {
	height: 120
};
const defaultViewport = { x: 0, y: 0, zoom: 1 };

const CustomNodeFlow = ({ flowNodes, flowEdges }) => {
	const { getNode, getEdges } = useReactFlow();
	const { addEdgeToStore, updateNodePosition, updateConnectionOfEdge } =
		useContext(StepContext);
	const [nodes, setNodes] = useState(flowNodes);
	const [edges, setEdges] = useEdgesState(flowEdges);

	const useValidatorFn = () => {
		const { getNode, getEdges } = useReactFlow();

		return useCallback(
			connection => {
				const edges = getConnectedEdges(
					[getNode(connection.target)],
					getEdges()
				);
				console.log('val', edges, connection);
				for (let i = 0; i < edges.length; i++) {
					if (
						(edges[i].sourceHandle === connection.sourceHandle &&
							edges[i].source === connection.source) ||
						(edges[i].targetHandle === connection.targetHandle &&
							edges[i].target === connection.target)
					) {
						console.log('handle already used');
						return false;
					}
				}
				return true;
			},
			[getNode, getEdges]
		);
	};

	useEffect(() => {
		setNodes(flowNodes);
	}, [flowNodes]);

	useEffect(() => {
		setEdges(flowEdges);
	}, [flowEdges]);

	useEffect(() => {
		console.log(flowEdges, edges, 'ldfk');
		if (flowEdges.length !== edges.length) {
			// new edge
			addEdgeToStore(edges[edges.length - 1]);
		}
	}, [edges]);

	const onNodesChange = useCallback(changes => {
		setNodes(nds => applyNodeChanges(changes, nds));
	}, []);

	const onEdgesChange = useCallback(changes => {
		setEdges(eds => applyEdgeChanges(changes, eds)),
			console.log('oec', changes);
	}, []);

	const onConnect = useCallback(params => {
		const conn_edges = getConnectedEdges(
			[getNode(params.source)],
			getEdges()
		);
		console.log(conn_edges, 'ff');
		const nodeEdges = conn_edges.filter(e => e.source === params.source);
		console.log('testnow-t', conn_edges);
		let labelKey;
		switch (nodeEdges.length) {
			case 0:
				labelKey = 'success';
				break;
			case 1:
				labelKey = TOGGLE_LABEL_DATA[nodeEdges[0].labelKey].key;
				break;
			default:
				break;
		}
		if (labelKey) {
			console.log('run edge');
			setEdges(eds =>
				addEdge(
					{
						...params,
						animated: true,
						type: 'custom',
						label: LABEL_DATA[labelKey].label,
						labelKey: labelKey,
						className: LABEL_DATA[labelKey].className
					},
					eds
				)
			);
		}
	}, []);

	// gets called after end of edge gets dragged to another source or target
	const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
		updateConnectionOfEdge(oldEdge, newConnection);
		setEdges(els => updateEdge(oldEdge, newConnection, els));
	}, []);

	const handleNodeDragStop = (e, _node, _nodes) => {
		// _node, updated position for draged node
		let cn = flowNodes.find(n => n.id === _node.id);
		if (cn) {
			let x1 = cn.position.x;
			let x2 = _node.position.x;
			let y1 = cn.position.y;
			let y2 = _node.position.y;
			if (x1 !== x2 || y1 !== y2) {
				if (Math.abs(x2 - x1) > 5 || Math.abs(y2 - y1) > 5) {
					updateNodePosition({
						id: _node.id,
						position: _node.position
					});
				}
			}
		}
	};

	return (
		<React.Fragment>
			<ReactFlow
				nodes={nodes}
				edges={edges}
				onNodesChange={onNodesChange}
				onEdgesChange={onEdgesChange}
				onNodeDragStop={handleNodeDragStop}
				onConnect={onConnect}
				onEdgeUpdate={onEdgeUpdate}
				edgeTypes={edgeTypes}
				nodeTypes={nodeTypes}
				isValidConnection={useValidatorFn()}
				connectionLineStyle={connectionLineStyle}
				connectionLineType="smoothstep"
				snapToGrid={true}
				snapGrid={snapGrid}
				defaultViewport={defaultViewport}
				// fitView
				attributionPosition="bottom-left"
				style={{ backgroundColor: '#fff' }}
			>
				<MiniMap
					style={minimapStyle}
					zoomable
					pannable
					position="top-right"
				/>
				<Controls showInteractive={false} />
				<Background color="#aaa" gap={16} />
			</ReactFlow>
		</React.Fragment>
	);
};

export default CustomNodeFlow;
