export const testReducer = (state, action) => {
	const { type, payload } = action;
	switch (type) {
		case 'ADD_TESTS':
			return { ...state, tests: [...state.tests, ...payload] };
		case 'UPDATE_TESTS':
			let _tests = state.tests.slice();
			let testIndex = _tests.findIndex(t => t.id === payload.id);
			if (testIndex > -1) {
				_tests[testIndex] = {
					..._tests[testIndex],
					...payload.data
				};
				return { ...state, tests: [..._tests] };
			}
			return state;
		case 'SELECT_TEST':
			return {
				...state,
				selectedTest: payload
			};
		case 'RESET_TESTS':
			return payload;
		default:
			return state;
	}
};
