import FalconCloseButton from 'components/common/CloseButton';
import DynamicForms from 'components/common/JsonFormGenerator';
import React from 'react';
import { Modal } from 'react-bootstrap';

export default function PreviewTechniqueConfig({
	show,
	handleClose,
	formSchema
}) {
	return (
		<Modal
			show={show}
			onHide={handleClose}
			backdrop="static"
			keyboard={false}
		>
			<Modal.Header>
				<Modal.Title>Preview Technique Configs</Modal.Title>
				<FalconCloseButton onClick={handleClose} />
			</Modal.Header>
			<Modal.Body>
				{Object.keys(formSchema ?? {})?.map(os => (
					<React.Fragment key={os}>
						<h4 className="text-capitalize">{os}</h4>
						{renderFormByOS(formSchema[os])}
					</React.Fragment>
				))}
			</Modal.Body>
		</Modal>
	);
}

const renderFormByOS = os => {
	console.log(os);
	if (os) {
		return os.map(section => <DynamicForms formsData={section?.form} />);
	}
};
