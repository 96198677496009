export const runningTaskStatus = [
  {
    id: 1,
    title: 'NMap vunlerability Task for port 43 & IP 34.52.4444.1',
    completion_percentile: 43.6,
    project_id: 'GMR_01-771',
    createdAt: '23-02-2023 12:31:55'
  },
  {
    id: 2,
    title: 'NMap vunlerability Task for port 43 & IP 34.52.4444.1',
    completion_percentile: 13.11,
    project_id: 'GMR_01-771',
    createdAt: '23-02-2023 12:31:55'
  },
  {
    id: 3,
    title:
      'NMap vunlerability Task for port 43 & IP 34.52.4444.1 NMap vunlerability Task for port 43 & IP 34.52.4444.1',
    completion_percentile: 12.11,
    project_id: 'GMR_01-771',
    createdAt: '23-02-2023 12:31:55'
  },
  {
    id: 4,
    title: 'NMap vunlerability Task for port 43 & IP 34.52.4444.1',
    completion_percentile: 43.6,
    project_id: 'GMR_01-771',
    createdAt: '23-02-2023 12:31:55'
  },
  {
    id: 5,
    title:
      'NMap vunlerability Task for port 43 & IP 34.52.4444.1 NMap vunlerability Task for port 43 & IP 34.52.4444.1',
    completion_percentile: 60.5,
    project_id: 'GMR_01-771',
    createdAt: '23-02-2023 12:31:55'
  },
  {
    id: 6,
    title:
      'NMap vunlerability Task for port 43 & IP 34.52.44 vunlerability Task for port 43 & IP 34.52.4444.1',
    completion_percentile: 80.5,
    project_id: 'GMR_01-771',
    createdAt: '23-02-2023 12:31:55'
  }
];

export const tags = [
  { name: 'beSecure' },
  { name: 'OpenVas' },
  { name: 'Tenable' },
  { name: 'Nesseus' },
  { name: 'Tool with longest name' },
  { name: 'PDF' },
  { name: 'IP' }
];

export const cateTAskData = [
  {
    id: 1,
    name: 'Database',
    value: 40,
    color: 'info'
  },
  {
    id: 2,
    name: 'Host based',
    value: 23,
    color: 'primary'
  },
  {
    id: 3,
    name: 'Application',
    value: 20,
    color: 'danger'
  },
  {
    id: 4,
    name: 'Network',
    value: 47,
    color: 'warning'
  }
];

export const cateProjectData = [
  {
    id: 1,
    name: 'Category 1',
    value: 40,
    color: 'info'
  },
  {
    id: 2,
    name: 'Category 2',
    value: 23,
    color: 'primary'
  },
  {
    id: 3,
    name: 'Category 3',
    value: 20,
    color: 'danger'
  },
  {
    id: 4,
    name: 'Category 4',
    value: 47,
    color: 'warning'
  }
];
