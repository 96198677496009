export const integrationReducer = (state, action) => {
	const { type, payload } = action;
	switch (type) {
		case 'SAVE_ALL':
			return {
				...state,
				all: payload
			};
		default:
			return state;
	}
};
