import React, { useCallback, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import ProjectHeader from './projects/ProjectHeader';
import ProjectsTable from './projects/ProjectsTable';
import usePagination from 'hooks/usePagination';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	createProjectService,
	deleteProjectService,
	getAllProjectService,
	updateProjectService
} from 'services/projectService';
import { toast } from 'react-toastify';
import CreateUpdateProjectModal from './projects/CreateUpdateProjectModal';
import useError from 'hooks/useError';

export default function Projects({ orgId }) {
	const navigate = useNavigate();
	const location = useLocation();
	const { getResponse } = useError();

	const [showCreate, setCreateShow] = useState(false);
	// const [showUpdate, setUpdateShow] = useState(false);
	const [creating, setCreating] = useState(false);
	const [deletingProject, setDeletingProject] = useState(null);
	const [selectProjectId, setSelectProjectId] = useState(null);
	const [formData, setFormData] = useState({
		name: '',
		description: ''
	});

	const [isFetching, setFetching] = useState(false); // initial fetching state = false
	const [projectsRes, setProjectsRes] = useState(null);

	const handleCreateClose = () => setCreateShow(false);
	const handleCreateShow = () => setCreateShow(true);

	const handleUpdateClose = () => setSelectProjectId(null);

	useEffect(() => {
		getAllProjects(orgId, 1);
	}, []);

	const getAllProjects = useCallback(async (orgId, page) => {
		setFetching(true);
		const res = await getAllProjectService(orgId, page);
		getResponse(res)
			.then(res => {
				setProjectsRes(res);
				setFetching(false);
			})
			.catch(err => {
				setFetching(false);
			});
	}, []);

	const getProjectsByOrgIdWithPage = page => {
		getAllProjects(orgId, page);
	};
	const { Pagination } = usePagination(getProjectsByOrgIdWithPage);

	const handleProjectEdit = item => {
		setFormData({
			name: item.name,
			description: item.description
		});
		setSelectProjectId(item.id);
	};

	const handleProjectDelete = async id => {
		setDeletingProject(id);
		const res = await deleteProjectService(id);
		getResponse(res)
			.then(res => {
				toast(
					<span className="text-success">
						Project successfully deleted.
					</span>
				);
				getAllProjects();
				setDeletingProject(null);
			})
			.catch(err => {
				setDeletingProject(null);
			});
	};

	const handleChange = e => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleCreateSubmit = async () => {
		setCreating(true);
		const res = await createProjectService({
			...formData,
			organizationId: orgId
		});
		getResponse(res)
			.then(res => {
				toast(
					<span className="text-success">
						Project successfully created.
					</span>
				);
				getAllProjects();
				handleCreateClose();
				setCreating(false);
			})
			.catch(err => {
				setCreating(false);
			});
	};

	const handleEditSubmit = async () => {
		setCreating(true);
		const res = await updateProjectService(selectProjectId, {
			...formData,
			organizationId: orgId
		});
		getResponse(res)
			.then(res => {
				toast(
					<span className="text-success">
						Project successfully updated.
					</span>
				);
				getAllProjects();
				handleUpdateClose();
				setCreating(false);
			})
			.catch(err => {
				setCreating(false);
			});
	};

	return (
		<React.Fragment>
			<ProjectHeader handleShow={handleCreateShow} />

			<Card className="overflow-hidden my-4">
				<Card.Body className="p-0">
					<ProjectsTable
						data={projectsRes?.data || []}
						isFetching={isFetching}
						editAction={handleProjectEdit}
						deleteAction={handleProjectDelete}
						deletingProject={deletingProject}
					/>
				</Card.Body>
			</Card>

			{projectsRes?.total_pages ? (
				<Pagination totalPages={projectsRes.total_pages} />
			) : null}
			{showCreate && (
				<CreateUpdateProjectModal
					loading={creating}
					show={showCreate}
					handleClose={handleCreateClose}
					onSubmit={handleCreateSubmit}
					handleChange={handleChange}
					btnText="Create"
					btnLoadingText="Creating"
					title="Create New Project"
					formData={formData}
				/>
			)}
			{selectProjectId && (
				<CreateUpdateProjectModal
					loading={creating}
					handleChange={handleChange}
					onSubmit={handleEditSubmit}
					show={selectProjectId != null}
					handleClose={handleUpdateClose}
					btnText="Save"
					btnLoadingText="Saving"
					title="Update Project"
					formData={formData}
				/>
			)}
		</React.Fragment>
	);
}
