import CardHeader from 'components/common/CardHeader';
import React from 'react';
import { Card } from 'react-bootstrap';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { GaugeChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  GaugeChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = data => ({
  series: [
    {
      type: 'gauge',
      startAngle: 90,
      endAngle: -270,
      radius: '90%',
      pointer: {
        show: false
      },
      progress: {
        show: true,
        overlap: false,
        // roundCap: true,
        clip: false,
        itemStyle: {
          color: getColor('primary')
        }
      },
      axisLine: {
        lineStyle: {
          width: 24,
          color: [[1, getColor('gray-200')]]
        }
      },
      splitLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false
      },
      data: [
        {
          value: data,
          detail: {
            offsetCenter: ['7%', '4%']
          }
        }
      ],
      detail: {
        fontSize: 0
      }
    }
  ]
});

export default function FileDownload() {
  return (
    <Card className="h-100">
      <CardHeader
        title="Files Downloaded"
        titleTag="h5"
        className="border-200 border-bottom py-3"
        titleClass="text-black"
      />
      <Card.Body>
        <div className="position-relative">
          <ReactEChartsCore
            echarts={echarts}
            option={getOptions(15)}
            style={{ height: '18rem' }}
          />
          <div className="position-absolute top-50 start-50 translate-middle text-center">
            <p className="fs-6 mb-0 font-sans-serif fw-semi-bold mt-n2 text-black">
              1250
            </p>
            <p className="fs--1 mt-n2 mb-0 text-900 font-sans-serif fw-medium">
              Downloaded
            </p>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
