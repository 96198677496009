import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import StepForm from './StepForm';
import ScenarioTable from './ScenarioTable';
import FalconCloseButton from 'components/common/CloseButton';

export default function AddEditStep({
	title,
	show,
	handleClose,
	isSaveLoading,
	onSave
}) {
	const handleSave = () => {
		onSave();
	};

	return (
		<React.Fragment>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				size="xl"
			>
				<Modal.Header>
					<Modal.Title>{title}</Modal.Title>
					<FalconCloseButton
						onClick={handleClose}
						disabled={isSaveLoading}
					/>
				</Modal.Header>
				<Modal.Body>
					<StepForm />
					<ScenarioTable />
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="falcon-default"
						className="py-2 px-4"
						onClick={handleClose}
						size="sm"
						disabled={isSaveLoading}
					>
						Cancel
					</Button>
					<Button
						variant="primary"
						size="sm"
						className="py-2 px-4"
						onClick={handleSave}
						disabled={isSaveLoading}
					>
						{isSaveLoading ? 'Saving...' : 'Save'}
					</Button>
				</Modal.Footer>
			</Modal>
		</React.Fragment>
	);
}
