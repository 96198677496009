import { generateQueryString, handleError } from 'helpers/utils';
import axios from './axiosInstance';

export const getAllProjectService = async (organizationId, page = 1) => {
	let params = { organizationId, page };
	const queryString = generateQueryString(params);
	let query = '';
	if (queryString) {
		query += `?${queryString}`;
	}
	try {
		const resp = await axios.get('/project' + query);
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};

export const createProjectService = async data => {
	try {
		const resp = await axios.post('/project', data);
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};

export const updateProjectService = async (id, data) => {
	try {
		const resp = await axios.put('/project/' + id, data);
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};

export const deleteProjectService = async id => {
	try {
		const resp = await axios.delete('/project/' + id);
		return resp.data;
	} catch (err) {
		return handleError(err);
	}
};
