import classNames from 'classnames';
import React, { useState } from 'react';

export default function TagView({ data, maxTagShow, emptyComponent = null }) {
	const [isClicked, setClicked] = useState(false);
	if (data?.length == 0) {
		return emptyComponent ? emptyComponent() : null;
	}
	return (
		<div className="d-flex flex-wrap">
			{data?.slice(0, maxTagShow).map(tag => (
				<TagPill
					key={tag}
					bgClass="bg-soft-dark"
					txClass="text-black"
					label={tag}
				/>
			))}
			{isClicked ? (
				data
					?.slice(maxTagShow)
					.map(tag => (
						<TagPill
							key={tag}
							bgClass="bg-soft-dark"
							txClass="text-black"
							label={tag}
						/>
					))
			) : data?.length - maxTagShow > 0 ? (
				<TagPill
					bgClass="bg-dark"
					txClass="text-white"
					extraClasses="hover-pointer"
					label={`+${data?.length - maxTagShow}`}
					onClick={e => {
						e.stopPropagation();
						setClicked(true);
					}}
				/>
			) : null}
		</div>
	);
}

const TagPill = ({ bgClass, txClass, label, extraClasses = '', ...rest }) => {
	return (
		<div
			{...rest}
			className={classNames(
				'rounded-pill me-2 mb-1 px-2 fs--1 fw-medium',
				{
					[bgClass]: true,
					[txClass]: true,
					[extraClasses]: true
				}
			)}
		>
			{label}
		</div>
	);
};
