import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import CloseButton from 'components/common/CloseButton';
import SelectBox from 'components/common/SelectBox';
import { toast } from 'react-toastify';
import { createUserService } from 'services/orgService';
import useError from 'hooks/useError';

function AddUser({ orgId, show, handleClose, onAdd }) {
	const { getResponse } = useError();

	const [adding, setAdding] = useState(false);
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		avatar: 'https://example.com/avatar.jpg',
		role: ''
	});

	const handleEdit = ({ target }) => {
		let { id, value } = target;
		setFormData({ ...formData, [id]: value });
	};

	const handleRole = value => {
		setFormData({ ...formData, role: value });
	};

	const handleSubmit = async e => {
		e.preventDefault();
		setAdding(true);
		const data = {
			firstName: formData.firstName,
			lastName: formData.lastName,
			email: formData.email,
			password: formData.password,
			avatar: 'https://example.com/avatar.jpg',
			organizationId: orgId,
			role: formData.role?.value
		};
		const res = await createUserService(data);

		getResponse(res)
			.then(res => {
				toast(
					<span className="text-success">
						User successfully added.
					</span>
				);
				setAdding(false);
				onAdd();
				handleClose();
			})
			.catch(err => {
				setAdding(false);
			});
	};

	useEffect(() => {
		return () => {
			setFormData({
				firstName: '',
				lastName: '',
				email: '',
				password: '',
				avatar: 'https://example.com/avatar.jpg',
				role: ''
			});
		};
	}, []);

	return (
		<>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				size="lg"
			>
				<Modal.Header>
					<Modal.Title>Create New User</Modal.Title>
					<CloseButton onClick={handleClose} disabled={adding} />
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col md={6}>
							<Form.Label column sm={4}>
								First Name*
							</Form.Label>
							<Form.Control
								id="firstName"
								type="text"
								className="text-700"
								placeholder="Enter First Name"
								value={formData.firstName}
								onChange={handleEdit}
							/>
						</Col>
						<Col md={6}>
							<Form.Label column sm={4}>
								Last Name*
							</Form.Label>
							<Form.Control
								id="lastName"
								type="text"
								className="text-700"
								placeholder="Enter Last Name"
								value={formData.lastName}
								onChange={handleEdit}
							/>
						</Col>
						<Col md={6}>
							<Form.Label column sm={4}>
								Email*
							</Form.Label>
							<Form.Control
								id="email"
								readOnly
								onFocus={e =>
									e.target.removeAttribute('readonly')
								}
								onBlur={e =>
									e.target.setAttribute('readonly', '')
								}
								type="email"
								autoComplete="off"
								className="text-700"
								placeholder="Enter Email"
								onChange={handleEdit}
								value={formData.email}
							/>
						</Col>
						<Col md={6}>
							<Form.Label column sm={4}>
								Password*
							</Form.Label>

							<Form.Control
								id="password"
								readOnly
								onFocus={e =>
									e.target.removeAttribute('readonly')
								}
								onBlur={e =>
									e.target.setAttribute('readonly', '')
								}
								autoComplete="off"
								type="password"
								className="text-700"
								value={formData.password}
								placeholder="Enter Password"
								onChange={handleEdit}
							/>
						</Col>
					</Row>

					<Row>
						<Col md={6}>
							<Form.Label column sm={4}>
								Role
							</Form.Label>
							<SelectBox
								options={[
									{ value: 'ORG_USER', label: 'User' },
									{ value: 'ORG_ADMIN', label: 'Admin' }
								]}
								value={formData.role}
								onChange={handleRole}
							/>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="falcon-default"
						className="py-2 px-4"
						onClick={handleClose}
						disabled={adding}
					>
						Cancel
					</Button>
					<Button
						onClick={handleSubmit}
						variant="primary"
						className="py-2 px-4"
						disabled={adding}
					>
						{adding ? 'Adding...' : 'Add'}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default AddUser;
