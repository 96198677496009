import { formatPhoneNumber } from 'helpers/utils';
import useError from 'hooks/useError';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createOrgService } from 'services/orgService';
import * as Yup from 'yup';

const schema = Yup.object().shape({
	name: Yup.string().required('Name is required'),
	email: Yup.string().email('Invalid email').required('Email is required')
});

export default function CreateOrg() {
	const navigate = useNavigate();
	const { getResponse } = useError();
	const [creating, setCreating] = useState(false);
	const [formData, setFormData] = useState({
		name: '',
		description: '',
		email: '',
		phone: '',
		address_1: '',
		address_2: '',
		city: '',
		state: '',
		country: '',
		zipCode: ''
	});
	const [errors, setErrors] = useState({
		name: '',
		email: ''
	});

	const handleFieldChange = e => {
		const { id, value } = e.target;
		let newVal = value;
		if (id === 'phone') {
			newVal = formatPhoneNumber(value);
		}
		setFormData({ ...formData, [id]: newVal });
	};
	const handleSubmit = async e => {
		e.preventDefault();
		const isFormValid = await schema.isValid(formData, {
			abortEarly: false // Prevent aborting validation after first error
		});
		if (!isFormValid) {
			schema.validate(formData, { abortEarly: false }).catch(error => {
				// Collect all errors in { fieldName: boolean } format:
				const validationErrors = {};
				error.inner.forEach(err => {
					validationErrors[err.path] = err.message;
				});
				setErrors(validationErrors);
			});
			return;
		}
		setCreating(true);
		let orgData = {
			name: formData.name,
			description: formData.description,
			contact: {
				email: formData.email,
				phone: formData.phone,
				address: {
					address_1: formData.address_1,
					address_2: formData.address_2,
					city: formData.city,
					state: formData.state,
					country: formData.country,
					zipCode: formData.zipCode
				}
			}
		};
		const res = await createOrgService(orgData);
		getResponse(res)
			.then(res => {
				setCreating(false);
				toast(
					<span className="text-success">
						Organisation created successfully!
					</span>
				);
				navigate('/orgs');
			})
			.catch(err => {
				setCreating(false);
			});
	};

	return (
		<React.Fragment>
			<h5 className="mb-3">Create New Organisation</h5>
			<div>
				<Row>
					<Col md={6}>
						<div className="mb-3">
							<Form.Label>Name*</Form.Label>
							<Form.Control
								id="name"
								type="text"
								className="text-700"
								placeholder="e.g. Linear"
								value={formData?.name}
								onChange={handleFieldChange}
							/>
							{errors.name && (
								<span className="text-danger fs--2 fw-medium">
									{errors.name}
								</span>
							)}
						</div>
					</Col>
					<Col md={6}>
						<div className="mb-3">
							<Form.Label>Email*</Form.Label>
							<Form.Control
								id="email"
								type="text"
								className="text-700"
								placeholder="e.g. Linear"
								value={formData?.email}
								onChange={handleFieldChange}
							/>
							{errors.email && (
								<span className="text-danger fs--2 fw-medium">
									{errors.email}
								</span>
							)}
						</div>
					</Col>
					<Col md={6}>
						<div className="mb-3">
							<Form.Label>Description</Form.Label>
							<Form.Control
								id="description"
								as="textarea"
								rows={3}
								className="text-700"
								placeholder="e.g. Linear"
								value={formData?.description}
								onChange={handleFieldChange}
							/>
						</div>
					</Col>
					<Col md={6}>
						<div className="mb-3">
							<Form.Label>Phone</Form.Label>
							<Form.Control
								id="phone"
								type="text"
								className="text-700"
								placeholder="e.g. Linear"
								value={formData?.phone}
								onChange={handleFieldChange}
								maxLength={10}
							/>
						</div>
					</Col>
					<Col md={6}>
						<div className="mb-3">
							<Form.Label>Address 1</Form.Label>
							<Form.Control
								id="address_1"
								as="textarea"
								rows={3}
								className="text-700"
								placeholder="e.g. Linear"
								value={formData?.address_1}
								onChange={handleFieldChange}
							/>
						</div>
					</Col>
					<Col md={6}>
						<div className="mb-3">
							<Form.Label>Address 2</Form.Label>
							<Form.Control
								id="address_2"
								as="textarea"
								rows={3}
								className="text-700"
								placeholder="e.g. Linear"
								value={formData?.address_2}
								onChange={handleFieldChange}
							/>
						</div>
					</Col>
					<Col md={6}>
						<div className="mb-3">
							<Form.Label>City</Form.Label>
							<Form.Control
								id="city"
								type="text"
								className="text-700"
								placeholder="e.g. Linear"
								value={formData?.city}
								onChange={handleFieldChange}
							/>
						</div>
					</Col>
					<Col md={6}>
						<div className="mb-3">
							<Form.Label>State</Form.Label>
							<Form.Control
								id="state"
								type="text"
								className="text-700"
								placeholder="e.g. Linear"
								value={formData?.state}
								onChange={handleFieldChange}
							/>
						</div>
					</Col>
					<Col md={6}>
						<div className="mb-3">
							<Form.Label>Country</Form.Label>
							<Form.Control
								id="country"
								type="text"
								className="text-700"
								placeholder="e.g. Linear"
								value={formData?.country}
								onChange={handleFieldChange}
							/>
						</div>
					</Col>
					<Col md={6}>
						<div className="mb-3">
							<Form.Label>Zip Code</Form.Label>
							<Form.Control
								id="zipCode"
								type="text"
								className="text-700"
								placeholder="e.g. Linear"
								value={formData?.zipCode}
								onChange={handleFieldChange}
							/>
						</div>
					</Col>
				</Row>
			</div>
			<div>
				<Button
					variant="primary"
					className="py-2 px-4"
					onClick={handleSubmit}
				>
					{creating ? 'Creating...' : 'Create'}
				</Button>
			</div>
		</React.Fragment>
	);
}
