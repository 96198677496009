import { createContext } from 'react';
import { settings } from 'config';

const AppContext = createContext(settings);
export const AuthContext = createContext({});
export const AgentContext = createContext({});
export const SelectContext = createContext({ selectedTasks: [] });
export const TemplateContext = createContext({});
export const TechniqueContext = createContext({});
export const AddStepContext = createContext({});
export const EditStepContext = createContext({});
export const StepContext = createContext({});
export const OrgContext = createContext({});
export const TestContext = createContext({});
export const IntegrationContext = createContext({});
export default AppContext;
