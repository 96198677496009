import React, { useEffect, useRef, useState } from 'react';
import '@yaireo/tagify/dist/tagify.css';
import 'assets/scss/tag_search.scss';
import Tagify from '@yaireo/tagify';

export default function TagBoxAnimated({
	label,
	labelClass = '',
	id = '',
	tags = [],
	updateTags = () => {}
}) {
	const [tagsList, setTagsList] = useState(tags || []);
	useEffect(() => {
		const searchElement = document.getElementById(id);
		const tagify = new Tagify(searchElement);

		tagify.addTags(tags);
		tagify.on('add', onTagAdded);
		tagify.on('remove', onTagRemoved);

		return () => {
			tagify.destroy(); // Cleanup when component unmounts
		};
	}, []);

	useEffect(() => {
		updateTags(tagsList);
	}, [tagsList]);

	function onTagAdded(e) {
		let tags_list = e.detail.tagify.value.map(tag => tag.value);
		setTagsList(tags_list);
	}

	function onTagRemoved(e) {
		let tags_list = e.detail.tagify.value.map(tag => tag.value);
		setTagsList(tags_list);
	}

	return (
		<div className="tag_search_box_container input-tagfield w-100 h-100">
			<input id={id} className="w-100" />
			<label className={labelClass}>{label}</label>
		</div>
	);
}
