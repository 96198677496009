import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

export const getItemFromStore = (key, defaultValue, store = localStorage) => {
	try {
		return store.getItem(key) === null
			? defaultValue
			: JSON.parse(store.getItem(key));
	} catch {
		return store.getItem(key) || defaultValue;
	}
};

export const getColor = (name, dom = document.documentElement) => {
	return getComputedStyle(dom).getPropertyValue(`--falcon-${name}`).trim();
};

export const hexToRgb = hexValue => {
	let hex;
	hexValue.indexOf('#') === 0
		? (hex = hexValue.substring(1))
		: (hex = hexValue);
	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
		hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
	);
	return result
		? [
				parseInt(result[1], 16),
				parseInt(result[2], 16),
				parseInt(result[3], 16)
		  ]
		: null;
};

export const rgbColor = color => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color, alpha = 0.5) =>
	`rgba(${hexToRgb(color)},${alpha})`;

export const isIterableArray = array => Array.isArray(array) && !!array.length;

export const capitalize = str =>
	(str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, ' ');

export const lowerize = str => str.toLowerCase().replace(/ /g, '');
export const setItemToStore = (key, payload, store = localStorage) =>
	store.setItem(key, payload);

export const getDateFormatByString = (dateString, format) =>
	dayjs(dateString).format(format);

export const getDateInFormatOfDMY = dateString =>
	getDateFormatByString(dateString, 'DD/MM/YYYY');

export const handleError = err => {
	if (err.response) {
		//err.response.statusText
		//err.response.headers
		return {
			err: {
				message: err.message,
				statusCode: err.response.status,
				responseMessage: err.response.data
			}
		};
	} else if (err.request) {
		// Request was made, but no response was received
		return {
			err: {
				message: err?.message,
				statusCode: null,
				responseMessage: null,
				reqErr: err?.request
			}
		};
	} else {
		// An error was thrown when setting up the request
		return {
			err: {
				message: err?.message,
				statusCode: null,
				responseMessage: null
			}
		};
	}
};

export const convertTechniqueFormSchemaIntoFormat = form => {
	return form.map(item => {
		const fieldStructure = [];
		if (item.type === 'form_group') {
			item.inputs.forEach(inputField => {
				fieldStructure.push(getInputByType(inputField));
			});
		} else {
			fieldStructure.push(getInputByType(item));
		}

		return {
			id: uuidv4(),
			label: '', // Set your desired label for each form
			description: '', // Set your desired description for each form
			forms: [
				{
					id: uuidv4(), // Function to generate a unique ID
					fields: fieldStructure.map(field => ({
						id: uuidv4(), // Function to generate a unique ID
						...field
					}))
				}
			],
			fieldStructure,
			multiple: item.is_multiple || false, // Set the multiple property based on your criteria
			max: item.max_count || null // Set the max property based on your criteria
		};
	});
};

const getInputByType = item => {
	console.log('if', item);
	let item_type =
		item.type === 'number' || item.type === 'text' ? 'input' : item.type;
	switch (item_type) {
		case 'number':
			return {
				id: uuidv4(), // Function to generate a unique ID
				type: item.type,
				label: item.label,
				name: item.output_key,
				default: item.default,
				value: null,
				placeholder: item.placeholder
			};
		case 'heading':
			return {
				id: uuidv4(), // Function to generate a unique ID
				type: 'text',
				label: 'Heading',
				name: item.type,
				value: item.value
			};
		case 'description':
			return {
				id: uuidv4(), // Function to generate a unique ID
				type: 'text',
				label: 'Description',
				name: item.type,
				value: item.value
			};
		case 'textarea':
			return {
				id: uuidv4(), // Function to generate a unique ID
				type: item.type,
				label: item.label,
				name: item.output_key,
				value: '',
				default: item.default,
				placeholder: item.placeholder
			};
		case 'radio':
			return {
				id: uuidv4(), // Function to generate a unique ID
				type: item.type,
				label: item.label,
				name: item.output_key,
				value: '',
				options: item.options
			};
		case 'file':
			return {
				id: uuidv4(), // Function to generate a unique ID
				type: item.type,
				label: item.label,
				name: item.output_key,

				allowedExtensions: item.allowed_extensions
			};
		case 'select':
			return {
				id: uuidv4(), // Function to generate a unique ID
				type: item.type,
				label: item.label,
				name: item.output_key,
				value: '',
				options: item.options
			};
		case 'checkbox':
			if (item?.max_check_count) {
				return {
					id: uuidv4(), // Function to generate a unique ID
					type: item.type,
					label: item.label,
					name: item.output_key,
					value: '',
					options: item.options,
					multiple: true
				};
			} else {
				return {
					id: uuidv4(), // Function to generate a unique ID
					type: item.type,
					label: item.label,
					name: item.output_key,
					value: false
				};
			}
		case 'input':
			console.log('enter in');
			return {
				id: uuidv4(), // Function to generate a unique ID
				type: item.type,
				label: item.label,
				name: item.output_key,
				default: item.default,
				value: null,
				placeholder: item.placeholder
			};
		default:
			console.log('enter de');
			break;
	}
};

export function findIndexOfSteps(nodes, edges) {
	const edgeTargets = edges.map(e => e.target);
	const edgeSources = edges.map(e => e.source);
	const uniqueSources = edgeSources.filter(es => !edgeTargets.includes(es));
	let indexNodes = [];
	nodes.forEach((node, index) => {
		let newNode = JSON.parse(JSON.stringify(node));
		if (index == 0 && edges.length == 0) {
			newNode.data.index = 1;
			indexNodes.push(newNode);
			return;
		}
		if (uniqueSources.includes(node.id)) {
			newNode.data.index = 1;
		} else {
			newNode.data.index = 2;
		}
		indexNodes.push(newNode);
	});
	return indexNodes;
}

export function findIndexChangedNodes(oldNodes, newNodes) {
	return oldNodes.filter(
		on => on.data.index != newNodes.find(n => n.id == on.id).data.index
	);
}

export function formatPhoneNumber(number) {
	let val = number.replace(/\D/g, '').slice(0, 3);
	if (number.length > 3) {
		val += '-' + number.replace(/\D/g, '').slice(3, 6);
	}
	if (number.length > 6) {
		val += '-' + number.replace(/\D/g, '').slice(6);
	}
	return val;
}

export const getRefactoredTechniqueResponse = res => {
	let { name, description, Taggable, ...remainingProperties } = res;
	return remainingProperties;
};

export function generateQueryString(params) {
	const searchParams = new URLSearchParams();

	Object.entries(params).forEach(([key, value]) => {
		if (
			value !== null &&
			value !== undefined &&
			value !== '' &&
			value !== []
		) {
			if (Array.isArray(value)) {
				searchParams.append(key, JSON.stringify(value));
			} else {
				searchParams.append(key, value);
			}
		}
	});

	return searchParams.toString();
}

export const getAgentStatusByRefactor = (status, lastSeen) => {
	if (status != 'ready' && status != 'deployed') {
		return status; //building or something else
	}
	if (!lastSeen || status === 'ready') {
		return status;
	}
	let sec_diff = getSecondDiffByNow(lastSeen);
	if (sec_diff > daysIntoSecs(1)) {
		return 'dead';
	} else if (sec_diff > minutesIntoSecs(5)) {
		return 'inactive';
	} else {
		return 'deployed';
	}
};

export const shouldShowDownload = (status, url) => {
	if (status === 'ready') {
		return true;
	} else {
		return false;
	}
};

// Determine the operating system platform
export const getPlatformByExtension = fileExtension => {
	if (fileExtension === 'exe') {
		return 'Windows';
	} else if (fileExtension === 'dmg') {
		return 'Mac';
	} else if (fileExtension === 'deb') {
		return 'Debian';
	} else if (fileExtension === 'rpm') {
		return 'Redhat';
	} else {
		return 'Unknown';
	}
};

export const getSecondDiffOfDates = (date1, date2) =>
	dayjs(date2).diff(dayjs(date1), 's');

export const getSecondDiffByNow = date =>
	getSecondDiffOfDates(date, new Date());

export const daysIntoSecs = days => dayjs.duration(days, 'days').asSeconds();
export const minutesIntoSecs = mins => mins * 60;

export function formatDate(date) {
	const currentDate = dayjs();
	const targetDate = dayjs(date);

	const diff = dayjs.duration(currentDate.diff(targetDate));

	if (diff.asSeconds() < 60) {
		return `${Math.round(diff.asSeconds())} sec`;
	} else if (diff.asMinutes() < 60) {
		return `${Math.round(diff.asMinutes())} min`;
	} else if (diff.asHours() < 24) {
		return `${Math.round(diff.asHours())} hr`;
	} else if (diff.asDays() < 7) {
		return `${Math.round(diff.asDays())} d`;
	} else if (diff.asWeeks() < 52) {
		return `${Math.round(diff.asWeeks())} w`;
	} else {
		return `${Math.round(diff.asYears())} yr`;
	}
}

export const getNodePosition = nodes => {
	if (nodes.length == 0) {
		return { x: 0, y: 50 };
	}
	let lastNode = nodes[nodes.length - 1];
	return { x: lastNode.position.x + 350, y: lastNode.position.y };
};

export const getRefactoredTechniqueResponseForStep = res => {
	let { id, Taggable, ...remainingProperties } = res;
	return remainingProperties;
};
