import {
	useContext,
	useEffect,
	useState,
	useLayoutEffect,
	Fragment
} from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import TemplateCreateUpdateComponent from './templates/TemplateCreateUpdateComponent';
import {
	getTempateById,
	updateTemplateService
} from 'services/templateService';
import {
	getMasterTemplateByName,
	updateTechniqueService
} from 'services/techniqueService';
import { StepContext, TestContext } from 'context/Context';
import { findIndexOfSteps } from 'helpers/utils';
import useError from 'hooks/useError';

export default function TemplateEdit() {
	const { getResponse } = useError();

	const { id: templateId } = useParams();
	const navigate = useNavigate();
	const {
		stepState: { edges, nodes },
		resetStep,
		addNodesToStep,
		addEdgesToStep
	} = useContext(StepContext);
	const { testState, addTest, resetTest } = useContext(TestContext);
	const [formData, setFormData] = useState({
		name: '',
		description: '',
		steps: [],
		status: 'draft',
		type: null
	});
	const [tags, setTags] = useState([]);

	const handleFieldChange = e => {
		const { id, value } = e.target;
		setFormData({ ...formData, [id]: value });
	};

	useLayoutEffect(() => {
		// let templateId = pathname.substring(pathname.lastIndexOf('/') + 1);
		getTemplateData(templateId);
	}, []);

	useEffect(() => {
		return () => {
			resetStep();
			resetTest();
		};
	}, []);

	const getTemplateData = async id => {
		const res = await getTempateById(id);
		await getResponse(res)
			.then(res => {
				setFormData({
					name: res.name,
					description: res.description,
					steps: [],
					status: res.status,
					type: res.type
				});
				let templateTags = res?.Taggable?.map(each => each?.tag).map(
					tag => tag?.name
				);
				setTags(templateTags);
				if (res.type === 'killchain') {
					handleSteps(res.steps);
				} else {
					handleTests(res.steps);
				}
			})
			.catch(err => console.error(err));
	};

	const handleTests = steps => {
		addTest(
			steps.map(step => {
				const { technique, edges, node, scenario, ...rest } = step;
				return { ...rest, stepId: step.id };
			})
		);
	};

	const handleSteps = async steps => {
		const newNodes = [];
		const edges = [];
		for (const [index, step] of steps.entries()) {
			if (step.edges) {
				step.edges.forEach(ed => {
					edges.push(ed);
				});
			}
			let node = {
				id: step.node.id || String(index),
				position:
					step.node.position ||
					getNodePosition([...nodes, ...newNodes])
			};
			const masterConfigRes = await getMasterTemplateByName(
				step.technique.name
			);
			await getResponse(masterConfigRes)
				.then(res => {
					const {
						createdAt,
						deletedAt,
						updatedAt,
						...masterResponse
					} = getRefactoredTechniqueResponse(masterConfigRes);

					newNodes.push({
						...node,
						type: 'custom',
						data: {
							number: nodes.length + newNodes.length + 1,
							name: step.name,
							description: step.description,
							techniqueId: step.techniqueId,
							extraConfig: {
								formSchema: step.technique?.formSchema
							},
							masterConfig: masterResponse,
							action: 'play',
							detailStatus: 'noaction',
							status: 'noaction',
							stepId: step.id,
							tags:
								step?.technique?.Taggable?.map(
									each => each?.tag
								).map(tag => tag?.name) || [],
							index: step.index,
							successStepId: step.successStepId,
							failureStepId: step.failureStepId
						}
					});
					if (newNodes.length == 1) {
						newNodes[0].sourcePosition = 'right';
					}
				})
				.catch(err => console.error(err));
		}
		addNodesToStep(newNodes);
		addEdgesToStep(edges);
	};

	const handleSubmit = async () => {
		if (formData.type === 'killchain') {
			updateByKillchain();
		} else {
			let testsWithIdNonId = testState.tests.map(test => {
				const { id, stepId, ...rest } = test;
				let newObj = { ...rest };
				if (stepId) {
					newObj.id = stepId;
				}
				return newObj;
			});
			updateTemplate(testsWithIdNonId);
		}
	};
	const updateByKillchain = async () => {
		const { steps, techniques } = refactorNodesEdgesIntoStepsAndTechniques(
			nodes,
			edges
		);
		for (const tech of techniques) {
			const techRes = await updateTechniqueService(tech.id, {
				formSchema: tech?.formSchema
			});
			await getResponse(techRes).catch(err => console.error(err));
		}
		updateTemplate(steps);
	};

	const updateTemplate = async steps => {
		const templateData = {
			...formData,
			steps: steps
		};
		const res = updateTemplateService(templateId, templateData);
		await getResponse(res)
			.then(res => {
				toast(
					<span className="text-success">
						Template edited successfully!
					</span>
				);
				resetStep();
				navigate('/templates');
			})
			.catch(err => console.error(err));
	};

	return (
		<Fragment>
			<TemplateCreateUpdateComponent
				title={'Edit Template'}
				submitButtonText={'Save Template'}
				onSubmit={handleSubmit}
				data={formData}
				handleFieldChange={handleFieldChange}
				tags={tags}
				handleTags={setTags}
				mode="edit"
			/>
		</Fragment>
	);
}

const refactorNodesEdgesIntoStepsAndTechniques = (nodesData, edgesData) => {
	const newNodes = findIndexOfSteps(nodesData, edgesData);
	let techniques = [];
	let steps = [];
	newNodes.forEach(node => {
		const {
			name,
			description,
			action,
			detailStatus,
			status,
			techniqueId,
			masterConfig,
			extraConfig,
			stepId,
			index
		} = node.data;
		const edges = edgesData.filter(e => e.source === node.id);
		let successTechniqueId = null;
		let failureTechniqueId = null;
		edges.forEach(e => {
			let sourceNode = nodesData.find(n => n.id === e.target);
			if (e.className === 'success') {
				successTechniqueId = sourceNode.data.techniqueId;
			} else {
				failureTechniqueId = sourceNode.data.techniqueId;
			}
		});
		let newStep = {
			name,
			description,
			techniqueId,
			successTechniqueId,
			failureTechniqueId,
			node: {
				id: node.id,
				position: node.position
			},
			edges: edges,
			index
		};
		if (stepId) {
			newStep.id = stepId;
		}
		steps.push(newStep);
		techniques.push({
			id: techniqueId,
			...masterConfig,
			...extraConfig
		});
	});
	return { steps, techniques };
};

const getNodePosition = nodes => {
	if (nodes.length == 0) {
		return { x: 0, y: 50 };
	}
	let lastNode = nodes[nodes.length - 1];
	return { x: lastNode.position.x + 350, y: lastNode.position.y };
};

const getRefactoredTechniqueResponse = res => {
	let {
		id,
		Taggable,
		createdAt,
		updatedAt,
		deletedAt,
		tacticId,
		tacticID,
		...remainingProperties
	} = res;
	return remainingProperties;
};
