export const pagesRoutes = {
	label: 'pages',
	labelDisable: true,
	children: [
		{
			name: 'Dashboard',
			icon: 'chart-pie',
			to: '/dashboard',
			active: true
		},
		{
			name: 'Templates',
			icon: 'users',
			to: '/templates',
			active: true
		},
		{
			name: 'Techniques',
			icon: 'user',
			to: '/techniques',
			active: true
		},
		{
			name: 'Organisations',
			icon: 'user',
			to: '/orgs',
			active: true
		}
	]
};

export default [pagesRoutes];
