export const authReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isLogedIn: true
      };
    case 'RESET_DATA':
      return {
        isLogedIn: false
      };
    default:
      return state;
  }
};
