import React, { useContext } from 'react';
import { FiPlus } from 'react-icons/fi';
import { AddStepContext } from 'context/Context';

export default function AddStepButton() {
  const { toggleModal } = useContext(AddStepContext);

  return (
    <div className="position-absolute bottom-0 end-0">
      <div
        className="rounded-circle bg-dark text-white d-flex align-items-center justify-content-center me-2 mb-2 hover-pointer"
        style={{ width: 42, height: 42 }}
        onClick={toggleModal}
      >
        <FiPlus size={18} />
      </div>
    </div>
  );
}
