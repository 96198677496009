import React, { useState } from 'react';
import AddStep from './AddStep';
import EditStep from './EditStep';
import FlowDiagram from './FlowDiagram';
import AddStepButton from './AddStepButton';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { rgbaColor } from 'helpers/utils';
import { getColor } from 'helpers/utils';
import { useContext } from 'react';
import { AddStepContext, StepContext } from 'context/Context';
import { ReactFlowProvider } from 'reactflow';
import DeleteConfirmModal from 'components/common/DeleteConfirmModal';
import { deleteStepService } from 'services/templateService';
import useError from 'hooks/useError';

export default function AssessmentSetupKillChain() {
	const { getResponse } = useError();
	const [isDeleting, setDeleting] = useState(false);
	const {
		stepState: { nodes, edges, selectedDeleteStep },
		updateDeleteStep,
		handleDeleteNode
	} = useContext(StepContext);
	console.log(nodes, 'jads');
	const handleDeleteStep = async () => {
		setDeleting(true);
		const node = nodes.find(n => n.id === selectedDeleteStep);
		if (node.data?.stepId) {
			const res = await deleteStepService(node.data.stepId);

			getResponse(res)
				.then(res => {
					handleDeleteNode(node.id);
					setDeleting(false);
					updateDeleteStep(null);
				})
				.catch(err => {
					setDeleting(false);
				});
		}
	};

	return (
		<React.Fragment>
			<div className="position-relative" style={{ height: 600 }}>
				{/* <FlowDiagram /> */}
				<ReactFlowProvider>
					<FlowDiagram flowNodes={nodes} flowEdges={edges} />
				</ReactFlowProvider>
				<AddStepButton />
				{renderNoStepOverlay(nodes.length)}
			</div>
			<AddStep />
			<EditStep />
			<DeleteConfirmModal
				show={selectedDeleteStep != null}
				handleClose={() => updateDeleteStep(null)}
				isLoading={isDeleting}
				message={'Are you sure you want to delete this step?'}
				onDelete={handleDeleteStep}
			/>
		</React.Fragment>
	);
}

const renderNoStepOverlay = stepLength => {
	const { toggleModal } = useContext(AddStepContext);
	if (stepLength === 0) {
		return (
			<div
				className="position-absolute top-0 bottom-0 start-0 end-0"
				style={{ backgroundColor: rgbaColor(getColor('dark')) }}
			>
				<Flex className="justify-content-center align-items-center flex-column h-100">
					<div className="text-white fw-medium fs-1 mb-3">
						This assessment doesn't have any steps. Please create
						steps to start.
					</div>
					<IconButton icon="plus" onClick={toggleModal}>
						<span className="ms-2">Create Step</span>
					</IconButton>
				</Flex>
			</div>
		);
	}
};
