import Ajv from 'ajv';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import React, { useEffect, useRef, useState } from 'react';

export default function JSONEditor({ json, handleChange }) {
  const ajv = new Ajv({ allErrors: true, verbose: true });
  //   console.log('dsfds', json);
  //   const [jsontext, setJSON] = useState({});
  //   useEffect(() => {
  //     setJSON(json);
  //   }, [json]);

  //   const onHandle = value => {
  //     setJSON(value);
  //     handleChange(value);
  //   };
  const jsonEditorRef = useRef();
  useEffect(() => {
    const editor =
      jsonEditorRef &&
      jsonEditorRef.current &&
      jsonEditorRef.current.jsonEditor;
    if (editor && json) {
      editor.update(json);
    }
  }, [jsonEditorRef, json]);
  return (
    <React.Fragment>
      <Editor
        ref={jsonEditorRef}
        key={json}
        value={json}
        onChange={handleChange}
        ajv={ajv}
        // schema={yourSchema}
        mode="text"
        navigationBar={false}
        statusBar={false}
        htmlElementProps={{
          style: {
            height: '420px'
          },
          className: 'customJsonEditor rounded overflow-hidden'
        }}
      />
      {/* <Editor value={jsontext} onChange={onHandle} mode="code" /> */}
    </React.Fragment>
  );
}
