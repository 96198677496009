import CloseButton from 'components/common/CloseButton';
import SelectBox from 'components/common/SelectBox';
import { TestContext } from 'context/Context';
import React, { memo, useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

function GroupModal({ title, defaultName, open, handleClose, onSubmit }) {
	const { testState } = useContext(TestContext);
	const [name, setName] = useState(defaultName);
	const [selectName, setSelectName] = useState();
	const handleSubmit = () => {
		onSubmit(name);
		handleClose();
	};

	useEffect(() => {
		setName(selectName?.value);
	}, [selectName]);

	const groups = [...new Set(testState.tests.map(item => item.group))];
	const refactoredGroups = groups.map(group => ({
		value: group,
		label: group
	}));

	console.log(name);
	return (
		<React.Fragment>
			<Modal
				show={open}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				size="xs"
			>
				<Modal.Header>
					<Modal.Title>{title}</Modal.Title>
					<CloseButton onClick={handleClose} />
				</Modal.Header>
				<Modal.Body>
					<SelectBox
						title={'Name'}
						options={refactoredGroups}
						value={selectName}
						onChange={setSelectName}
					/>
					<div className="my-3 text-center">OR</div>
					<div>
						<Form.Control
							type="text"
							placeholder="Enter new name..."
							value={name}
							onChange={({ target }) => setName(target.value)}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="falcon-default"
						className="py-2 px-4"
						onClick={handleClose}
						size="sm"
					>
						Cancel
					</Button>
					<Button
						variant="primary"
						size="sm"
						className="py-2 px-4"
						onClick={handleSubmit}
					>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		</React.Fragment>
	);
}

export default memo(GroupModal);
