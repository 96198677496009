import React from 'react';
import { useLayoutEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';

export default function useDimention() {
  const ref = useRef(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetHeight);
  }, []);
  return { ref, width, height };
}
