import React, { memo, useCallback, useContext, useEffect } from 'react';
import { Handle, Position } from 'reactflow';
import { Form } from 'react-bootstrap';
import { StepContext } from 'context/Context';
import TagView from 'components/common/TagView';
import classNames from 'classnames';
import { BiMessageSquareEdit } from 'react-icons/bi';
import { MdDeleteOutline } from 'react-icons/md';

const HANDLE_POSITION = {
	right: Position.Right,
	left: Position.Left,
	bottom: Position.Bottom,
	top: Position.Top
};

export default memo(({ id, data, isConnectable, yPos, xPos }) => {
	const { showStepData, updateDeleteStep } = useContext(StepContext);

	const handleStepClick = () => {
		showStepData(id);
	};
	const onStepDelete = () => {
		showStepData(null);
		updateDeleteStep(id);
	};
	return (
		<>
			<div>
				<div className="node-tag text-info bg-200 d-inline-flex align-items-center py-1 px-2">
					<div className="fs--1 text-uppercase fw-semi-bold">
						Step {data.number}
					</div>
					<div className="mx-2">
						<BiMessageSquareEdit onClick={handleStepClick} />
					</div>
					<div className="me-2">
						<MdDeleteOutline onClick={onStepDelete} />
					</div>
				</div>
				<div
					className={classNames(
						'reactflow_custom_node bg-white position-relative p-2 shadow rounded',
						{
							'bg-soft-danger': data.detailStatus === 'pending',
							'opacity-50': data.status === 'not-proccessed'
						}
					)}
				>
					<Form.Control
						type="text"
						className="bg-transparent p-0 outline-none border-0"
						placeholder="Add Name"
						disabled
						value={data.name}
					/>
					<div className="line-clamp-2 mt-2">
						{data.description ? (
							<p
								className="fs--1 fw-medium text-black"
								title={data.description}
							>
								{data.description}
							</p>
						) : (
							<p className="fs--1 fw-medium text-400">
								Add description
							</p>
						)}
					</div>
					<div className="mt-3">
						<TagView data={data?.tags || []} maxTagShow={1} />
					</div>
				</div>
			</div>
			<>
				{[
					0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
					17, 18, 19, 20
				].map(i => (
					<Handle
						key={'target_left' + i}
						type="target"
						position={HANDLE_POSITION['left']}
						className="sourceConnectLeftLine custom"
						style={{
							top: 35 + 6 * i
						}}
						id={'l' + String(i)}
						isConnectableStart={false}
						isConnectableEnd={true}
					/>
				))}
			</>
			<>
				{[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
					i => (
						<Handle
							key={'target_bottom' + i}
							type="target"
							position={HANDLE_POSITION['bottom']}
							className="sourceConnectBottomtLine custom"
							style={{
								left: 3 + 6 * i
							}}
							id={'bs' + String(i)}
							isConnectableStart={false}
							isConnectableEnd={true}
						/>
					)
				)}
			</>
			<>
				{[
					0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
					17, 18, 19, 20
				].map(i => (
					<Handle
						key={'target_top' + i}
						type="target"
						position={HANDLE_POSITION['top']}
						className="sourceConnectToptLine custom"
						style={{
							left: 3 + 6 * i
						}}
						id={'ts' + String(i)}
						isConnectableStart={false}
						isConnectableEnd={true}
					/>
				))}
			</>
			<>
				{[
					0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
					17, 18, 19, 20
				].map(i => (
					<Handle
						key={'source_right' + i}
						type="source"
						position={HANDLE_POSITION['right']}
						className="left-0 targetConnectRightLine custom"
						style={{
							top: 35 + 6 * i
						}}
						id={'r' + String(i)}
						isConnectableStart={true}
						isConnectableEnd={false}
					/>
				))}
			</>
			<>
				{[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
					i => (
						<Handle
							key={'source_bottom' + i}
							type="source"
							position={HANDLE_POSITION['bottom']}
							className="targetConnectBottomtLine custom"
							style={{
								right: -3 + 6 * i
							}}
							id={'bt' + String(i)}
							isConnectableStart={true}
							isConnectableEnd={false}
						/>
					)
				)}
			</>
			<>
				{[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i => (
					<Handle
						key={'source_top' + i}
						type="source"
						position={HANDLE_POSITION['top']}
						className="targetConnectTopLine custom"
						style={{
							right: -3 + 6 * i
						}}
						id={'tt' + String(i)}
						isConnectableStart={true}
						isConnectableEnd={false}
					/>
				))}
			</>
		</>
	);
});
