import React, { useContext } from 'react';
import SimpleBarReact from 'simplebar-react';
import { Table, Form } from 'react-bootstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const OrgListTableRow = ({ item, isLast }) => {
	return (
		<tr>
			<td
				className={classNames(
					'align-middle font-sans-serif fw-medium text-nowrap',
					{
						'border-bottom-0': isLast
					}
				)}
			>
				<div className="d-flex">
					<Link to={`/orgs/${item.id}`}>
						<span className="text-700 ms-1">{item.id}</span>
					</Link>
				</div>
			</td>
			<td
				className={classNames('align-middle', {
					'border-bottom-0': isLast
				})}
			>
				{item.name}
			</td>
			<td
				className={classNames('align-middle text-center', {
					'border-bottom-0': isLast
				})}
			>
				{item.description}
			</td>
		</tr>
	);
};
const OrgListTable = ({ data }) => {
	return (
		<SimpleBarReact>
			<Table className="fs--1 mb-0">
				<thead className="project_table_header text-800 py-3">
					<tr>
						<th className="text-nowrap pt-4 pb-3">ID</th>
						<th className="text-nowrap pt-4 pb-3">Name</th>
						<th className="text-nowrap pt-4 pb-3 text-center">
							Description
						</th>
					</tr>
				</thead>
				<tbody>
					{data.map((item, index) => (
						<OrgListTableRow
							key={item.id}
							item={item}
							isLast={data.length - 1 === index}
						/>
					))}
				</tbody>
			</Table>
		</SimpleBarReact>
	);
};

export default OrgListTable;
