import { useContext } from 'react';
import { Form, Table } from 'react-bootstrap';
import { GiQueenCrown } from 'react-icons/gi';
import ContentLoader from 'react-content-loader';

import TagView from 'components/common/TagView';
import useTechnique from 'hooks/useTechnique';
import { AddStepContext, EditStepContext, StepContext } from 'context/Context';
import usePagination from 'hooks/usePagination';

export default function ScenarioTable() {
	const { techniques, isFetching, getAllTechniques } = useTechnique();

	const { Pagination } = usePagination(getAllTechniques);

	return (
		<>
			<Table className="fs--1 mb-0" borderless>
				<thead className="bg-300 text-800">
					<tr className="">
						<th className="text-nowrap "></th>
						<th className="text-nowrap"></th>
						<th className="text-nowrap ">Scenario name</th>
						<th className="text-nowrap ">Status</th>
						<th className="text-nowrap ">Published by</th>
						<th className="text-nowrap">Tags</th>
						{/* <th className="text-nowrap text-end ">Actions</th> */}
					</tr>
				</thead>
				<tbody className="bg-white">
					<RenderScenarioTableRows
						techniques={techniques}
						isFetching={isFetching}
					/>
					{/* {renderScenarioTableRows(techniques, isFetching)} */}
				</tbody>
			</Table>
			{techniques?.total_pages ? (
				<Pagination totalPages={techniques.total_pages} />
			) : null}
		</>
	);
}

const RenderScenarioTableRows = ({ techniques, isFetching }) => {
	const {
		stepState: { scenarioType }
	} = useContext(StepContext);
	if (isFetching || scenarioType == null) {
		return (
			<tr>
				<td className="px-0 py-1" colSpan={6}>
					<ContentLoader
						width={'100%'}
						height={163}
						foregroundColor="#dedede"
					>
						<rect x="0" y="0" width={'100%'} height="40" />
						<rect x="0" y="41" width={'100%'} height="40" />
						<rect x="0" y="82" width={'100%'} height="40" />
						<rect x="0" y="123" width={'100%'} height="40" />
					</ContentLoader>
				</td>
			</tr>
		);
	}
	if (!techniques || !techniques?.data || techniques?.data?.length == 0) {
		return (
			<tr>
				<td
					colSpan="6"
					className="text-center text-muted fs-medium fs-0"
				>
					No results found
				</td>
			</tr>
		);
	}
	return techniques?.data?.map(tech => (
		<tr key={tech.id}>
			<td className="text-nowrap ">
				<RenderInputBox scenarioType={scenarioType} tech={tech} />
				{/* {renderInputBox(scenarioType, tech)} */}
			</td>
			<td className="text-nowrap ">
				<GiQueenCrown size={20} />
			</td>
			<td className="text-break ">{tech.name}</td>
			<td className="text-nowrap "></td>
			<td className="text-nowrap ">PSE</td>
			<td className="text-nowrap ">
				<TagView
					data={
						tech?.Taggable?.map(each => each?.tag).map(
							tag => tag?.name
						) || []
					}
					maxTagShow={3}
				/>
			</td>
			{/* <td className="text-nowrap  text-end">
				<CardDropdown drop="start">
					<div className="py-2">
						<Dropdown.Item href="#!">View</Dropdown.Item>
						<Dropdown.Item href="#!">Edit</Dropdown.Item>
					</div>
				</CardDropdown>
			</td> */}
		</tr>
	));
};

const RenderInputBox = ({ scenarioType, tech }) => {
	const {
		editStepState: { selectedScenario },
		updateScenario
	} = useContext(EditStepContext);
	const { updateScenarios, addStepState } = useContext(AddStepContext);
	const isSelected = id => {
		return (
			addStepState.selectedScenarios.findIndex(scn => scn?.id == id) > -1
		);
	};
	if (scenarioType === 'add') {
		return (
			<Form.Check
				type="checkbox"
				id="add_steps_scenario"
				name="scenario"
				checked={isSelected(tech?.id)}
				onChange={() => updateScenarios(tech)}
			/>
		);
	}
	return (
		<Form.Check
			type="radio"
			id="edit_scenario"
			name="scenario"
			checked={selectedScenario?.id == tech?.id}
			onChange={() => updateScenario(tech)}
		/>
	);
};
