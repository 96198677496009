import React from 'react';
import Flex from './Flex';

export default function QuickAnalysisCard({ imgUrl, title, count }) {
  return (
    <Flex
      alignItems="center"
      className="px-4 bg-white rounded-3 border position-relative"
    >
      <img src={imgUrl} alt="" height="40" />
      <div className="ms-3 my-x1">
        <h6 className="fs-0 fw-semi-bold mb-2">
          <a className="text-900">{title}</a>
        </h6>
        <h5 className="mb-0 fw-semi-bold text-primary">{count}</h5>
      </div>
    </Flex>
  );
}
