import CardHeader from 'components/common/CardHeader';
import Treemap from 'components/common/TreeMap';
import useDimention from 'hooks/useDimention';
import React from 'react';
import { useState } from 'react';
import { useLayoutEffect } from 'react';
import { useRef } from 'react';
import { Card } from 'react-bootstrap';

const data = {
  name: 'Celtics',
  children: [
    {
      name: 'Guards',
      children: [
        {
          category: 'Guards',
          name: 'Kemba Walker',
          value: 20.4
        }
      ]
    },
    {
      name: 'p2',
      children: [
        {
          category: 'p2',
          name: 'ba Walker',
          value: 60.4
        }
      ]
    },
    {
      name: 'p3',
      children: [
        {
          category: 'p3',
          name: 'ba Walker',
          value: 30.6
        }
      ]
    }
  ]
};

export default function ProjectsSnapshot() {
  const { ref, width, height } = useDimention();

  return (
    <Card>
      <CardHeader
        title="Tasks by category"
        titleTag="h5"
        className="border-200 border-bottom py-3"
      />
      <Card.Body>
        <div class="w-100" style={{ height: 350 }} ref={ref}>
          {width > 0 && height > 0 && (
            <Treemap width={width} height={height} data={data} />
          )}
        </div>
      </Card.Body>
    </Card>
  );
}
