import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDebounce } from 'use-debounce';

import Flex from 'components/common/Flex';
import SelectBox from 'components/common/SelectBox';
import TagBoxAnimated from 'components/common/TagBoxAnimated';
import SearchBox from 'components/navbar/top/SearchBox';
import { TechniqueContext } from 'context/Context';

let osData = [
	{ value: '', label: 'Select' },
	{ value: 'windows', label: 'Windows' },
	{ value: 'macos', label: 'Mac' },
	{ value: 'debian', label: 'Debian' },
	{ value: 'redhat', label: 'Redhat' }
];

export default function StepForm() {
	const { updateTechniqueFilters } = useContext(TechniqueContext);
	const [search, setSearch] = useState('');
	const [debouncedText] = useDebounce(search, 1000);
	const [selectedEnv, setEnv] = useState(null);
	const [tags, setTags] = useState([]);

	useEffect(() => {
		updateTechniqueFilters(debouncedText, selectedEnv?.value, tags);
	}, [debouncedText, selectedEnv, tags]);

	return (
		<div>
			<Row>
				<Col md={4} className="pe-0">
					<Flex>
						<SearchBox
							inputClass="bg-transparent border-0 border-bottom rounded-0"
							searchInputValue={search}
							setSearchInputValue={setSearch}
						/>
					</Flex>
				</Col>
				<Col md={8} className="pe-0">
					<TagBoxAnimated
						label={'Tags'}
						id="tag_search_box"
						tags={tags}
						updateTags={setTags}
						key={tags}
					/>
				</Col>
			</Row>
			<Row className="my-3">
				<Col md={6} className="pe-0">
					<SelectBox
						title={'Environment'}
						options={osData}
						value={selectedEnv}
						onChange={setEnv}
					/>
				</Col>
				<Col md={6} className="pe-0">
					<SelectBox
						title={'Tactics'}
						options={[{ value: '', label: 'Select' }]}
					/>
				</Col>
			</Row>
		</div>
	);
}
