import React from 'react';
import { Card } from 'react-bootstrap';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  DatasetComponent,
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import CardHeader from 'components/common/CardHeader';
import Flex from 'components/common/Flex';
import { getColor } from 'helpers/utils';
import useDimention from 'hooks/useDimention';
import { useRef } from 'react';
import { useEffect } from 'react';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  DatasetComponent
]);

const getOption = data => ({
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: function (params) {
      return `<div class="text-center"><div class="fs--1 text-600">
        <strong>${params.value}</strong></div>
        <small class="text-500">Tasks</small>
        </div>`;
    }
  },
  xAxis: {
    type: 'category',
    data: data.week.cates,
    axisLabel: { color: getColor('gray-400') },
    axisLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    axisTick: false,
    boundaryGap: true
  },
  yAxis: {
    axisPointer: { type: 'none' },
    axisTick: 'none',
    splitLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    axisLine: { show: false },
    axisLabel: { color: getColor('gray-400') },
    min: 0
  },
  series: [
    {
      type: 'bar',
      data: data.week.data,
      barWidth: '10px',
      barGap: '30%',
      label: { show: false },
      z: 10,
      itemStyle: {
        borderRadius: [10, 10, 0, 0],
        color: getColor('primary')
      }
    }
  ],
  grid: { right: 0, left: 0, bottom: 0, top: 32, containLabel: true }
});

export const topProducts = {
  week: {
    cates: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    data: [5100, 7500, 18000, 6000, 41000, 21000, 3500]
  }
};

export default function VulnerabilitiesSnapshot() {
  const { ref, width } = useDimention();
  return (
    <Card>
      <CardHeader
        title="Vulnerabilities Snapshot (Top 10)"
        titleTag="h5"
        className="py-3 border-bottom"
        titleClass="text-black"
      />
      <Card.Body>
        <div>
          <Flex className="justify-content-center">
            <div
              style={{ backgroundColor: '#F2F2F2' }}
              className="py-2 px-4 text-center rounded-3"
            >
              <div className="fs--1">Total</div>
              <p className="text-black fw-medium mb-0">826 Detected</p>
            </div>
          </Flex>
        </div>
        <div className="position-relative w-100" ref={ref}>
          {width > 0 && (
            <ReactEChartsCore
              // ref={chartRef}
              echarts={echarts}
              option={getOption(topProducts)}
              style={{ height: '100%', minHeight: '17.75rem', width: width }}
            />
          )}
        </div>
      </Card.Body>
    </Card>
  );
}
