import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import Dashboard from 'components/pages/Dashboard';
import Templates from 'components/pages/Templates';
import Login from 'components/pages/auth/login';
import Techniques from 'components/pages/Techniques';
import TemplateCreate from 'components/pages/TemplateCreate';
import TemplateEdit from 'components/pages/TemplateEdit';
import TechniqueCreate from 'components/pages/TechniqueCreate';
import TechniqueEdit from 'components/pages/TechniqueEdit';
import OrgDetail from 'components/pages/OrgDetail';
import Orgs from 'components/pages/Orgs';
import CreateOrg from 'components/pages/CreateOrg';
import Notifications from 'components/pages/Notifications';
import AssessmentFlowView from 'components/pages/AssessmentFlowView';

const FalconRoutes = () => {
	return (
		<Routes>
			<Route element={<ErrorLayout />}>
				<Route path="/" element={<Login />} />
				<Route path="errors/404" element={<Error404 />} />
				<Route path="errors/500" element={<Error500 />} />
			</Route>
			{/* //--- MainLayout Starts  */}

			<Route element={<MainLayout />}>
				{/*Dashboard*/}
				<Route path="/dashboard" element={<Dashboard />} />
				<Route path="/templates" element={<Templates />} />
				<Route path="/template/create" element={<TemplateCreate />} />
				<Route path="/template/edit/:id" element={<TemplateEdit />} />
				<Route path="/techniques" element={<Techniques />} />
				<Route path="/technique/create" element={<TechniqueCreate />} />
				<Route path="/technique/edit/:id" element={<TechniqueEdit />} />
				<Route path="/orgs" element={<Orgs />} />
				<Route path="/orgs/new" element={<CreateOrg />} />
				<Route path="/orgs/:org_id" element={<OrgDetail />} />
				<Route path="/notifications" element={<Notifications />} />
				<Route
					path="/assessment/:id"
					element={<AssessmentFlowView />}
				/>
			</Route>

			{/* //--- MainLayout end  */}

			{/* <Navigate to="/errors/404" /> */}
			<Route path="*" element={<Navigate to="/errors/404" replace />} />
		</Routes>
	);
};

export default FalconRoutes;
