import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = data => ({
  color: [getColor('warning'), getColor('info')],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: function (params) {
      return `<strong>${params.value}</strong> `;
    }
  },
  legend: {
    left: 'right',
    data: ['Tasks Failed', 'Tasks Succeed'],
    textStyle: {
      color: getColor('gray-700')
    },
    itemWidth: 16,
    itemHeight: 2,
    itemGap: 48,
    icon: 'roundRect',
    inactiveColor: getColor('gray-400')
  },
  calculable: true,
  xAxis: {
    type: 'category',
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      name: 'Tasks Failed',
      data: [820, 932, 901, 934, 1290, 830, 1320],
      type: 'line',
      smooth: true
    },
    {
      name: 'Tasks Succeed',
      data: [840, 602, 931, 1034, 1290, 530, 1320],
      type: 'line',
      smooth: true
    }
  ],
  grid: { left: 0, bottom: 0, right: 0, top: 32, containLabel: true }
});

const TaskSummaryLineChart = ({ data }) => {
  const chartRef = useRef(null);

  return (
    <div className="position-relative py-2">
      <ReactEChartsCore
        ref={chartRef}
        echarts={echarts}
        option={getOptions(data)}
        style={{ height: '20rem', width: 'inherit' }}
      />
    </div>
  );
};

export default TaskSummaryLineChart;
