import CloseButton from 'components/common/CloseButton';
import TagBox from 'components/common/TagBox';
import { getAgentStatusByRefactor, getDateInFormatOfDMY } from 'helpers/utils';
import useError from 'hooks/useError';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateAgentService } from 'services/agentService';
import { attachTagsService } from 'services/tagService';

let STATUS_LABEL = {
	building: 'NA',
	ready: 'Alive',
	dead: 'Dead',
	inactive: 'Inactive',
	undefined: 'NA' //handling for status otherthan this
};

export default function AssetShowEditModal({ data, show, handleClose }) {
	const { getResponse } = useError();
	const navigate = useNavigate();
	const location = useLocation();
	const [formData, setFormData] = useState({
		name: data?.name || '',
		tags: []
	});

	useEffect(() => {
		let agentTags = data?.Taggable?.map(each => each?.tag).map(
			tag => tag?.name
		);
		handleTags(agentTags);
	}, [data]);

	const handleEdit = ({ target }) => {
		let { id, value } = target;
		setFormData({ ...formData, [id]: value });
	};

	const handleTags = values => {
		console.log(values);
		setFormData({ ...formData, tags: [...values] });
	};

	const handleSaveAgent = async () => {
		if (formData.name != data?.name) {
			let _data = { name: formData.name };
			const res = await updateAgentService(data?.id, _data);

			getResponse(res)
				.then(async res => {
					toast(
						<span className="text-success">
							Agent details updated successfully.
						</span>
					);
				})
				.catch(err => console.error(err));
		}
		const tagRes = await attachTagsService({
			names: formData.tags,
			taggableName: 'Agent',
			taggableId: data?.id
		});
		getResponse(tagRes)
			.then(async res => {
				toast(
					<span className="text-success">
						Agent Tags updated successfully.
					</span>
				);
			})
			.catch(err => console.error(err));
	};

	let status = getAgentStatusByRefactor(data.status, data.lastSeen);
	let statusLabel = STATUS_LABEL[status];
	return (
		<>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				size="lg"
			>
				<Modal.Header>
					<Modal.Title>Agent Details</Modal.Title>
					<CloseButton onClick={handleClose} />
				</Modal.Header>
				<Modal.Body>
					{/* <h5 className="text-black">Settings</h5> */}
					<div className="my-4">
						<Form.Group
							as={Row}
							className="mb-3"
							controlId="formHorizontalEmail"
						>
							<Form.Label column sm={4}>
								Name*
							</Form.Label>
							<Col sm={8}>
								<Form.Control
									type="text"
									id="name"
									value={formData.name}
									onChange={handleEdit}
									placeholder="e.g. Linear"
								/>
							</Col>
						</Form.Group>

						<Row>
							<Col style={{ height: 36 }}>
								<TagBox
									labelClass="text-900 fw-semi-bold"
									label={'Agent Tags'}
									placeholder={'Agent Tags'}
									id="agent_tag_box"
									tags={formData.tags}
									updateTags={handleTags}
									key={formData.tags}
								/>
							</Col>
						</Row>
					</div>
					<Button
						variant="primary"
						className="w-100 py-2 px-4"
						onClick={handleSaveAgent}
					>
						Save
					</Button>
					<hr class="my-4 text-primary border-top-2" />
					<h5 className="text-black">Agent Details</h5>
					<div className="my-4">
						<Row>
							<Col md={4} lg={3} className="text-end">
								Status
							</Col>
							<Col md={8} lg={9}>
								{statusLabel}
							</Col>
						</Row>

						<hr class="my-2" />
						<Row>
							<Col md={4} lg={3} className="text-end">
								Host
							</Col>
							<Col md={8} lg={9}>
								{data?.meta?.computerName}
							</Col>
						</Row>
						<hr class="my-2" />
						<Row>
							<Col md={4} lg={3} className="text-end">
								Name
							</Col>
							<Col md={8} lg={9}>
								{data?.name}
							</Col>
						</Row>
						<hr class="my-2" />
						<Row>
							<Col md={4} lg={3} className="text-end">
								Privilege
							</Col>
							<Col md={8} lg={9}>
								{data?.privilege}
							</Col>
						</Row>
						<hr class="my-2" />
						<Row>
							<Col md={4} lg={3} className="text-end">
								Last Seen
							</Col>
							<Col md={8} lg={9}>
								{data?.lastSeen &&
									getDateInFormatOfDMY(data?.lastSeen)}
							</Col>
						</Row>
						<hr class="my-2" />
						<Row>
							<Col md={4} lg={3} className="text-end">
								Created
							</Col>
							<Col md={8} lg={9}>
								{getDateInFormatOfDMY(data?.createdAt)}
							</Col>
						</Row>
						<hr class="my-2" />
						<Row>
							<Col md={4} lg={3} className="text-end">
								Platform{' '}
							</Col>
							<Col md={8} lg={9}>
								{data?.platform}
							</Col>
						</Row>
						<hr class="my-2" />
						<Row>
							<Col md={4} lg={3} className="text-end">
								PID{' '}
							</Col>
							<Col md={8} lg={9}>
								{data?.pid}
							</Col>
						</Row>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="falcon-default"
						className="py-2 px-4"
						onClick={handleClose}
					>
						Cancel
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
