import React, { useContext } from 'react';
import AddEditStep from '../killchains/AddEditStep';
import { EditStepContext, TestContext } from 'context/Context';

export default function EditTestStep() {
	const {
		editStepState: { showModal, selectedScenario },
		toggleModal,
		resetEditStep
	} = useContext(EditStepContext);
	const {
		testState: { selectedTest, tests },
		updateTestDataProps
	} = useContext(TestContext);

	const handleEditStep = () => {
		const data = { techniqueId: selectedScenario.id };
		if (Object.keys(selectedScenario).length > 1) {
			data.name =
				selectedScenario.name ?? selectedScenario?.techniqueName;
			data.description =
				selectedScenario.description ??
				selectedScenario?.techniqueDescription;
		}
		console.log(data, 'data');

		// update the success/failure in last step if exist
		let indexOfPrevStepOfSelectedTest = tests?.findIndex(
			t => t.id === selectedTest
		);
		if (indexOfPrevStepOfSelectedTest) {
			let prevTest = tests[indexOfPrevStepOfSelectedTest - 1];
			updateTestDataProps(prevTest?.id, {
				successTechniqueId: data.techniqueId,
				failureTechniqueId: data.techniqueId
			});
		}
		updateTestDataProps(selectedTest, data);
		resetEditStep();
	};

	console.log(selectedScenario, 'ss');

	return (
		<React.Fragment>
			<AddEditStep
				title="Change Step"
				show={showModal}
				handleClose={toggleModal}
				onSave={handleEditStep}
			/>
		</React.Fragment>
	);
}
