import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Flex from 'components/common/Flex'
import SearchBox from 'components/navbar/top/SearchBox'

function TechniqueFilters({search, setSearch}) {
  return (
    <Row className="align-items-end">
			<Col>
				<Flex>
					<SearchBox
						inputClass="bg-white border-bottom-0 rounded-0"
						searchContainerClasses="w-100"
						searchInputValue={search}
						setSearchInputValue={setSearch}
					/>
				</Flex>
			</Col>
      </Row>
  )
}

export default TechniqueFilters
