export const templateReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SAVE_ALL_TEMPLATE_DATA':
      return {
        ...state,
        templates: payload
      };
    case 'ADD_TEMPLATE':
      return {
        ...state,
        templates: { data: [payload, ...(state.templates?.data || [])] }
      };
    case 'REMOVE_TEMPLATE':
      return {
        ...state,
        templates: {
          data: state.templates.data.filter(temp => temp.id != payload)
        }
      };
    default:
      return state;
  }
};
