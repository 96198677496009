import Flex from 'components/common/Flex';
import React from 'react';
import { Button, Card, Col, Dropdown, Row } from 'react-bootstrap';
import iconLogo from 'assets/img/logos/brand_logo.png';
import Avatar from 'components/common/Avatar';
import CardDropdown from 'components/common/CardDropdown';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import PropTypes from 'prop-types';
import { BsPlayCircle } from 'react-icons/bs';
import { getDateInFormatOfDMY } from 'helpers/utils';
import { Link } from 'react-router-dom';
export default function TemplateDetailView({ data = [], onView }) {
	return (
		<React.Fragment>
			<Row className="px-2">
				{data.map(template => (
					<Col md={3} className="p-1">
						<Card className="rounded-0">
							<Flex className="bg-primary p-2">
								<div
									className="text-truncate fs--1 text-white fw-medium my-1"
									title={template.name}
								>
									{template.name}
								</div>
								<div className="ms-auto text-white">
									<CardDropdown
										drop="down"
										btnRevealClass="text-white"
									>
										<div className="py-2">
											<Dropdown.Item
												as={Link}
												to={`/template/edit/${template.id}`}
												href="#!"
											>
												Edit
											</Dropdown.Item>
										</div>
									</CardDropdown>
								</div>
							</Flex>
							<Card.Header className="p-1 px-2">
								<Flex className="align-items-center">
									<Avatar
										src={iconLogo}
										rounded="soft"
										size="2xl"
										className="shadow p-1 rounded me-2 bg-white"
									/>
									<div className="text-center ms-auto">
										{template?.tests && (
											<>
												<div className="fs--1 fw-medium">
													Test
												</div>
												<div className="fs-1 fw-semi-bold">
													{template.tests || 0}
												</div>
											</>
										)}
									</div>
									<div className="text-center ms-2">
										<div className="fs--1 fw-medium">
											Scenarios
										</div>
										<div className="fs-1 fw-semi-bold">
											{template.Steps || 0}
										</div>
									</div>
								</Flex>
							</Card.Header>
							<Card.Body className="p-0">
								<div className="bg-200 p-2">
									<Flex className={'align-items-center'}>
										<div>
											<div className="fs--2 fw-medium">
												by{' '}
												<span className="fw-semi-bold">
													PSE
												</span>
											</div>
											<div className="fs--2 fw-medium">
												Updated by:{' '}
												<span className="fw-semi-bold">
													{getDateInFormatOfDMY(
														template.updatedAt
													)}
												</span>
											</div>
										</div>
									</Flex>
								</div>
								<div className="p-2">
									<div className="line-clamp-3 ">
										<p
											className="fs--1  text-black"
											title={template.description}
										>
											{template.description}
										</p>
									</div>
								</div>
							</Card.Body>
							<Card.Footer className="p-2 pb-3 mt-3 position-relative border border-top">
								<div
									className="rounded-circle position-absolute bg-white shadow hover-pointer"
									style={{
										top: -2,
										right: 16,
										width: 24,
										height: 24,
										transform: 'translateY(-50%)'
									}}
								>
									<BsPlayCircle
										size={24}
										onClick={() => onView(template)}
									/>
								</div>
							</Card.Footer>
						</Card>
					</Col>
				))}
			</Row>
		</React.Fragment>
	);
}

TemplateDetailView.propTypes = {
	data: PropTypes.array.isRequired
};
