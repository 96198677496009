import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import is from 'is_js';
import Main from './Main';

import AppRoutes from 'routes';
import { CloseButton } from 'components/common/Toast';
import 'react-toastify/dist/ReactToastify.min.css';

const App = () => {
	const HTMLClassList = document.getElementsByTagName('html')[0].classList;

	useEffect(() => {
		if (is.windows()) {
			HTMLClassList.add('windows');
		}
		if (is.chrome()) {
			HTMLClassList.add('chrome');
		}
		if (is.firefox()) {
			HTMLClassList.add('firefox');
		}
		if (is.safari()) {
			HTMLClassList.add('safari');
		}
	}, [HTMLClassList]);

	return (
		<Router basename={process.env.PUBLIC_URL}>
			<Main>
				<AppRoutes />
				<ToastContainer
					closeButton={CloseButton}
					icon={false}
					position={toast.POSITION.TOP_CENTER}
				/>
			</Main>
		</Router>
	);
};

export default App;
