import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import UserListTable from './users/UserListTable';

import users from 'data/users';
import AddUser from './users/AddUser';
import usePagination from 'hooks/usePagination';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUsersByOrgIdService } from 'services/orgService';
import useError from 'hooks/useError';

export default function Users({ org_id }) {
	const { getResponse } = useError();
	const [usersRes, setUsersRes] = useState(null);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const getUsersByOrgId = useCallback(async (org_id, page) => {
		const res = await getUsersByOrgIdService(org_id, page);

		getResponse(res)
			.then(res => {
				setUsersRes(res);
			})
			.catch(err => console.error(err));
	});

	const getUsersByOrgIdWithPage = page => {
		getUsersByOrgId(org_id, page);
	};

	const { Pagination } = usePagination(getUsersByOrgIdWithPage);

	useEffect(() => {
		getUsersByOrgId(org_id, 1);
	}, []);

	const reload = () => {
		getUsersByOrgId(org_id, 1);
	};

	return (
		<React.Fragment>
			<Row className="gx-3 my-3 h-100">
				<Col>
					<Row className="flex-between-center">
						<Col md>
							<h5 className="mb-2 mb-md-0">Users List</h5>
						</Col>
						<Col xs="auto">
							<Button
								variant="primary"
								className="me-2 mb-1"
								onClick={handleShow}
							>
								Add User
							</Button>
						</Col>
					</Row>

					<Card className="overflow-hidden my-1">
						<Card.Body className="p-0">
							<UserListTable data={usersRes?.data || []} />
						</Card.Body>
					</Card>
				</Col>
			</Row>
			{usersRes?.total_pages ? (
				<Pagination totalPages={usersRes.total_pages} />
			) : null}
			{show && (
				<AddUser
					orgId={org_id}
					show={show}
					handleClose={handleClose}
					onAdd={reload}
				/>
			)}
		</React.Fragment>
	);
}
