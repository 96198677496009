import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { AddStepContext } from 'context/Context';
import React, { useContext } from 'react';
import { BsFileEarmarkPlus } from 'react-icons/bs';

const RenderEmptyTestContent = () => {
	const { toggleModal } = useContext(AddStepContext);
	return (
		<Flex
			justifyContent={'center'}
			alignItems={'center'}
			direction={'column'}
			style={{ minHeight: 200 }}
			className={'bg-white'}
		>
			<BsFileEarmarkPlus fontSize={64} className="empty-icon" />
			<div className="text-black fw-medium fs-1 mb-3">
				This assessment doesn't have any steps. Please create steps to
				start.
			</div>
			<IconButton onClick={toggleModal} icon="plus">
				<span className="ms-2">Create Step</span>
			</IconButton>
		</Flex>
	);
};

export default RenderEmptyTestContent;
