export const stepReducer = (state, action) => {
	const { type, payload } = action;
	let nodes, nodeIndex, edges, edgeIndex;
	switch (type) {
		case 'UPDATE_SCENARIO_TYPE':
			return {
				...state,
				scenarioType: payload
			};
		case 'ADD_NODES_TO_STEP':
			return { ...state, nodes: [...state.nodes, ...payload] };
		case 'ADD_EDGES_TO_STEP':
			return { ...state, edges: [...state.edges, ...payload] };
		case 'ADD_HANDLE_TO_OUTPUT':
			nodes = state.nodes.slice();
			let startNodeIndex = nodes.findIndex(n => n.id === payload.nodeId);
			let startNode = nodes[startNodeIndex];
			startNode.data.output.push({
				id: payload.handleId,
				position: payload.position,
				positionStyle: payload.dotPositon
			});
			nodes[startNodeIndex] = startNode;
			return { ...state, nodes: nodes };
		case 'ADD_HANDLE_TO_INPUT':
			nodes = state.nodes.slice();
			let endNodeIndex = nodes.findIndex(n => n.id === payload.nodeId);
			let endNode = nodes[endNodeIndex];
			endNode.data.inputs.push({
				id: payload.handleId,
				position: payload.position,
				positionStyle: payload.dotPositon
			});
			nodes[endNodeIndex] = endNode;
			return { ...state, nodes: nodes };
		case 'ADD_EDGE':
			//   let edges = state.edges.slice();
			//   let edgeIndex = edges.findIndex(e => e.id === payload.id);
			//   edges[edgeIndex] = payload;
			return { ...state, edges: [...state.edges, payload] };
		case 'SHOW_STEP':
			return {
				...state,
				selectedStep: payload,
				showStepData: payload ? true : false
			};
		case 'UPDATE_NODE':
			nodes = state.nodes.slice();
			nodeIndex = nodes.findIndex(n => n.id === state.selectedStep);
			nodes[nodeIndex].data[payload.id] = payload.value;
			return { ...state, nodes: nodes };
		case 'TOGGLE_LABEL':
			return { ...state, edges: payload };
		case 'UPDATE_NODE_POSITION':
			nodes = state.nodes.slice();
			nodeIndex = nodes.findIndex(n => n.id === payload.id);
			nodes[nodeIndex].position = payload.position;
			return { ...state, nodes: nodes };
		case 'RESET_STEP':
			return payload;
		case 'UPDATE_SELECTED_DELETE_STEP':
			return { ...state, selectedDeleteStep: payload };
		case 'DELETE_NODE':
			return { ...state, ...payload };
		case 'UPDATE_CONNECTION_OF_EDGE':
			edges = state.edges.slice();
			edgeIndex = edges.findIndex(n => n.id === payload.id);
			let newid =
				'reactflow__edge-' +
				payload.source +
				payload.sourceHandle +
				'-' +
				payload.target +
				payload.targetHandle;
			edges[edgeIndex] = { ...payload, id: newid };
			return { ...state, edges: edges };
		case 'REMOVE_EDGE':
			return {
				...state,
				edges: state.edges.filter(e => e.id != payload)
			};
		default:
			return state;
	}
};
