import React, {
	Fragment,
	useCallback,
	useContext,
	useEffect,
	useState
} from 'react';
import AssetsTable from './assets/AssetsTable';

import { Button, Card, Col, Row } from 'react-bootstrap';
import AssetShowEditModal from './assets/AssetShowEditModal';
import AgentAddModal from './assets/AgentAddModal';
import { getAllAgentService } from 'services/agentService';
import { AgentContext } from 'context/Context';
import usePagination from 'hooks/usePagination';
import useError from 'hooks/useError';

export default function Assets({ orgId }) {
	const { getResponse } = useError();
	const [isAssetFetching, setAssetFetching] = useState(false);
	const getAllAgents = useCallback(async (orgId, pageId) => {
		setAssetFetching(true);
		const res = await getAllAgentService(orgId, pageId);
		getResponse(res)
			.then(res => {
				saveAllAgents(res);
				setAssetFetching(false);
			})
			.catch(err => {
				setAssetFetching(false);
			});
	}, []);
	const getAssetsByOrgIdWithPage = page => {
		getAllAgents(orgId, page);
	};
	const { Pagination, currentPage } = usePagination(getAssetsByOrgIdWithPage);
	const { agentState, saveAllAgents } = useContext(AgentContext);
	const [selectedAgent, setSelectedAgent] = useState(null);
	const [show, setShow] = useState(false);
	const [showAddAgentModal, setAAM] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const handleAddAgentModalClose = () => setAAM(false);
	const handleAddAgentModalShow = () => setAAM(true);

	useEffect(() => {
		getAllAgents(orgId);
	}, []);

	// useEffect(() => {
	// 	const interval = setInterval(() => getAllAgents(currentPage), 30000);
	// 	return () => {
	// 		clearInterval(interval);
	// 	};
	// }, [currentPage]);

	const handleOnCreate = () => {
		// toast(<span className="text-primary">Generating Link started!</span>);
		getAllAgents();
	};

	const handleAgentRowClick = id => {
		const agentsData = agentState.agents.data.slice();
		const agent = agentsData.find(agent => agent.id == id);
		setSelectedAgent(agent);
		handleShow();
	};

	const handleAgentClose = () => {
		handleClose();
		setSelectedAgent(null);
	};

	return (
		<Fragment>
			<div>
				<Row className="flex-between-center">
					<Col md>
						<h5 className="mb-2 mb-md-0">Assets</h5>
					</Col>
					<Col xs="auto">
						<Button
							variant="primary"
							className="me-2 mb-1"
							onClick={handleAddAgentModalShow}
						>
							Add New Agent
						</Button>{' '}
					</Col>
				</Row>
				<Card className="overflow-hidden my-4">
					<Card.Body className="p-0">
						<AssetsTable
							data={agentState.agents?.data || []}
							onRowClick={handleAgentRowClick}
							isLoading={isAssetFetching}
						/>
					</Card.Body>
				</Card>
			</div>
			{agentState.agents?.total_pages ? (
				<Pagination totalPages={agentState.agents.total_pages} />
			) : null}
			{selectedAgent && (
				<AssetShowEditModal
					show={show}
					data={selectedAgent}
					handleClose={handleAgentClose}
				/>
			)}
			<AgentAddModal
				show={showAddAgentModal}
				handleClose={handleAddAgentModalClose}
				onCreate={handleOnCreate}
			/>
		</Fragment>
	);
}
