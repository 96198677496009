import Flex from 'components/common/Flex';
import TagBox from 'components/common/TagBox';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import Switch from 'react-switch';
import TypeFormSelect from './TemplateTypeFormSelect';
import TemplateTypeBasedOperation from './TemplateTypeBasedOperation';

export default function TemplateCreateUpdateComponent({
	title,
	submitButtonText,
	onSubmit,
	data,
	handleFieldChange,
	tags,
	handleTags,
	mode
}) {
	return (
		<React.Fragment>
			<div>
				<h5 className="mb-3">{title}</h5>
				<div>
					<div className="mb-3">
						<Form.Label>Template Name*</Form.Label>
						<Form.Control
							id="name"
							type="text"
							className="text-700"
							placeholder="e.g. Linear"
							value={data?.name}
							onChange={handleFieldChange}
						/>
					</div>
					<div className="mb-3">
						<Form.Label>Template Description*</Form.Label>
						<Form.Control
							id="description"
							as="textarea"
							rows={3}
							className="text-700"
							placeholder="e.g. Linear"
							value={data?.description}
							onChange={handleFieldChange}
						/>
					</div>
					<div className="mb-3">
						<Flex className={'align-items-center'}>
							<Form.Label className="me-3">Publish</Form.Label>
							<div>
								<Switch
									checked={data?.status != 'draft'}
									onChange={() =>
										handleFieldChange({
											target: {
												id: 'status',
												value:
													data?.status != 'draft'
														? 'draft'
														: 'active'
											}
										})
									}
									onColor="#86d3ff"
									onHandleColor="#2693e6"
									handleDiameter={24}
									uncheckedIcon={false}
									checkedIcon={false}
									boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
									activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
									height={16}
									width={40}
									className="react-switch"
									id="material-switch"
								/>
							</div>
						</Flex>
					</div>
					{mode == 'create' && (
						<TypeFormSelect
							title={'Template Type*'}
							selectedType={data?.type}
							onViewChange={handleFieldChange}
						/>
					)}
					{data?.type && (
						<TemplateTypeBasedOperation type={data.type} />
					)}
				</div>
				<div className="my-3">
					<Form.Label>Tags (Optional)</Form.Label>
					<TagBox
						labelClass="text-900 fw-bold"
						label={'Tags'}
						id="tech_tag_box"
						tags={tags}
						updateTags={handleTags}
						key={tags}
					/>
				</div>
				<div>
					<Button
						variant="primary"
						className="py-2 px-4"
						onClick={onSubmit}
					>
						{submitButtonText}
					</Button>
				</div>
			</div>
		</React.Fragment>
	);
}
